import React, { useEffect, useState, Fragment } from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap'
import '../css/style.css';
import '../css/sass/main.css';
import '../css/colors/blue.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, BellIcon, SignalIcon, XMarkIcon, } from '@heroicons/react/24/outline'
import { Logout } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { decrypt } from '../utils/function';

const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function mapStateToProps(state) {
  return {
    userInfo: state
  };
}

// const BuyerSection = [
//   {id:0, name:'Create Lead', active:false, route:'/app/category'},
//   {id:1, name:'Dashboard', active:false, route:'/app/category'},
// ]


const SellerSection = [
  { id: 0, name: 'Dashboard', route: '/app/seller/dashboard', current: true },
  { id: 1, name: 'My Listing', route: '/app/seller/mylead', current: false, visible: true },
  { id: 9, name: 'Profile', route: '/app/seller/profile', current: false, visible: true },
  { id: 2, name: 'Listing', route: '/app/seller/lead', current: false, visible: true },
  { id: 3, name: 'Quotes', route: '/app/seller/quotes', current: false, visible: true },
  { id: 4, name: 'Earnings', route: '/app/seller/balance', current: false, visible: true },
  { id: 5, name: 'Inbox', route: '/app/seller/inbox', current: false, visible: true },
  { id: 6, name: 'Help', route: '/app/seller/help', current: false, visible: true },
  { id: 7, name: 'Account', route: '/app/seller/invoice', current: false, visible: true },
  { id: 8, name: 'Settings', route: '/app/seller/setting', current: false, visible: true },
]

const BuyerSection = [
  { id: 0, name: 'Dashboard', route: '/app/dashboard', current: true, visible: true },
  { id: 1, name: 'Create Listing', route: '/app/category', current: false, visible: true },
  { id: 2, name: 'My Listing', route: '/app/buyer-lead', current: false, visible: true },
  { id: 3, name: 'Inbox', route: '/app/inbox', current: false, visible: true },
  { id: 4, name: 'Help', route: '/app/help', current: false, visible: true },
  { id: 5, name: 'Profile', route: '/app/profile', current: false, visible: true },
  { id: 6, name: 'Account', route: '/app/account-info', current: false, visible: true },
  { id: 7, name: 'Setting', route: '/app/setting', current: false, visible: true },
];

const USection = [
  { id: 0, name: 'Find Listing', route: '/lead', current: false, visible: true },
  { id: 1, name: 'Create Listing', route: '/category', current: false, visible: true },
  { id: 2, name: 'Help', route: '/help', current: false, visible: true },
  { id: 3, name: 'Register', route: '/register', current: false, visible: false },
  { id: 4, name: 'Login', route: '/login', current: false, visible: false },
]


const Header = (props) => {
  const { custom } = props;
  const [dataset, setdataset] = useState([]);
  const [token, settoken] = useState('');
  const [role, setrole] = useState('');
  const { userInfo } = props;
  const history = useHistory();

  useEffect(async () => {
    const token_ = await localStorage.getItem('token');
    const role_ = await localStorage.getItem('role');
    if (token_) {
      if (decrypt(process.env.REACT_APP_SECRET_KEY, role_) == 'Buyer') {
        setdataset(BuyerSection)
      } else {
        setdataset(SellerSection)
      }
    } else {
      setdataset(USection)
    }
    settoken(token_);
    setrole(role_);
  }, []);

  const handleLogged = async (route = '/login') => {
    await localStorage.removeItem('token');
    await localStorage.removeItem('uid');
    await localStorage.removeItem('userId');
    await localStorage.removeItem('email');
    await localStorage.removeItem('state');
    await localStorage.removeItem('role');

    history.push('/login')
    window.location.reload(true);
  }

  return (
    <Disclosure as="nav" className="bg-white border-b">
      {({ open }) => (
        <>
          <div className="mx-auto  px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start lg:px-28">
                <div className="flex flex-shrink-0 items-center bg-black">
                  <img
                    className="block h-10 lg:h-12 w-auto lg:hidden"
                    src={require('../assets/icons/logo.png').default}
                    alt="Your Company"
                    onClick={() => history.push('/')}
                  />
                  <img
                    className="hidden h-16 w-auto lg:block"
                    src={require('../assets/icons/logo.png').default}
                    alt="Your Company"
                    onClick={() => history.push('/')}
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {dataset?.filter(x => x.visible).map((item) => (
                      <a
                        key={item.name}
                        href={item.route}
                        className="text-black mt-[18px] mr-4"
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {!token ? dataset?.filter(x => !x.visible).map((item) => (
                  <a
                    key={item.name}
                    href={item.route}
                    className='text-black mx-1 lg:mx-2 flex flex-row text-xs lg:text-lg'
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                )) :
                  <>
                    {/* <button
                      type="button"
                      onClick={() => history.push(userInfo?.user_type == 'Buyer' ? '/app/inbox' : '/app/seller/inbox')}
                      className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}
                    <button
                      type="button"
                      onClick={handleLogged}
                    // className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      Log out
                      {/* <span className="sr-only">Logout</span> */}
                      {/* <Logout className="h-6 w-6" aria-hidden="true" /> */}
                    </button>
                  </>
                }



              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {dataset.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.route}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-600 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default Header;
