import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import { InputSelector, MoveItem, SimpleInputSelector } from '..';
import {
    booleanAns, buildingStories, businessType,
    carCondition, carTruckTypes, constructionTypeOfGoods,
    constructionTypeOfService, constructionTypesOfItems, diningArr, driedFood,
    fruitFood, handlingUnit, motorCycleTypes, movingAreaRange, officeCarType,
    officeShipHauler, officeShipType, oneToTen, otherVehicles, propertyType,
    pulocationType, timeFrameObj, vegeFood,
    livingRoomArr,
    bedroomArr,
    kitchenArr,
    homeOfficeArr,
    garageArr,
    outdoorArr,
    misArr,
    boxesArr,
    serviceObj
} from '../../utils/constant';
import TimePicker from 'react-time-picker/dist/entry.nostyle';
import _ from 'lodash';


const MoveShipSection = ({ userBasicInfo, onSubmit, subCatId, typeId, previousstate = [] }) => {
    const [submisive, setsubmisive] = useState(false);
    const [shipmentInfo, setshipmentInfo] = useState([]);
    const dataset = serviceObj.filter(x => x.id === 7)[0].options.find(x => x.id === typeId);
    const [data, setdata] = useState(dataset.formStructure.filter(x => x.subCatId.includes(subCatId)).map(item => { return { ...item, value: '' } }));
    var i = 0;

    const handleChange = (key, value) => {
        var data_ = [...data];
        const index = data_.findIndex(x => x.id === key);
        data_[index].value = value;
        setdata(data_);
    }

    const handleOnChange = (key, value) => {
        var shipmentInfoClone = { ...shipmentInfo };
        shipmentInfoClone[key] = value;
        setshipmentInfo(shipmentInfoClone);
    }

    useEffect(() => {
        if (!_.isEmpty(previousstate)) {
            var _data = data.map(item => {
                item.value = previousstate[item.key]
                return item;
            });
            setdata(_data)
        }
    }, [typeId])


    const handleCheck = (id) => {
        const sObj = data.find(x => x.id == id);
        return !_.isEmpty(sObj);
    }

    const handleRenderItem = (key) => {
        const sObj = data.find(x => x.id === key);
        if (handleCheck(key)) {
            i++;
            return (
                <div className='col-md-4' style={{ zIndex: data.length + 100 - i }}>
                    <SimpleInputSelector
                        label={sObj?.label}
                        type={sObj?.type}
                        options={sObj?.options}
                        onChange={val => handleChange(key, val)}
                        value={sObj?.value}
                    // mode={'number'}
                    />
                    {submisive && sObj?.value === '' &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>{sObj?.label} is required</h4>
                    }
                </div>
            );
        }
    }

    const handleSubmission = () => {
        var requiredState = data.filter(x => !x.value);
        if (data.find(x => x.id === 'vehicle_transported')?.value === 'No') {
            requiredState = requiredState.filter(x => !['car_type', 'year', 'make', 'model',
                'conditions', 'all_papers'].includes(x.id));
        }
        console.log(requiredState)
        if (_.isEmpty(requiredState) || !_.isEmpty(previousstate)) {
            // console.log("FULFILED")
            var res = {};
            data.map(item => {
                if (item.value !== '') {
                    res[item.id] = item.value;
                }
            })
            // console.log(res)
            onSubmit({ ...res, ...shipmentInfo });
        } else {
            setsubmisive(true);
        }
    }



    return (
        <div className="container" style={{ minHeight: '100vh' }}>
            <div className="row mt-4">

                {handleRenderItem('heading')}
                {handleRenderItem('property_type')}
                {handleRenderItem('moving_area_range')}
                {handleRenderItem('service_type')}
                {handleRenderItem('number_of_bedroom')}
                {handleRenderItem('vehicle_transported')}

                {data.find(x => x.id === 'vehicle_transported').value === 'Yes' &&
                    (
                        <>
                            {handleRenderItem('car_type')}
                            {handleRenderItem('year')}
                            {handleRenderItem('make')}
                            {handleRenderItem('model')}
                            {handleRenderItem('conditions')}
                            {handleRenderItem('all_papers')}

                        </>
                    )
                }




            </div>

            <h2 className='bold mt-5'>Inventory Information</h2>
            <div className='row'>
                <div className='col-md-12 mt-4'>
                    <MoveItem
                        options={diningArr}
                        label='Dining Items'
                        selectedOptions={e => handleOnChange('dining_items', JSON.stringify(e))}
                    />
                    <MoveItem
                        options={livingRoomArr}
                        label='Living Room Items'
                        selectedOptions={e => handleOnChange('living_items', JSON.stringify(e))}
                    />
                    <MoveItem
                        options={bedroomArr}
                        label='Bedroom Items'
                        selectedOptions={e => handleOnChange('bedroom_items', JSON.stringify(e))}
                    />
                    <MoveItem
                        options={kitchenArr}
                        label='Kitchen Items'
                        selectedOptions={e => handleOnChange('kitched_items', JSON.stringify(e))}
                    />
                    <MoveItem
                        options={homeOfficeArr}
                        label='Home Office Items'
                        selectedOptions={e => handleOnChange('office_items', JSON.stringify(e))}
                    />
                    <MoveItem
                        options={garageArr}
                        label='Garage Items'
                        selectedOptions={e => handleOnChange('garage_items', JSON.stringify(e))}
                    />
                    <MoveItem
                        options={outdoorArr}
                        label='Outdoor Items'
                        selectedOptions={e => handleOnChange('outdoor_items', JSON.stringify(e))}
                    />
                    <MoveItem
                        options={misArr}
                        label='Miscellaneous Items'
                        selectedOptions={e => handleOnChange('miscellaneous_items', JSON.stringify(e))}
                    />
                    <MoveItem
                        options={boxesArr}
                        label={'Box Items'}
                        selectedOptions={e => handleOnChange('box_items', JSON.stringify(e))}
                    />

                </div>




            </div>
            <div style={{ zIndex: 1 }}>
                <button
                    onClick={() => handleSubmission()}
                    className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-5 float-right bold"
                    style={{ backgroundColor: '#0e415f', color: '#fff', maxWidth: '220px', fontSize: 20 }}
                    type="submit" form="login-form">Save Information<i className="icon-material-outline-arrow-right-alt" />
                </button>
            </div>
        </div>
    );
}

export default MoveShipSection;



