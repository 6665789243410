import React from 'react';
import _ from 'lodash'
import { getFieldName } from '../utils/function';

const InfoSection = ({ ...props }) => {
  const { categoryName, data, limited = false } = props;
  if (!_.isEmpty(data.furniture_data)) {
    const furnitureData = JSON.parse(data.furniture_data)

    return (
      <>
        {furnitureData?.map(item => <div className='flex flex-wrap -ml-2'>
          <h4 className="bg-white rounded border shadow-sm p-3 m-2">Title: {item.title}</h4>
          <h4 className="bg-white rounded border shadow-sm p-3 m-2">Quantity: {item.quantity}</h4>
          <h4 className="bg-white rounded border shadow-sm p-3 m-2">Length (Inches): {item.length} Inches</h4>
          <h4 className="bg-white rounded border shadow-sm p-3 m-2">Width (Inches): {item.width} Inches</h4>
          <h4 className="bg-white rounded border shadow-sm p-3 m-2">Height (Inches): {item.height} Inches</h4>
          <h4 className="bg-white rounded border shadow-sm p-3 m-2">Weight (Lbs):  {item.weight} Lbs</h4>
        </div>)}
      </>
    );
  }
  const dataset = limited ? getFieldName(categoryName, data).filter(x => x.key).slice(0, 5) : getFieldName(categoryName, data).filter(x => x.key);
  return (
    <div className='flex flex-wrap -ml-2'>
      {dataset.map((item, index) => {
        return (
          <h4 className="bg-white rounded border shadow-sm p-3 m-2">{item.key}: {item.value}</h4>
        );
      })}


    </div>
  );
};

export default InfoSection;