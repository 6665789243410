import axios from 'axios';
import { useEffect, useState } from 'react';
import { crypt, encryptWithSalt } from './function';

// https://bdiconnect.herokuapp.com/api/article/1

// axios.defaults.baseURL = "https://bijlink-env.eba-b8vvjvsu.us-east-2.elasticbeanstalk.com/api/";
//  axios.defaults.baseURL = "https://bdiconnect.herokuapp.com/api/";
// axios.defaults.baseURL = "https://api.idigitek.com/api/";
// axios.defaults.baseURL = "https://bdconnect.peerschain.com/api/";
axios.defaults.baseURL = "https://bijlinkapi.herokuapp.com/api/";
// axios.defaults.baseURL = "http://pcbijlink.us-east-2.elasticbeanstalk.com/api/";
// axios.defaults.baseURL = "https://backend.bijlink.com/api/"
// axios.defaults.baseURL = "http://127.0.0.1:4040/api/"

const getToken = async () => {
    return await localStorage.getItem('token');
}


const useApiRequest = (request) => {
    const { url, data = {}, method, headers = {} } = request;
    const [refreshing, setrefreshing] = useState(false);
    const [loading, setloading] = useState(false);
    const [response, setresponse] = useState([]);
    const [error, seterror] = useState([]);

    useEffect(() => handleData(), [refreshing]);

    const handleData = async () => {
        try {
            setloading(true);
            const _request = await _call(url, method, data, headers);
            setresponse(_request.data);
            setloading(false);
        } catch (err) {
            setloading(false);
            seterror(err);
        }
    }

    return [loading, response, error, setrefreshing];
}

const _call = (url, method, data = {}, headers = null) => new Promise(async (resolve, reject) => {

    const token = await localStorage.getItem('token');
    var headerObj = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
    };

    if (headers) {
        headerObj = {
            ...headers,
        }
    }

    var params = {
        method,
        url,
        data,
        headers: { ...headerObj },
    };

    // const axiosInterceptor = axios.interceptors.response.use(
    //     (response) => response,
    //     (error) => {
    //         // Handle error here
    //         if (error.response.status === 401) {
    //             reject({
    //                 message: "Un-auth"
    //             })
    //         } else {
    //             console.error('An error occurred:', error);
    //         }
    //         return reject(error);
    //     }
    // );

    // const cleanupInterceptor = () => {
    //     axios.interceptors.response.eject(axiosInterceptor);
    // };

    axios(params).then(res => {
        // cleanupInterceptor()
        resolve(res)

    }).catch(err => {
        // cleanupInterceptor()
        reject(err)
    });
});

export { _call, useApiRequest };

// http://sanjida726@gmail.com/Testing1@