import React, { useEffect, useState } from 'react'
import { _call } from '../../utils/auth';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { ImageUploader } from '..';
import JSON5 from 'json5';
import moment from 'moment';
import _ from 'lodash';
import MaskInput from 'react-maskinput';
import { Button } from 'react-bootstrap';
import AlertAsk from '../askalert';
import RModal from '../rmodal';
import { CircularProgress } from '@mui/material';

const ProfileUserSkills = ({ userSkillsInfo, license, handleSubmit, userType, registration_copy,
  fitness_certificate, userId,
  route_permit }) => {
  const [uSkills, setuSkills] = useState(userSkillsInfo);
  const [userInfo, setuserInfo] = useState({});
  const [skillFile, setskillFile] = useState([]);
  const [skills, setskills] = useState([]);
  const [loader, setloader] = useState(false);
  const [selectedSkill, setselectedSkill] = useState('Shipping');
  const [registeration, setregisteration] = useState([]);
  const [routePermit, setroutePermit] = useState([]);
  const [fitnessCertificate, setfitnessCertificate] = useState([]);
  const [drivingLicense, setdrivingLicense] = useState([]);
  const [removingSkill, setremovingSkill] = useState([]);
  const [askalertObj, setaskalertObj] = useState({ state: false, title: '' });
  const [skillInfo, setskillInfo] = useState({
    license: '',
    mc_number: '',
    dot_number: ""
  });

  const [alertObj, setalertObj] = useState({ state: false, message: 'Are you sure.', title: '' });

  const handleChange = (key, value) => {
    var userInfoClone = { ...userInfo };
    userInfoClone[key] = value;
    setuserInfo(userInfoClone);
  }

  const handleData = async () => {
    try {
      const getSkills = await _call('skills', 'GET');
      const data = getSkills.data.data;
      // setuSkills(userSkillsInfo);

      setskills(data);
    } catch (err) {
    }
  }

  useEffect(() => {
    handleData();
    handleUserSkillsInfo();
  }, []);

  const handleUpdateSkill = async () => {


    try {
      const _dataset = {
        mc_number: skillInfo?.mc_number,
        dot_number: skillInfo?.dot_number,
        license: skillInfo?.license,
        skillId: uSkills[0]?.id
      }
      const updateSkill = await _call('user/update-skill/', 'POST', _dataset);
      setalertObj({
        state: true,
        title: 'Congratulation!',
        message: 'Your request has been submitted',
        type: 1,
      });


    } catch (err) {
    }


  }

  const handleUserSkillsInfo = async () => {
    try {
      const getSkills = await _call(`user/skill/${userId}`, 'GET');
      const data = getSkills.data.data;
      if (!_.isEmpty(data)) {
        setskillInfo({
          license: data[0]?.license_expiry_date,
          mc_number: data[0]?.mc_number,
          dot_number: data[0]?.dot_number
        })
      }

      setuSkills(data);
    } catch (err) {
    }
  }

  const handleUpdateSkills = () => {
    var data = {};
    const getSkillObj = skills.filter(x => x.skillName === selectedSkill)[0];

    if ((selectedSkill === 'Car Rental' || selectedSkill === 'Shipping')) {

      data = {
        fileName1: !_.isEmpty(drivingLicense) ? `${userId}_${moment().unix()}_${drivingLicense[0].name}` : null,
        fileName2: !_.isEmpty(registeration) ? `${userId}_${moment().unix()}_${registeration[0].name}` : null,
        fileName3: !_.isEmpty(fitnessCertificate) ? `${userId}_${moment().unix()}_${fitnessCertificate[0].name}` : null,
        fileName4: !_.isEmpty(routePermit) ? `${userId}_${moment().unix()}_${routePermit[0].name}` : null,
        dot_number: userInfo.dot_number,
        license_expiry_date: userInfo.license_expiry_date,
        mc_number: userInfo.mc_number,
        skillName: getSkillObj.skillName,
        skillid: getSkillObj.id,
        category: getSkillObj.category,
        userId
      }
    } else {
      data = {
        social_security: userInfo.social_security,
        skillName: getSkillObj.skillName,
        fileName1: !_.isEmpty(skillFile) ? `${userId}_${moment().unix()}_${skillFile[0].name}` : null,
        category: getSkillObj.category,
        skillid: getSkillObj.id,
        userId
      }
    }

    handleSubmit(data);
    setuserInfo({});
  }


  const handleRemoveSkill = async () => {
    const skillId = removingSkill.id;
    setaskalertObj({ ...askalertObj, state: false });
    var datasetclone = [...uSkills];
    datasetclone = datasetclone.filter(x => x !== removingSkill);
    setuSkills(datasetclone);
    try {
      setloader(true);
      const removeSkill = await _call('remove-skill', 'POST', {
        skillId
      });

      setloader(false);
      setalertObj({
        state: true,
        title: 'Congratulation!',
        message: 'Your request has been submitted',
        type: 1,
      });
    } catch (err) {
      setloader(false);
      setalertObj({
        state: true,
        title: 'Problem Occured!',
        message: 'Some problem occured. Try again later.',
        type: 2
      })
    }
  }


  if (loader) return <div className='loaderContainer' style={{ alignItems: 'center', height: '100vh', justifyContent: 'center', display: 'flex' }}><CircularProgress color="inherit" /></div>
  return (
    <div className="container pl-4">

      <div className="row">
        <div className='col-md-12'>
          {uSkills.map((item, index) => {
            return (
              <div className="flex rounded-lg bg-slate-50 border w-100 mt-4 mb-2 p-3">
                <img
                  src={require('../../assets/images_/file.png').default}
                  style={{ width: 30 }}
                />
                <h4 style={{ marginLeft: 10, width: '70%' }} className="text-overflow bold mt-2">{item.skillName} ({item.is_activated ? 'Active' : 'In Review'})</h4>
                {/* <Button
                  onClick={() => {
                    setremovingSkill(item);
                    setaskalertObj({ ...askalertObj, state: true })

                  }
                  }
                  style={{ width: '70px', fontSize: 12, padding: 0 }}
                >Remove</Button> */}
              </div>

            );
          })}


          <h4 className='mt-4 mb-1'>Dot Number</h4>
          <input className='input-style mt-1' placeholder='Dot Number' value={skillInfo?.dot_number} onChange={e => setskillInfo({ ...skillInfo, dot_number: e.target.value })} />
          <h4 className='mt-2 mb-1'>MC Number</h4>
          <input className='input-style mt-1' placeholder='MC Number' value={skillInfo?.mc_number} onChange={e => setskillInfo({ ...skillInfo, mc_number: e.target.value })} />
          <h4 className='mt-2 mb-1'>License Expiry Date</h4>
          <DatePicker
            className="mt-1 input-style"
            placeholderText={moment(skillInfo?.license).format('MM-DD-YYYY')} onChange={e => setskillInfo({ ...skillInfo, license: e })}
          />
          <button
            onClick={handleUpdateSkill}
            className='bg-yellow-500 float-right w-48 rounded h-12 mt-4 text-white text-md'
            type="button">
            Submit
          </button>

        </div>


        <div className="col-md-6">

          {/* <h4>Select skill</h4>
          <select
            className="mt-1"
            style={{ backgroundColor: '#fafafa', borderRadius: 0, height: 60, fontSize: 16, borderWidth: 1, borderColor: '#efefef', boxShadow: 'none', borderStyle: 'solid ' }}

            value={selectedSkill}
            onChange={e => setselectedSkill(e.target.value)}
          >
            <option value="-1">Select any skill</option>
            {skills.map(item => <option value={item.skillName}>{item.skillName}</option>)}
          </select> */}

          {/* {selectedSkill && <>

            <h4 >{selectedSkill} Certificate</h4>
            <ImageUploader
              sendFiles={e => setskillFile(e)}
              numberOfFiles={1}
            /> */}
          {/* <h4 className="mt-4">{selectedSkill} Required Information</h4>
                                <h4 className="mt-5">Social Security Number</h4> */}
          {/* <input
                               className="mt-1"
                               style={{backgroundColor:'#fafafa',borderRadius:0,height:60,fontSize:16,borderWidth:1,borderColor:'#efefef',boxShadow:'none',borderStyle:'solid '}}
                            
                                value={userInfo.social_security}
                                onChange={e => handleChange('social_security',e.target.value)}
                                /> */}
          {/* <MaskInput alwaysShowMask maskChar=" " mask="000-00-0000" size={20}   
                            onChange={e => handleChange('social_security',e.target.value)}
                            className="mt-2 input-style" /> */}

          {/* {(selectedSkill === 'Car Rental Service' || selectedSkill === 'Shipping') && <>
              <h4 className="mt-5">Driving License</h4>
              <ImageUploader
                sendFiles={e => setdrivingLicense(e) || license(e)}
                numberOfFiles={1}
              />
              <h4 className='mb-2 mt-4'>License Expiry Date</h4>
              <DatePicker
                selected={userInfo.license_expiry_date}
                className="input-style"
                onChange={e => handleChange('license_expiry_date', e)} />
              <h4>DOT Number (Optional)</h4>
              <input
                value={userInfo.dot_number}
                className="mt-2 input-style"
                onChange={e => handleChange('dot_number', e.target.value)}
              />
              <h4>MC Number (Optional)</h4>
              <input
                className="mt-2 input-style"
                value={userInfo.mc_number}
                onChange={e => handleChange('mc_number', e.target.value)}
              />
              {userType === 'Company' &&
                <>
                  <h4 >Vehicle Registration Copy</h4>
                  <ImageUploader
                    sendFiles={e => setregisteration(e) || registration_copy(e)}
                    numberOfFiles={1}
                  />
                  <h4 >Vehicle Fitness Certificate</h4>
                  <ImageUploader
                    sendFiles={e => setfitnessCertificate(e) || fitness_certificate(e)}
                    numberOfFiles={1}
                  />
                  <h4 >Vehicle Route Permit</h4>
                  <ImageUploader
                    sendFiles={e => setroutePermit(e) || route_permit(e)}
                    numberOfFiles={1}
                  />
                </>
              }
            </>}

          </>} */}



        </div>

      </div>
      <div>
        {/* <button
          disabled={selectedSkill === '-1' || !selectedSkill || _.isEmpty(skillFile)}
          onClick={() => handleUpdateSkills()}
          className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-5 float-right bold"
          style={{ backgroundColor: '#444', color: '#fff', maxWidth: '300px', fontSize: 16, borderRadius: 100 }}
          type="submit" form="login-form">Save Information<i className="icon-material-outline-arrow-right-alt" />
        </button> */}
      </div>

      <AlertAsk
        open={askalertObj.state}
        message={"Are you sure?"}
        onAccept={() => handleRemoveSkill(askalertObj.data)}
        onClose={() => {
          setaskalertObj({ ...askalertObj, state: false });
        }}
      />

      <RModal
        open={alertObj.state}
        title={alertObj.title}
        message={alertObj.message}
        type={alertObj.type}
        onClose={() => {
          setalertObj({ ...alertObj, state: false });
        }}
      />
    </div>
  );
}

export default ProfileUserSkills;