//ADD SOCIAL SECURITY, ADDRESS in profile

const googleAPIKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const imageURL = process.env.REACT_APP_IMAGE_URL;

const S3BucketInfo = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
}

const categories = [
  { id: 0, value: 'Air Condition', image: require('../assets/images_/ac.jpg').default },
  { id: 1, value: 'Electrical service', image: require('../assets/images_/electrician.jpg').default },
  { id: 2, value: 'Plumbing Service', image: require('../assets/images_/plumber.jpg').default },
  { id: 3, value: 'Tutor', image: require('../assets/images_/tutor.jpg').default },
  { id: 4, value: 'Appliance', image: require('../assets/images_/appliance.jpg').default },
  { id: 5, value: 'TV/Television', image: require('../assets/images_/tv.jpg').default },
  { id: 6, value: 'Household Item', image: require('../assets/images_/household.jpg').default },
  { id: 7, value: 'Paint', image: require('../assets/images_/painter.jpg').default },
  { id: 8, value: 'Cleaning Service', image: require('../assets/images_/cleaner.jpg').default },
  { id: 9, value: 'Rental', image: require('../assets/images_/rental.jpg').default },
  { id: 10, value: 'Tour and Travel', image: require('../assets/images_/travel.jpg').default },
  { id: 11, value: 'Shipping', image: require('../assets/images_/shipper.jpg').default },
  { id: 12, value: 'Photography', image: require('../assets/images_/photography.jpg').default },
];

const packingSupplies = ["Not applicable", "Blanket", "Furniture Pad", "Glass Pack Kit With Box", "TV or Art Edge Protection",
  "Picture Packer Kit", "Cardboard Box", "Corrugated Box", "Foam Sheets", "Pouches Bags", "Cushion Foam", "Bubble wrap", "Airbags",
  "Styrofoam", "Crumpled paper", "Wooden Pallet", "Plastic Pallet", "Wooden Crated", "Plastic Crated"];

const timeFrame = ["8 AM to 12 PM EST", "12 PM to 4 PM EST", "4 PM to 8 PM EST"];

const pickupLocationAdditionalInfo = ['Residence Driveway', 'Residence Garage', 'Business Location', 'Auto repair shop', 'Type for other Location']

const unallowedChar = "-+&^*()%$#@!_+{{}}[]:;,<>./?abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

const subcategory = [
  { id: 0, cid: 0, value: 'AC installation' },
  { id: 1, cid: 0, value: 'AC dismantle' },
  { id: 2, cid: 0, value: 'AC un-Install & Installation with a new unit' },
  { id: 3, cid: 0, value: 'AC Gas Charge' },
  { id: 4, cid: 0, value: 'AC Compressor Fitting With Gas Charge' },
  { id: 5, cid: 1, value: 'Ceiling Fan' },
  { id: 6, cid: 1, value: 'Ceiling Fan Replacement' },
  { id: 7, cid: 1, value: 'Exhaust Fan Servicing' },
  { id: 8, cid: 1, value: 'Electrical Panel Replacement' },
  { id: 9, cid: 1, value: 'Electrical Spa, Pool & Jacuzzi Wiring' },
  { id: 10, cid: 1, value: 'Electrical Outlet Replacement' },
  { id: 11, cid: 1, value: 'Holiday Lighting (Indoor)' },
  { id: 12, cid: 1, value: 'Kitchen Lighting' },
  { id: 13, cid: 1, value: 'Bathroom Lighting' },
  { id: 14, cid: 1, value: 'Building Electric wiring' },
  { id: 15, cid: 2, value: 'Drain cleaning' },
  { id: 16, cid: 2, value: 'Water line installation' },
  { id: 17, cid: 2, value: 'New plumbing fixture installation' },
  { id: 18, cid: 2, value: 'Faucet and replacement' },
  { id: 19, cid: 2, value: 'Outside faucet replacement' },
  { id: 20, cid: 2, value: 'Water Meter Installation' },
  { id: 21, cid: 2, value: 'Sink Installation' },
  { id: 22, cid: 2, value: 'Garbage disposal installation/replacement' },
  { id: 23, cid: 2, value: 'Water pipe rerouting' },
  { id: 24, cid: 2, value: 'Toilet installation' },
  { id: 25, cid: 3, value: 'Bangla Medium' },
  { id: 26, cid: 3, value: 'English Medium' },
  { id: 27, cid: 3, value: 'Arabic Medium' },
  { id: 28, cid: 3, value: 'English Version' },
  { id: 29, cid: 3, value: 'Language Learning' },
  { id: 30, cid: 3, value: 'Computer Learning' },
  { id: 31, cid: 5, value: 'LCD/LED repair' },
  { id: 32, cid: 5, value: 'Wall Mount' },
  { id: 33, cid: 6, value: 'Geyser Installation' },
  { id: 34, cid: 6, value: 'IPS Installation' },
  { id: 35, cid: 6, value: 'Water Purifier' },
  { id: 36, cid: 6, value: 'Generator Installation' },
  { id: 37, cid: 6, value: 'Window' },
  { id: 38, cid: 6, value: 'Door' },
  { id: 39, cid: 7, value: 'Interior (Surface Type)' },
  { id: 40, cid: 7, value: 'Exterior (Surface Type)' },
  { id: 41, cid: 7, value: 'Damp Wall repair (Surface Type)' },
  { id: 42, cid: 7, value: 'Bed (Furniture Type)' },
  { id: 43, cid: 7, value: 'Chair (Furniture Type)' },
  { id: 44, cid: 7, value: 'Dinning tables and Chairs (Furniture Type)' },
  { id: 45, cid: 7, value: 'Tables (Furniture Type)' },
  { id: 46, cid: 7, value: 'Cabinate (Furniture Type)' },
  { id: 47, cid: 7, value: 'Drawer (Furniture Type)' },
  { id: 48, cid: 7, value: 'Dresser (Furniture Type)' },
  { id: 49, cid: 7, value: 'Office desk (Furniture Type)' },
  { id: 50, cid: 7, value: 'Drawer (Furniture Type)' },
  { id: 51, cid: 8, value: 'Furniture (Cleaning Service)' },
  { id: 52, cid: 8, value: 'Carpet (Cleaning Service)' },
  { id: 53, cid: 8, value: 'Common Space Cleaning (Home Cleaning)' },
  { id: 54, cid: 8, value: 'Home Deep Clean (Home Cleaning)' },
  { id: 55, cid: 8, value: 'Floor Deep Clean (Home Cleaning)' },
  { id: 56, cid: 8, value: 'Water Tank & Pipe Deep Clean (Home Cleaning)' },
  { id: 57, cid: 8, value: 'Bathroom (Home Cleaning)' },
  { id: 58, cid: 8, value: 'Kitchen (Home Cleaning)' },
  { id: 59, cid: 4, value: 'Microwave Oven' },
  { id: 60, cid: 4, value: 'Refrigerator' },
  { id: 61, cid: 4, value: 'Washing Machine' },
  { id: 62, cid: 4, value: 'Kitchen Hood' },
  { id: 63, cid: 9, value: 'Car Rental' },
  { id: 64, cid: 9, value: 'Bus Rental' },
  { id: 65, cid: 11, value: 'Vehicle' },
  { id: 66, cid: 11, value: 'Household Items (Sub Menu) ' },
  { id: 67, cid: 11, value: 'Moves(sub menu)' },
  { id: 68, cid: 11, value: 'Office or Business ' },
  { id: 69, cid: 11, value: 'Construction' },
  { id: 70, cid: 11, value: 'Freight' },
  { id: 71, cid: 11, value: 'Live Animals' },
  { id: 72, cid: 11, value: 'Electronics' },
  { id: 73, cid: 11, value: 'Food' },
  { id: 74, cid: 12, value: 'Occasion' },
  { id: 75, cid: 12, value: 'Wedding' },
  { id: 76, cid: 12, value: 'Engagement' },
  { id: 77, cid: 12, value: 'Holud' },
  { id: 78, cid: 12, value: 'Birthday' },
  { id: 79, cid: 12, value: 'Baby Shower' },
  { id: 80, cid: 12, value: 'Newborn' },
  { id: 81, cid: 12, value: 'Commercial' },
  { id: 82, cid: 12, value: 'Party' },
  { id: 83, cid: 12, value: 'Fashion' },
  { id: 84, cid: 12, value: 'Personal' },
  { id: 85, cid: 12, value: 'Outdoor' },
  { id: 86, cid: 12, value: 'Seminar' },
  { id: 87, cid: 12, value: 'Travel & Nature' },
  { id: 88, cid: 12, value: 'Outdoor' },
  { id: 89, cid: 12, value: 'Ecommerce' },
];

const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSENGER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}



const acSize = ["Geothermal or Central AC (1 Ton)",
  "Geothermal or Central AC (1.5 Ton)", "Geothermal or Central AC (2 Ton)", "Geothermal or Central AC (2.5 Ton)",
  "Geothermal or Central AC (3 Ton)", "Geothermal or Central AC (3.5 Ton)", "Geothermal or Central AC (4 Ton)",
  "Geothermal or Central AC (4.5 Ton)", "Geothermal or Central AC (5 Ton)", "Window/Ductless Air conditioner"
];
const airConditionType = ["Window Air Conditioners", "Geothermal Heating and Cooling", "Central Air Conditioners", "Ductless Air Conditioners", "Portable Air Conditioners"];
const typeOfLocation = ["Single-family homes", "Apartments", "Townhouses", "Condos", "Shop", "Office", "Other - Specify"];
const buildingStories = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
const installationLocation = ["Crawl Attic + Outside", "Loft Attic + Outside", "Garage + Outside", "Inside Home + Outside", "Crawl Attic", "Loft Attic", "Garage", "Inside Home", "Office + Outside", "Office", "Shop + Outside", "Shop", "BedRoom", "Dinning Room", "Living Room", "Hallway", "Kitchen", "Bathroom"];
const unitToInstalled = ["Indoor Only", "Outdoor Only", "Indoor and Outdoor units"];
const gasType = ["Full - R22", "Partial - R22", "Full - R410", "Partial - R410", "Not Sure"];
const booleanAns = ["Yes", "No"];
const totalUnitsToInstallType = ["AC Un-install & Installation (Both Unit) – Same place", "AC Uninstallation & Installation  (Indoor Unit Only) - Same place", "AC Uninstallation & Installation (Outdoor Unit Only) - Same place"];

const oneToTen = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
const propertyType = ["Residential", "Commercial"];
const drainType = ["Bathroom Drain", "Kitchen Drain", "Other"];
const ductType = ["Heating and cooling air ducts", "Dryer vent"];
const ventsServing = ["1 to 5", "5 to 10", "10 to 15", "15 to 20", "Other"];
const systemLocated = ["Attic", "Garage", "Hallway", "Bathroom", "Basement", "Sunroom", "Deck", "Closet", "Outdoor"];
const lineType = ["Bathroom", "Kitchen", "Washer", "Deck", "Other"];
const serviceType = ["Install new water pipe", "Replace existing water pipe", "Repair broken water pipe", "Other"];
const pipeType = ["PEX", "PVC", "Rigid Copper", "ABS", "Flexible", "Steel and Cast Iron"]
const faucetType = ["Bathroom", "Kitchen", "Washer", "Deck", "Outdoor", "Other"];
const moreFaucet = ["Pull Down Kitchen faucet", "Put out Kitchen Faucet", "1 Handle Kitchen faucet", "2 Handle Kitchen Faucet", "Bar Faucet", "Bridge faucet", "Pot Fillers", "Touchless and Touch on Faucet", "Voice Control Faucets"];
const sinkHoles = ["Faucet for 1-Hole Sink", "Faucet for 2-Hole Sink", "Faucet for 3-Hole Sink", "Faucet for 4-Hole Sink"];
const sinkInstallationType = ["Sink Installation Only", "Sink Installation & Plumbing Only", "Sink Installation, Plumbing, & Faucet"];
const toiletType = ["Wall Hung toilets", "Close Coupled toilets", "Back To Wall toilets", "Squat toilet"];
const sinkInstallationLocation = ["Bathroom", "Kitchen", "Dining", "Outdoor", "Other"];
const toiletInstallationType = ["New Unit installation", "Replacement with new unit"];
const applianceMTypeOfService = ["Not Heating", "Button Problems", "Noise Issue", "Other"];
const applianceRTypeOfService = ["Circuit Repair or Replacement", "Condenser fitting with gas refill", "Compressor fitting with gas refill", "Thermostat replace", "Gasket Change", "Fridge Gas Refill"];
const applianceRSTypeOfService = ["Installation", "Replacement"];
const applianceWMTypeOfService = ["Circuit Repair or Replacement", "Motor Repair/Replace", "Gear Box repair/replace", "Water Level Sensor", "Washing Machine Replacement", "Button/Switch Problems", "Noise Issue", "Hot Water issue"]
const operationType = ["N/A", "Customer", "Service Provider"];
const tvlcdserviceType = ["Crack screen", "Sound issue", "Switch issue", "Remote Sensor issue", "Others"];
const tvwellmountserviceType = ["Wall Mount"];
const tvBrand = ["Samsung", "Vizio", 'Sony', "Panasonic", "Toshiba", "LG", "TCL", "Hisense", "Walton", "Other"];
const tvInches = [22, 24, 26, 30, 32, 36, 40, 42, 45, 48, 50, 55, 60, , 65, 70, 75, 80, 'Above 80'];
const wallType = ["Brick", "DryWall"];
const householdUnitSize = ["45L", "50L", "67L", "Other"];
const windowType = ["Double-Hung", "Casement", "Awning", "Bay or Bow"];
const windowTypeMaterial = ["Thai", "Aluminium", "Glass", "Wood", "Others"];
const householdServiceType = ["Installation including new Frame", "Installation In existing frame only", "Replacement", "Repair"];
const doorType = ["Exterior", "Front Entry", "Sliding Doors", "Sliding Barn", "Pocket", "Dutch", "French"];
const doorMaterial = ["Wood", "Steel", "Fiberglass", "Aluminum", "Glass", "Battened and Ledged", "Flush"];

//Painter
const furnitureType = ["Bedroom", "Chair", "Dinning tables and Chairs", "Living Room", "Tables", "Kitchen Cabinate", "Bathroom Cabinate", "Drawer", "Dresser", "Office desk", "OutDoor", "Others"];
const furniturematerialType = ["Wood", "Metal", "Others"];
const paintSurface = ["New Surface", "Old Surface"];
const paintType = ["Paint only", "Paint and Paint Finish option", "Varnish Only", "Paint & Varnish"];
const paintMethod = ["Brush", "Roller", "Spray"];
const morePaintType = ["Oil-Based Paint", "Chalkboard Paint", "Chalk Paint", "Milk Paint", "Stains", "Metallic Paint"];
const paintFinish = ["Not Needed", "Waxes", "Polyurethanes", "Glaze", "Varnish", "Clear Top Coats", "Weathered Effects"];
const surfaceLocation = ["Wall Only", "Ceiling Only", "Wall and Ceiling", "Molding", "Bedroom", "Kitchen", "Dining Space", "Living Room", "hall way", "Staircase", "Wood Floor", "Others"];
const totalSurfaceFeet = ["Total number of rooms", "Area in sq ft-Insert value"];
const furnitureTypeService = ["Remove old paint", "Sand surface", "Prep Surface: Fill holes, cracks"];
const textureType = ["Comb", "Popcorn", "orange peel", "Knockdown", "Slap Brush", "Slapbrush Knockdown"];
const moreInteriorPaintType = ["Latex Paint", "Oil Based Paint"];
const paintInteriorFinish = ["Flat (matte finish)", "Satin (eggshell finish)", "Semi-Gloss", "High-Gloss"];
const exteriorSurfaceLocation = ["Deck", "Sunroom", "Cement Siding", "Vinyl Siding"];
const CarType = ["Sedan", "SUV", "Minivan", "Pickup trucks", "MotorCycle"];
const paintCarType = ["Paint only", "Paint & Seal", "Dent & Paint", "Dent, Paint & Seal"];

//Furniture Assemble
const assembleFurnitureType = ["Bed Room Set", "Dinning Room Set", "Living Room set", "Nursery room set", "Patio set", "Office desk", "Bed", "Drawer", "Sofas", "Sectionals", "Accent Chairs", "Loveseats", "Recliners", "Sideboards", "Nightstands", "Dressers", "Chests", "armoires", 'Storage cabinets', "Bookcases", "Others"];
const assembleFurnitureBrand = ["Ikea", "Ashley", "Rooms to Go", "Herman Miller", "La-Z-Boy", "Drexel", "Bernhardt", "Others"];


//Roof
const roofMaterialType = ["Asphalt Shingles", "Gable", "Metal", "Slate", "Clay Tiles", "Wood Shake", "Single Ply"];
const roofServiceType = ["Complete Replacement of Roof", "Install roof on new construction"];
const roofRepairServiceType = ["Roof Leaking", "Storm damage", "Flashing repairs", "Gutter  repair"];

//Pressure Washer
const powerWashingArea = ["Driveway", "Deck/Patio", "Walkway/Sidewalk", "Garage", "Fence", "Others"];

//Car Mechanic
const carlocatedgarage = ["Garage", "Driveway", "Others"];
const carmechanicServiceType = ['Change Oil and Filter', "Tire Rotation", "Wiper blades replacement", 'Replace air filter', "Battery replacement", "Inspecting and cleaning the battery", "An inspection of all fluids, filters, belts, hoses and brakes", "Replacing head lights", "Replacing parking lights", "Replacing Break lights"];
const carmechanicEngine = ["Engine leak", "Check Engine Light on", "Spark plug replacement", "Air-conditioner filter check", "Brake fluid and brakes check", "Replacing the Brake Pads", "Coolant flush", "Transmission service", "Transmission replacement", "Suspension check", "Repack wheel bearings", "Tune-Up", "Replacing the Brake Pads", "Timing Belt Replacement", "Starter Replacement", "Alternator Replacement", "Valve Cover Gasket Replacement", "Oil Pan Replacement", "Carburetor Replacement", "Radiator Replacement", "Throttle Body Replacement", "Water Pump Replacement", "Engine Mount Replacement", "Distributor Replacement", "Other"]
const carmechanicAC = ["AC is not working (Inspection)", "AC Condenser Fan Replacement", "AC Condenser Replacement", "AC Recharge", "AC Compressor Replacement", "AC Accumulator Replacement", "Heater is not working (inspection)", "Heater Blower Motor Replacement", "Heater Blower Motor Resistor Replacement", "Heater Control Valve Replacement", "Other"];

//Gutter Service
const gutterserviceType = ["Water draining problem", "Gutter Clogged", "Regular maintenance"];

//Flooring Service
const floorTilesMaterial = ["Ceramic", "Porcelain", "Glass", "Marble", "Granite.", "Other Natural Stone."];
const floorTilesInstallation = ["Bathroom Floor/Wall", "Bathroom Floor only", "Bathroom Wall only", "Kitchen floor/Wall", "Kitchen floor only", "Kitchen Wall Only", "Wholehouse", "Office floor", "Sunroom floor", "Porch floor", "Garage floor", "Other"];
const floorTilesServiceType = ["Demolish existing Tiles/ Grout & Install new Tile", "Install new tiles", "Tiles Replacement", "Tile/Grout Cleaning"];
const flooringMaterials = ["Hardwood", "Laminate", "Vinyl", "Engineered Wood Flooring", "LVP"];
const flooringInstallation = ["Bathroom Floor", "Stairs", "Kitchen floor", "Kitchen floor only", "Wholehouse", "Office floor", "Sunroom floor", "Porch floor"];
const flooringServiceType = ["Demolish existing floor & Install new", "Install new floor only", "Floor Replacement", "Floor Cleaning"];
const floorcarpetMaterial = ["Nylon", "Olefin", "Polyester", "Acrylic", "Wool"];
const floorcarpetStyles = ["Loop Pile", "Cut Pile"];
const floorcarpetPadding = ["Prime  foam", "Frothed foam (memory foam)", "Synthetic fiber", "Rebonded foam", "Slab rubber"];
const floorcarpetServiceType = ["Demolish existing & Install new Padding/Carpet", "Install new Padding/Carpet only"];

//Deck Service
const deckMaterials = ["Redwood", "Cedar", "Pressure treated - Pine", "Vinyl", "Others"];
const deckPlacement = ["Ground level", "2nd Floor", "3rd Floor", "Others"];
const deckIssue = ["Deck is unstable", "Faulty or broken stairs/railing", "Stairs/Railings not to code", "Loose decking or stairs", "Discolored/Mildewed decking", "Rotted decking or stairs", "Waterproofing", "Repaint"];
const deckJobType = ["New Paint", "Repaint"];

//Lawn Care
const lawnCareYards = ["Front Yard", "Back Yard", "Front and Back Yards", "Others"];
const lawnAreLayer = ["Sloped", "Flat"];
const lawnMovingFrequency = ["One Time", "Weekly", "BiWeekly", "Monthly"];
const lawnserviceRequire = ["Few days", "Few Weeks", "In a month", "flexible for time"];
const lawnseedingType = ["New Seeding", "Reseeding lawn"];
const lawngrassType = ["Warm season grass", "Cool season grass"];
const lawntypeofSeed = ["BENTGRASS(Cool Season)", "BERMUDA GRASS(Warm Season)", "CENTIPEDE GRASS(Warm Season)", "DICHONDRA(Warm Season)", "FINE FESCUE(Cool Season)", "KENTUCKY BLUEGRASS(Cool Season)", "RYEGRASS(Cool Season)", "AUGUSTINE GRASS(Warm Season)", "TALL FESCUE(Cool Season)", "ZOYSIA GRASS(Warm Season)", "BLUEGRASS/RYE (Cool Session)"];
const lawncurrentSurface = ["Existing grass or weeds", "Concrete", "Trees"];

//Tutoring
const tutorMedium = ["Bangla Medium", "English Medium", "Arabic Medium", "English Version", "Language Learning", "Computer Learning"];
const tutorGrade = ["Nursery", "Class I", "Class II", "Class III", "Class IV", "Class IX", "Class V", "Class VI", "Class VII", "Class VIII", "Class X", "Class XI (SSC)", "Class XII(HSC)"];
const tutorSubject = ["English", "General Math", "Higher Math,", "Math,", "Physics,", "Primary Science,", "Social Science", "Accounting", "Agriculture", "Arabic", "Arts and Crafts", "Biology", "Chemistry", "ICT", "Primary Science", "Easy Handwriting", "All Subject"];
const tutionPerWeek = [1, 2, 3, 4, 5, 6];
const tutorPreferredTime = ["Morning", "Afternoon", "Evening"];
const gender = ["Male", "Female", "Others"];

//Pest Control
const pestcontrolType = ["Cockroach", "Termite", "Bed Bug", "Rodent", "Mosquito", "Ant", "Carpenter Bee"];
const pestresidentialType = ["House", "Condo", "Apartment", "Bungalow", "Cottage House"];
const pestnumberOfBedroom = ["1 Bedroom", "2 Bedroom", "3 Bedroom", "4 Bedroom", "Others"];
const pestServiceType = ["Indoor", "Outdoor", "Indoor and Outdoor"];
const pestserviceFrequency = ["One Time", "Monthly", "Quarterly", "Twice a year", "Yearly"];
const pestproductPreference = ["Spray", "Gel", "Powder", "Does not matter"];
const pestCommercial = ["Hospital", "School/University", "Hotel", "Restaurant", "Office", "Others"];

//Cleaning Service
const cleaningServiceType = ["Hot Water Extraction/Deep Steam Cleaning", "Stain & Odor Removal", "Pet stain removal"];
const cleaningupholsteryType = ["Fabric", "Leather", "Microfiber", "Others"];
const cleaningchairSize = ["1 Seat", "2 seat", "3 seat", "4 Seat", "5 Seat", "Others"];
const cleaningServiceFor = ["Residential", "Commercial"];
const cleaningType = ["One Time", "Weekly", "BiWeekly", "Monthly"];
const cleaningSpace = ["1 Bedroom Apartment", "2 Bedroom Apartment", "3 Bedroom Apartment", "4 Bedroom Apartment", "2 Bedroom Single Family home", "3 Bedroom Single Family home", "4 Bedroom Single Family home", "5 Bedroom Single Family home", "5 plus Bedroom Single Family home"];
const cleaningTypeOfFloor = ["Tiles", "Marble", "Mosaic", "Hardwood", "Wooden"];
const cleaningrecommendedProduct = ["Kids and Pet Safe", "Doesn’t matter"];
const cleaningIncluded = ["Oven", "Refrigerator", "Stove", "Garage", "Other - Specify"];
const cleaningMoveServiceType = ["Move In", "Move Out"];
const cleaningCommericial = ["Entire office", "Office Desk", "Shop", "Restaurant", "Hospital", "School", "Others"];
const cleaningPoolServiceLocation = ["Apartment Complex", "Single Family home", "Office", "Hospital", "Swimming Pool Complex", "School", "Others"];
const cleaningPoolServiceType = ["Vacuum", "Blow deck", "Check for filtration System operation", "Clean tile", "Net top", "Empty Skimmer/Pump Baskets", "Empty pool cleaner", "Vacuum bottom of pool", "Brush Walls", "Backwash filter", "Filter and cartridge cleaning", "Clean Equipment Area", "Balance chemicals", "Clean and Check Automatic Pool Cleaners(if any)", "Adjust Water Level, as Needed"];
const cleaningPoolSize = ["Length", "Height", "Weight"];
const cleaningTankServiceType = ["Overhead Water Tank & Reserve tank", "Overhead Water Tank only", "Reserve Tank Only", "Septic Tank"];
const spaceInfo = ["1 Bedroom Apartment ", "2 Bedroom Apartment", "3 Bedroom Apartment", "4 Bedroom Apartment", "2 Bedroom Single Family home", "3 Bedroom Single Family home", "4 Bedroom Single Family home", "5 Bedroom Single Family home", "5 plus Bedroom Single Family home"];
//Electrician

const electricianInstallationArea = ["Attic", "Garage", "Office", "Shop", "BedRoom", "Dinning Room", "Living Room", "Hallway", "Kitchen", "Bathroom", "Basement", "Sunroom", "Deck"];
const electricianfloorUnits = ["8ft", "10ft", "12ft", "14ft", "16ft", "18ft", "24ft", "Others"];
const electriciansurfacesForSpa = ["Concrete slab", "Prefabricated spa pad", "Gravel base", "Deck"];
const electricianPrivacyScreen = ["PVC Curtain.", "Drop Cloth Outdoor Curtains.", "Blinds", "Fence", "Decorative Privacy Wall", "Retractable Tub Screen", "Shade Sail"];
const electricianGeneratorType = ["Portable Generators", "Inverter Generators", "Standby Generators", "Gasoline Generators", "Diesel Generators", "Natural Gas Generators", "Solar Generators", "Hydrogen Generators"];
const electricianGeneratorUnitSizes = ["Upto 2000 watts", "2000 to 3000 watts", "3000 to 4000 watts", "4000 to 5000 watts", "5000 to 70000 watts", "7000 to 9000 watts", "9000 t0 1100 watts", "1100 and more watts", "Others"];
const electricianAdditionLocation = ["Roof lighting", "Tree and shrub lighting", "Others"];
const electricianLighteningService = ["Indoor", "Outdoor"];
const electricianOccassions = ["Christmas", "Halloween", "Wedding", "Puja", "EID", "Others"];
const electricianServiceType = ["New Installation", "Rewiring"];
const electricianSurfaceType = ["Wall", "Ceiling", "Under Cabinet"];
const electricianFixtures = ["Flush Mount", "Chandelier", "Pendant", "Fan Light Combination"];
const electricianFixturesType = ["Recessed light", "Downlight"];

//Car Rental
const rentalVehicleType = ["Sedan", "Compact Suv", "Mid Size Suv", "Full Size Suv", "Electric Car", "Hybrid Car", "Light Trucks", "Vans"];

//Photography
const photographyServices = ["Photography only", "Cinematography only", "Drone Video Only", "Photography, Drone Video & Cinematography"];

const photographyExpertise = ["Expert", "Immediate", "Beginner"];

const photosProvidedC1 = ["Pen Drive", "DVD", "Photo print on the paper"];

const photosProvidedC3 = ["Pen Drive", "DVD"];

const photosProvidedC2 = ["Pen Drive", "Photo print on the paper"];

const printedPhoto = ["4 x 6", "5 x 7", "6 x 8", "8 x 10", "8 x 12", "10 x 12", "Others"];
const printedPhotoType = ["Matte", "Glossy"];

const durationCinemaAndDrone = ["Trailer time", "Full Video"];

const photographyTypeServices = ["All professional Photo shoots/Cinematography/Drone equipment", "Professional camera and lens accessories", "Professional Lighting", "Single-snap and multi-shooter options", "Photo editing, color correction and retouching", "Video editing"];
const weddingPartyType = ["Wedding Party", "Engagement Party", "Bridal Shower or Couples' Shower", "Bachelor Party", "Bachelorette Party", "Welcome Party", "Rehearsal Dinner", "Wedding Ceremony and Reception", "After-Party", "Day-After Brunch", "Engagement,Wedding, Holud, Reception (Package)", "Engagement Only", "Holud Only", "Wedding Only", "Reception Only"]
const matchType = ["50 Overs match", "20 Overs Match", "10 overs match", "Other, please specifiy"]
//Shipment

const carTruckTypes = ["New car and light trucks", "Old Car & Light trucks"];
const motorCycleTypes = ["New Motorcycle", "Old Motorcycle"];
const otherVehicles = ["Golf Cart", "ATV", 'Dirt Bikes', "Others"];
const carHauler = ["Open Haulers", "Covered Hauler", "Single Car Haulers"];
const carCondition = ["Operable > to Operational with Keys", "Operational without Keys", "Not Operational without Key", "Not Operational with Key"];
const handlingUnit = ["Pallet", "Crate", "box", "Bag", "Bale", "Bundle", "Can", "Carton",
  "Coil", "Cylinder", "Drum", "Loose", "Pail", "Reel", "Roll", "Pipe", "Cases", "Other"]
const businessType = ["Office", "Shop", "Warehouse"];
const movingAreaRange = ["Within State", "to Another State"];
const officeCarType = ["Used Car", "Light Trucks"];
const officeShipType = ["Full package Service(You relax, Service provider provide all, pack and wrapped items+J21, load and unload all for you) ",
  "Packed Service(You Packed and wrapped, Service provide load and Unload items for you)", "Self Service - (You Packed and help service provided to load and unload item) "
];
const officeShipHauler = ["Open Hauler", "Covered Hauler"];

const constructionTypeOfPlaces = ["Residence", "Office", "Shop", "Warehouse"];
const constructionTypeOfGoods = ["Bricks", "Coarse Aggregates", "TMT Steel & Rod", "Cement", "Sand", "Water Pump", "Water Tank", "Tiles", "Cables & Wires", "Pipe", "Door", "Window", "Drywall", "Others"];
const constructionTypesOfItems = ["Debris", "Tiling", "Wallboard", "Flooring", "Walls", "Windows", "Floorboards", "Plasterboard", "Corrugated Iron", "Concrete Removal", "Cabinets", "Bathroom Items", "Others"];
const constructionTypeOfService = ["Full package Service(You relax, Service provider  load and unload all for you)", "Self Service - (You help service provided to load and unload item)"]
const driedFood = ["Coffees", "Biscuit", "Cereals", "Cookies and crackers", "Dry Beans", "Dry Grains", "Flour", "Sugar", "Herbal and regular teas", "Macaroni and other Pasta", "Powdered milk", "White and brown rice, wild rice", "Chips", "Mango bar", "Dried Dal", "Gram Flour", "Rice Flour", "Sooji", "Puffed Rice", "Lentil", "Gorom Masala", "Salt"];
const vegeFood = ["Cabbage", "Cauliflower", "Mustard green", "Turnip", "Pea", "Hyacinth bean", "String bean", "French bean", "Vegetable soybean", "Sweet gourd", "Bottle gourd", "Wax gourd", "Cucumber", "Ribbed gourd", "Sponse gourd", "Muskmelon", "Watermelon", "Potato", "Sweet pepper", "Red amaranth", "Others"];
const fruitFood = ["Mango", "strawberry", "blackberry", "blueberry", "banana", "grapes", "Cantaloupe", "water melon", "apples", "oranges", "coconuts", "pomegranate", "papaya", "dragon fruit", "lychee", "guava", "Others"];
const timeFrameObj = ["At", "Timeframe", "After"];
const pulocationType = ["Business with fork lift or Dock", "Business without fork lift or Dock", "Residence", "Others"];
const typeOfPlaces = ["Residence", "Office", "Shop", "Warehouse"]

//Move






















// Dining
const diningArr = [
  { name: 'china cab, 2 pc', state: false },
  { name: 'table, folding', state: false },
  { name: 'table, serving', state: false },
  { name: 'tea cart', state: false },
  { name: 'cabinet, med', state: false },
  { name: 'lamp, floor', state: false },
  { name: 'chair, child highchair', state: false },
  { name: 'cabinet, sml', state: false },
  { name: 'china cab, 1 pc', state: false },
  { name: 'lamp, table', state: false },
  { name: 'buffet, base', state: false },
  { name: 'wine rack', state: false },
  { name: 'cabinet, lrg', state: false },
  { name: 'buffet, top', state: false },
  { name: 'chair, high back', state: false },
  { name: 'cabinet, corner', state: false },
  { name: 'table, dining', state: false },
  { name: 'chair, dining', state: false },
  { name: 'hutchappx', state: false },
  { name: 'lamp, lrg', state: false },
];

const livingRoomArr = [
  { name: "tv, over 42 to 47", state: false },
  { name: "lamp, lrg", state: false },
  { name: "bookcase, 6x3", state: false },
  { name: "chair, wicker", state: false },
  { name: "carpet 8x10", state: false },
  { name: "tv stand", state: false },
  { name: "chair, overstuffed", state: false },
  { name: "entertain. unit, sml", state: false },
  { name: "sofa, 3 seat/bed", state: false },
  { name: "lamp, floor", state: false },
  { name: "lamp, floor", state: false },
  { name: "stereo system", state: false },
  { name: "sofa, 4 seat", state: false },
  { name: "curio cabinet", state: false },
  { name: "stool, bar", state: false },
  { name: "sofa, 2 seat", state: false },
  { name: "bookcase, lrg", state: false },
  { name: "chair, arm", state: false },
  { name: "tv, over 21 to 41", state: false },
  { name: "table, side", state: false },
  { name: "piano, babygrand", state: false },
  { name: "lamp, table", state: false },
  { name: "ottoman, lrg", state: false },
  { name: "sofa, sec 4 pc", state: false },
  { name: "chandelier, sml", state: false },
  { name: "wall unit, 2 pc", state: false },
  { name: "wall unit, 3 pc", state: false },
  { name: "wall unit, 1 pc", state: false },
  { name: "wall unit, 4 pc", state: false },
  { name: "piano, spinet", state: false },
  { name: "chair, rocker", state: false },
  { name: "chair, recliner", state: false },
  { name: "clock grandfather", state: false },
  { name: "table, coffee", state: false },
  { name: "stereo cabinet", state: false },
  { name: "tv, big 60", state: false },
  { name: "ottoman, sml", state: false },
  { name: "tv, projector 48 +", state: false },
  { name: "speaker, lrg", state: false },
  { name: "piano, grand", state: false },
  { name: "entertain. unit, lrg", state: false },
  { name: "bookcase, sml", state: false },
  { name: "tv console", state: false },
  { name: "sofa, sec 5 pc", state: false },
  { name: "bookcase, med", state: false },
  { name: "chair, lounge", state: false },
  { name: "speaker, med", state: false },
  { name: "table end", state: false },
  { name: "chandelier, lrg", state: false },
  { name: "piano, upright", state: false }
];

const bedroomArr = [
  { name: "dresser, triple", state: false },
  { name: "bed, queen", state: false },
  { name: "clothes basket", state: false },
  { name: "crib", state: false },
  { name: "bed, double", state: false },
  { name: "futon", state: false },
  { name: "armoire, sml", state: false },
  { name: "bed, water q/k", state: false },
  { name: "cradle", state: false },
  { name: "bed, twin", state: false },
  { name: "lamp, floor", state: false },
  { name: "night stand", state: false },
  { name: "bed, king", state: false },
  { name: "oriental screen", state: false },
  { name: "chest, sml", state: false },
  { name: "hamper", state: false },
  { name: "lamp, lrg", state: false },
  { name: "armoire, lrg", state: false },
  { name: "bed, platform", state: false },
  { name: "bed, bun", state: false },
  { name: "dresser, single", state: false },
  { name: "wardrobe closet", state: false },
  { name: "chest, toy", state: false },
  { name: "dresser, child", state: false },
  { name: "dresser, double", state: false },
  { name: "ironing board", state: false },
  { name: "chest, med", state: false },
  { name: "armoire, med", state: false },
  { name: "lamp, table", state: false },
  { name: "child table/chairs", state: false },
  { name: "chest, cedar", state: false },

];

const kitchenArr = [
  { name: "freezer, sml", state: false },
  { name: "dishwasher", state: false },
  { name: "freezer, lrg", state: false },
  { name: "freezer, med", state: false },
  { name: "microwave, cart", state: false },
  { name: "microwave, sml", state: false },
  { name: "stove", state: false },
  { name: "refrigerator, dormitory", state: false },
  { name: "freezer, xlge", state: false },
  { name: "refrigerator, side/side", state: false },
  { name: "trash can", state: false },
  { name: "table, kitchen", state: false },
  { name: "microwave, lrg", state: false },
  { name: "table, kitchen sml", state: false },
  { name: "chair, kitchen", state: false },
  { name: "refrigerator, upright", state: false },

];

const homeOfficeArr = [
  { name: "desk, single", state: false },
  { name: "file cabinet, sml", state: false },
  { name: "safe, lrg", state: false },
  { name: "desk, return", state: false },
  { name: "chair, office", state: false },
  { name: "copier, reg. office", state: false },
  { name: "safe, xlrg", state: false },
  { name: "safe, sml", state: false },
  { name: "computer system", state: false },
  { name: "copier, table top", state: false },
  { name: "desk, double", state: false },
  { name: "desk, executive", state: false },
  { name: "desk, hutch", state: false },
  { name: "table, conference", state: false },
  { name: "file cabinet, lrg", state: false },
  { name: "desk, computer sml", state: false },

];

const garageArr = [
  { name: "exercise eqp, larg", state: false },
  { name: "table, sml", state: false },
  { name: "bike, child", state: false },
  { name: "exercise eqp, eliptical", state: false },
  { name: "ladder, to 7ft", state: false },
  { name: "work bench, sml", state: false },
  { name: "work bench, med", state: false },
  { name: "tool chest", state: false },
  { name: "exercise eqp, bench", state: false },
  { name: "ladder, to 10ft", state: false },
  { name: "exercise eqp, treadmill", state: false },
  { name: "exercise eqp, bike", state: false },
  { name: "bike, adult", state: false },
  { name: "exercise eqp, sml", state: false },
  { name: "trunk, sml", state: false },
  { name: "trunk, lrg", state: false },
  { name: "wheel barrow", state: false },

];

const outdoorArr = [
  { name: "patio umbrella", state: false },
  { name: "patio set, 5 piece", state: false },
  { name: "plant artificial, lrg", state: false },
  { name: "chair, patio", state: false },
  { name: "lawnmower, ride", state: false },
  { name: "lawnmower, ride", state: false },
  { name: "Barbecue", state: false },
  { name: "table, picnic", state: false },
  { name: "barbecue grill, lrg", state: false },
  { name: "outdoor bench, lrg", state: false },
  { name: "patio table", state: false },
  { name: "chair, stackable", state: false },
  { name: "lawnmower, push", state: false },
  { name: "outdoor bench, sml", state: false },

];


const misArr = [
  { name: "musical instrument", state: false },
  { name: "pictures, poster", state: false },
  { name: "mirror, med", state: false },
  { name: "aquarium stand, sml", state: false },
  { name: "changing table", state: false },
  { name: "instep stroller", state: false },
  { name: "vacuum cleaner", state: false },
  { name: "golf bag", state: false },
  { name: "aquarium stand, lrg", state: false },
  { name: "coat rack", state: false },
  { name: "aquarium, 10 gal", state: false },
  { name: "aquarium, 20 gal", state: false },
  { name: "statue, sml", state: false },
  { name: "washing machine", state: false },
  { name: "air conditioner, lrg", state: false },
  { name: "credenza", state: false },
  { name: "book shelf", state: false },
  { name: "pedestal", state: false },
  { name: "washer/dryer combo", state: false },
  { name: "dryer", state: false },
  { name: "air conditioner, sml", state: false },
  { name: "vanity table", state: false },
  { name: "mirror, lrg", state: false },
  { name: "aquarium, 55 gal", state: false },
  { name: "statue, lrg", state: false },

];

const boxesArr = [
  { name: "large boxes", state: false },
  { name: "medium boxes", state: false },
  { name: "wardrobe boxes", state: false },
  { name: "small boxes", state: false },
]






const locationInformationStructure = [
  { id: 'address1', label: 'Address (1)', type: 'input', subCatId: [0] },
  { id: 'address2', label: 'Address (2)', type: 'input', subCatId: [0] },
  { id: 'city', label: 'City', type: 'input', subCatId: [0] },
  { id: 'country', label: 'Country', type: 'input', subCatId: [0] },
  { id: 'district', label: 'District', type: 'input', subCatId: [0] },
  { id: 'postal_code', label: 'Postal Code', type: 'input', subCatId: [0] },
  { id: 'service_date', label: 'Service Date', type: 'date', subCatId: [0] },
  { id: 'service_time', label: 'Service Time', type: 'time', subCatId: [0] },
];

const additionServicesStructure = [
  { id: 'provide_unit', label: 'Who will provide Unit', type: 'select', options: operationType, subCatId: [0] },
  { id: 'support_need', label: 'Need support to move uniy', type: 'select', options: operationType, subCatId: [0] },
  { id: 'accessories_provided', label: 'All accessories provided by', type: 'select', options: operationType, subCatId: [0] },
  { id: 'tools_provided', label: 'All tools provided by', type: 'select', options: operationType, subCatId: [0] },
  { id: 'provide_item', label: 'Who will provide Item', type: 'select', options: operationType, subCatId: [0] },
]


const acformStructure = [
  { id: 'air_condition_type', label: 'Air Condition Type', type: 'select', options: airConditionType, subCatId: [] },
  { id: 'brand_new', label: 'Is it brand new Installation', type: 'select', options: booleanAns },
  { id: 'location_type', label: 'Type of Location', type: 'select', options: typeOfLocation },
  { id: 'building_stories', label: 'Building Stories', type: 'select', options: buildingStories },
  { id: 'total_square_feet', label: 'Total Square Feet', type: 'input' },
  { id: 'elevator_available', label: 'Elevator to carry items available?', type: 'select', options: booleanAns },
  // {id:'elevator_can_carry_items', label:'Allowed to use elevator to carry items', type:'select', options:booleanAns},
  { id: 'installation_location', label: 'Installation Location', type: 'select', options: installationLocation },
  { id: 'ac_brand', label: 'AC Brand', type: 'input' },
  { id: 'ac_model', label: 'AC Modal', type: 'input' },
  { id: 'ac_size', label: 'AC Size', type: 'select', options: acSize },
  { id: 'btu', label: 'Specify BTU', type: 'input' },
  { id: 'units', label: 'Units to Installed', type: 'select', option: unitToInstalled },
  { id: 'number_of_units', label: 'Number of Units', type: 'input' },
]

const plumberformStructure = [
  { id: 'air_condition_type', label: 'Air Condition Type', type: 'select', options: airConditionType },
  { id: 'brand_new', label: 'Is it brand new Installation', type: 'select', options: booleanAns },
  { id: 'location_type', label: 'Type of Location', type: 'select', options: typeOfLocation },
  { id: 'building_stories', label: 'Building Stories', type: 'select', options: buildingStories },
  { id: 'total_square_feet', label: 'Total Square Feet', type: 'input' },
  { id: 'elevator_available', label: 'Elevator Available', type: 'select', options: booleanAns },
  { id: 'elevator_can_carry_items', label: 'Allowed to use elevator to carry items', type: 'select', options: booleanAns },
  { id: 'installation_location', label: 'Installation Location', type: 'select', options: installationLocation },
  { id: 'ac_brand', label: 'AC Brand', type: 'input' },
  { id: 'ac_model', label: 'AC Modal', type: 'input' },
  { id: 'ac_size', label: 'AC Size', type: 'select', options: acSize },
  { id: 'btu', label: 'Specify BTU', type: 'input' },
  { id: 'units', label: 'Units to Installed', type: 'select', option: unitToInstalled },
  { id: 'number_of_units', label: 'Number of Units', type: 'input' },
]


const shippingLocationInfo = {
  pickup_location: "Destination",
  pickup_stories: "Pick up Stories",
  elevator_pickup: "Elevator Pick up",
  location_type_tf: "Pick up Location Type",
  pickup_tf: "Pick up Time Frame",
  pickupdate: "Pick up Date",
  pickuptime: "Pick up Time",
  dropoff_stories: "Drop off Stories",
  elevator_dropoff: "Elevator Drop off",
  location_type_df: "Drop off Location Type",
  dropoff_tf: "Drop off Time Frame",
  dropoffdate: "Drop off Date",
  dropofftime: "Drop off Time",
  destination_location: "Delivery Location",
  pickupdate_range: "Pick up other date",
  dropoffdate_range: "Drop off other date",
  packing_supplies: "Packing Supplies",
  packing_info: "Packing Item"
}

const catLocationInfo = {
  item_pallete: "Who will provide Item",
  support_need: "Unit Lift/Move Support Provided By",
  accessories_provided: "All accessories provided by",
  tools_provided: "All tools provided by"
}

const InformationObj = {
  "AC Service": {
    ac_brand: "AC Brand",
    ac_model: "AC Modal",
    ac_size: "AC Size",
    air_condition_type: "Air Condition Type",
    brand_new: "Is it brand new Installation",
    btu: "Specify BTU",
    building_stories: "Building Stories",
    elevator_available: "Elevator to carry items available?",
    // elevator_can_carry_items: "Allowed to use elevator to carry items",
    gas_type: "AC Type",
    installation_location: "Dismantle Location",
    location_type: "Type of Location",
    number_of_units: "Number of Units",
    total_square_feet: "Total Square Feet",
    total_units_install_type: "Units to be installed",
    unitsType: "Units to be Installed",
    emergency: "Is it emergency"
  },
  "Plumber": {
    "property_type": "Type of Property",
    "location_type": "Type of Location",
    "building_stories": "Building Stories",
    "drain_type": "Drain Type",
    "duct_type": "Duct Type",
    "number_of_vents": "Number of Vents serving",
    "system_located": "Where is system located",
    "eliminate_bacteria": "Would like to serve for  eliminate mold, bacteria, and dust mites?",
    "line_type": "Line Type",
    "number_of_lines": "How many lines",
    "service_type": "Type of Service",
    "sink_installation_type": "Sink Installation Location",
    "pipe_type": "Type of Pipe",
    "pipe_in_feet": "How many feet of line",
    "faucet_type": "Faucet Type",
    "number_of_faucet": "Faucets needs installation/replacement?",
    "more_faucet_type": "Type of Faucet",
    "number_of_sink_holes": "Number of Sink Holes",
    "meter_brand": "Brand",
    "meter_model": "Model",
    "meter_color": "Color",
    "number_of_room": "Number of room or unit",
    "remove_existing_sink": "Need to remove existing sink",
    "remove_existing_sink_plumbing": "Need to remove existing sink and plumbing",
    "remove_existing_sink_plumbing_faucet": "Need to remove existing sink, plumbing and faucet",
    "need_new_plumbing": "Need New Plumbing",
    "toilet_type_of_service": "Type of Service",
    "number_of_unit": "Number of Unit",
    "number_of_toilet": "How many toilets?",
    "toilet_type": "Toilet Types"
  },
  "Appliance": {
    "property_type": "Type of Property",
    "location_type": "Type of Location",
    "building_stories": "Building Stories",
    "meter_brand": "Brand",
    "meter_model": "Model",
    "service_type": "Type of Service",
    "number_of_unit": "Number of Unit",
    "brand_new": "Brand New Installation",
    "demolishing": "Need any Demolishing before Installation",
    "demolishing_date": "Please specify when need to demolish",
    "move_haul_old_unit": "In case of Replacement, who will move and haul old unit",
    "move_haul_expect_money_customer": "Do Customer expect to get any money for Old Unit before move",
    "move_haul_expect_money_service_provider": "Service provider Haul old unit without any additonal cost",
    old_unit: "Do you still have old unit installed?",
    old_unit_remove: "Do you need old unit to be removed and hauled? "
  },
  "Electrician": {
    "heading": "Job Title",
    "location_type": "Type of Location",
    "addition_location": "Addition Location",
    "total_square_feet": "Total Square Feet",
    "building_stories": "Building Stories",
    "lightening_service": "Lighting Service",
    "lightening_material": "Types of Lighting Materials",
    "number_of_bathroom": "Number of Bathroom",
    "service_type": "Service Type",
    "surface": "Surface",
    "drilling_required": "Is Drilling Required",
    "lightening_fixture_type": "Lighting Fixture Type",
    "number_of_fixtures": "Number of Fixtures",
    "occasion": "Occasion",
    "number_of_outlets": "Number of Outlets",
    "installation_area": "Installation Area",
    "number_of_breakers": "How many breaker",
    "brand_type": "Yes it a Brand new installation",
    "meter_brand": "Brand",
    "meter_model": "Model",
    "generator_type": "Type of Generator",
    "generator_unit_sizes": "Generator Unit Sizess",
    "spa_size": "Spa & Jacuzzi size",
    "number_of_unit": "Number of Unit",
    "surfaces_spa": "Surfaces for SPA",
    "is_surface_ready": "Is Surface Ready",
    "prepare_surface": "Who will prepare or build Surface",
    "prepare_thickness": "Prefared Thickness of Surface(Gravel or Concrete)",
    "privacy_screen": "Need any privacy screening",
    "privacy_screen_type": "Type of Privecy Screen",
    "floor_height": "Floor Height",
    "ceiling_provided": "Who will provide Ceiling / Exhaust FAN",
    "outlet_provided": "Who will provide Outlet",
    "generator_provided": "Who will provide Generator Unit",
    "lightening_provided": "Who will provide Lightening Materials",
    "supporting_hand": "Will there be a supporting hand by Customer",
    "accessories_provided": "All accessories provided by",
    "tools_provided": "All tools provided by",
    "spa_provided_by": "Who will provide new Electrical Spa/Jacuzzi unit",
    "install_surface": "Who will install surface",
    "material_surface": "Who will provide materials for surface",
    "privacy_screening": "Who will provide Privacy screening",
    "privacy_materials": "Who will provide Privacy Material for screening"
  },
  "Tutor": {
    "heading": "Job Title",
    "tutor_medium": "Which Medium",
    "tutor_grade": "Grade",
    "tutor_subjects": "Preferred Subject",
    "gender": "Gender of Student",
    "tutor_gender": "Gender of Teacher",
    "tution_week": "Tuition Per Week",
    "tution_time": "Preferred Tuition Time"
  },
  "Photography": {
    serviceType: 'Type of Service',
    expertise: 'Photography Expertise Level',
    photosprovidedIn: 'Service',
    printedSize: 'Printed Photographs size',
    printedPhotoType: 'Printed Photographs paper type',
    cinema_trailer_time: 'Trailer Time Length',
    cinema_video_time: 'Full Video Time Length',
    drone_trailer_time: 'Drone Video Trailer Time Length',
    drone_video_time: 'Drone Full Video Time Length',
    event_start_date: 'Event Start Date',
    event_start_time: 'Event Start Time',
    event_end_time: 'Event End Time',
    event_end_date: 'Event End Date',
    wedding_party_type: "Wedding Party Type",
    match_type: 'Match Type',
    return_cost_provided: 'Return Cost Provided By',
    long_distance: 'Long Distance Travel',
    travel_with_customer: 'Photographer travel with Customer',
    travel_pay_by: 'Who will pay Photograher travel cost',
    multiple_day_stay: 'Multiple Day Stay',
    years_of_experience: 'Photographer (Years) of Experience',
    photography_type_services: 'Photography services',
    building_stories: 'Building Stories',
    elevator_available: 'Elevator Available',
  },
  "Shipping": {
    year: "Year",
    make: "Make",
    model: "Model",
    length: 'Length (Inches)',
    width: 'Width (Inches)',
    height: 'Height (Inches)',
    call_before_delivery: 'Call before delivery',
    lifegate_service: 'Liftgate Service at pickup',
    quantity: "Quantity",
    hazardous: "Hazardous",
    stackable: 'Stackable',
    delivery_apt: 'Delivery appt required',
    handling_unit: 'Handling Unit',
    type: 'Vehicle Types',
    conditions: 'Condition',
    neccesary_documents: 'Does have all necessary paper',
    title: 'Title',
    type: 'Type of Item',
    service_type: 'Type of Service',
    car_hauler: 'Preferred Vehicle Type',
    items: 'Items',
    cubicles_disassembled: "Will cubicles or other items need to be disassembled",
    cubicles_resassembled: "Will cubicles or other items need to be re-assembled",
    item_stackable: "Item(s) stackable: (item has flat surfaces and can sustain the weight of another object)",
    item_crated: "Item Palletized or boxed",
    item_pallete: "Item on Pallete:(item is on a wooden or plastic pallet and can be moved by forklift))",
    water_protection: "Water Protection",
    support_load: "Need Support to load and Unload Item",
    number_of_people: "Number of People",
    support_load: "Need Support to load and Unload Item",
    support_by: "Who will provide support for load and unload",
    property_type: "Type of residence",
    moving_area_range: "Moving Area Range",
    number_of_bedroom: "Number of bedroom",
    vehicle_transported: "Any vehicle to be transported",
    car_type: "Car Type",
    business_type: "Type of Business",
    area: "Moving Area Range",
    type_of_places: 'Type of Places',
    desease_animal: "Desease Animal",
    weight: 'Weight (lbs)'
  },
  "Cleaning": {
    "heading": "Job Title",
    "property_type": "Type of Property",
    "location_type": "Type of Location",
    "building_stories": "Building Stories",
    "furniture_type": "Furniture Type",
    "furniture_material": "Material",
    "emergency": "Is this emergency",
    "remove_upholstery_seat": "Need to remove upholstery, seat and cover",
    "paint_surface": "Paint Surface",
    "paint_surface_location": "Paint Surface Location",
    "total_square_feet": "Total Square Feet",
    "space": "Space",
    "total_square_feet_image": "Total Square Feet Image",
    "service_type": "Car Service Type",
    "texture_paint_application": "Textured Paint application",
    "texture_type": "Texture Type",
    "scrap_surface": "Need to scrape surface",
    "paint_type": "Type of Paint",
    "paint_method": "Paint Application Method",
    "more_paint_type": "More Paints Type",
    "more_paint_furnish": "More Paints Furnish",
    "paint_finish": "Paint Finish",
    "labour_service": "Service for Labor Only:- Material will be provided by Customer",
    "labour_service_tools": "Provide Service with tools and equipments",
    "furniture_moving": "Furniture moving provided by",
    "drop_cloth_floor": "Drop Cloth for Floor or Carpet provided by",
    "sheet_covering": "Sheet covering for furniture provided by",
    "equipments_provided": "Tools, Brushes, Rollers, putty, paint tape  and all other tools Provided by",
    "car_type": "Car Type",
    "car_make": "Car Make",
    "meter_model": "Car Model",
    "car_year": "Car Year",
    "car_paint_color": "Paint Color",
    "car_preferred_brush": "Prefared Paint Brand"
  },
  "Painter": {
    "heading": "Job Title",
    "property_type": "Type of Property",
    "location_type": "Type of Location",
    "building_stories": "Building Stories",
    "furniture_type": "Furniture Type",
    "furniture_material": "Material",
    "remove_upholstery_seat": "Need to remove upholstery, seat and cover",
    "paint_surface": "Paint Surface",
    "paint_surface_location": "Paint Surface Location",
    "total_square_feet": "Total Square Feet",
    "total_square_feet_image": "Total Square Feet Image",
    "service_type": "Car Service Type",
    "texture_paint_application": "Textured Paint application",
    "texture_type": "Texture Type",
    "scrap_surface": "Need to scrape surface",
    "paint_type": "Type of Paint",
    "paint_method": "Paint Application Method",
    "more_paint_type": "More Paints Type",
    "more_paint_furnish": "More Paints Furnish",
    "paint_finish": "Paint Finish",
    "labour_service": "Service for Labor Only:- Material will be provided by Customer",
    "labour_service_tools": "Provide Service with tools and equipments",
    "furniture_moving": "Furniture moving provided by",
    "drop_cloth_floor": "Drop Cloth for Floor or Carpet provided by",
    "sheet_covering": "Sheet covering for furniture provided by",
    "equipments_provided": "Tools, Brushes, Rollers, putty, paint tape  and all other tools Provided by",
    "car_type": "Car Type",
    "car_make": "Car Make",
    "meter_model": "Car Model",
    "car_year": "Car Year",
    "car_paint_color": "Paint Color",
    "car_preferred_brush": "Prefared Paint Brand"
  },
  "TV / Television": {
    "heading": "Job Title",
    "property_type": "Type of Property",
    "location_type": "Type of Location",
    "building_stories": "Building Stories",
    "number_of_unit": "Number of Unit",
    "service_type": "Type of Service",
    "wall_type": "Type of Wall",
    "meter_brand": "Brand",
    "meter_model": "Model",
    "tv_size": "Tv Inches"
  },
  "Household Item": {
    "heading": "Job Title",
    "property_type": "Type of Property",
    "location_type": "Type of Location",
    "building_stories": "Building Stories",
    "meter_brand": "Brand",
    "meter_model": "Model",
    "unit_size": "Unit Size",
    "number_of_unit": "Number of Unit",
    "provide_ips": "Who will provide IPS",
    "window_type": "Window Type",
    "window_material": "Window Material",
    "service_type": "Type of Service",
    "door_type": "Door Type",
    "door_material": "Door Material"
  },
  "Car Rental": {
    "heading": "Job Title",
    "vehicle_type": "Vehicle Type",
    "number_of_passenger_adult": "Adult Numbers",
    "number_of_passenger_children": "Children Numbers",
    "emergency": "Any medical emergency passenger travelling",
    "total_miles": "Expected Total Miles"
  },
  "Furniture Assemble": {
    "heading": "Job Title",
    "furniture_type": "Furniture Type",
    "meter_brand": "Furniture Brand",
    "need_transportation_furniture": "Do you need transportation for furniture pickup",
    "store_location_address1": "Store Location Address (1)",
    "store_location_address2": "Store Location Address (2)",
    "store_location_city": "Store Location City",
    "store_location_country": "Store Location Country",
    "store_location_zip": "Store Location Zip",
    "item_details": "Item Details",
    customerhelp: "Customer Will be available for item pick up",
    customerhelppickup: 'Will customer help load and unload'
  },
  "Pest Control": {
    "heading": "Job Title",
    "pest_type": "Type of Pest Control",
    "residence": "Type of Residence",
    "commercial": "Type of Commericial",
    "number_of_bedroom": "Number of Bedroom",
    "service_type": "Service For",
    "expected_square_feet": "Total sq ft for floor",
    "number_of_stories_covered": "Number of stories to be covered",
    "product_kidfriendly": "Kid and Pet Safe Product Preferred When Possible",
    "product_kids_save": "Kid and Pet Safe Product Preferred When Possible",
    "parking": "Do you want Pest Control for parking area as well",
    "parking_area_insqft": "What is the Parking Area sq ft",
    "service_frequency": "Service Frequency",
    "warranty": "One Time: Warranty duration (months)",
    "receive_reservice": "When in contract with Pest Control, would you like to receive reservice if infestation comes back",
    "product_preference": "Type of Product Preference",
    "furniture_moved": "If needed, furniture will be moved and replaced by",
    "kitchen_moved": "If required, item removal from kitchen cabinets provided by",
    "boxes_moved": "Boxes for kitchen cabinet items placement provided by",
    "items_moved": "Items back to kitchen shelf",
    "bathroom_moved": "If required, item removal from  Bathroom cabinets provided by",
    "boxes_bathroom_moved": "Boxes for bathroom cabinet items placement provided by",
    "boxes_bathroom_moved_back": "Items back to bathroom cabinet",
    "number_of_bathrooms": "Number of Bathrooms",
    "drawers_moved": "If required, item removal from drawers, shelf & armoire provided by",
    "boxes_drawers_moved": "Boxes for drawers, shelf & armoire  items placement provided by",
    "boxes_drawers_moved_back": "Items back to drawers, shelf & armoire",
    "number_of_furniture": "Number of Furniture"
  },
  "Lawn Care": {
    "heading": "Job Title",
    "property_type": "Property Type",
    "lawn_care_yards": "Lawn Care Yards",
    "total_sf": "Total SF",
    "lawn_area_layer": "Lawn Area Layer",
    "lawn_mowing_frequency": "Lawn mowing frequency",
    "soon_need_service": "How soon you need lawn service",
    "need_extensive_cleaning": "Do your Lawn need extensive fall or Springs cleaning",
    "lawn_seeding_type": "Lawn Seeding type",
    "lawn_grass_type": "Grass Type",
    "lawn_seed_type": "Grass Seed Type",
    "yard_sod": "Does the yards need to be graded before sod is placed",
    "current_yard_surface": "What is current yards surface",
    "paint_details": "Please specify recommended Paint details",
    "expected_size_square_feet": "Expected size of Deck(square Feet)"
  },
  "Deck": {
    "heading": "Job Title",
    "property_type": "Property Type",
    "deck_issues": "Type of Issues",
    "deck_job_type": "Job Type",
    "materials": "Deck Materials",
    "deck_placemnet": "Deck placement",
    "apply_sealer": "Do you want to Apply sealer",
    "duck_paint": "Do you want to paint Deck",
    "paint_details": "Please specify recommended Paint details",
    "expected_size_square_feet": "Expected size of Deck(square Feet)"
  },
  "Flooring": {
    "heading": "Job Title",
    "property_type": "Property Type",
    "tiles_material": "Tiles Materials",
    "tiles_installation": "Tiles installation place",
    "expected_square_feet": "Expected square Feet for complete work",
    "service_type": "Service Type",
    "improve_tiles_place": "Do you need to prepare tile place",
    "floor_material": "Floor Materials",
    "floor_underlayment": "Do you need flooring underlayment",
    "floor_installation": "Floor installation place",
    "carpet_material": "Carpet Materials",
    "carpet_style": "Carpet Styles",
    "carpet_padding": "Do you need Carpet Padding",
    "carpet_type": "Carpet Type"
  },
  "Car Mechanic": {
    "heading": "Job Title",
    "car_type": "Car Type",
    "meter_model": "Car Model",
    "car_year": "Car Year",
    "trim": "Car Trim",
    "car_start": "Does your car start",
    "garage_location": "Where is your car located",
    "service_type": "Service Type",
    "service_engine": "Engine",
    "service_ac": "AC"
  },
  "Pressure Washer": {
    "heading": "Job Title",
    "property_type": "Property Type",
    "power_washing_area": "Power Washing Area",
    "expected_square_feet": "Expected square Feet for complete work"
  },
  "Roof": {
    "heading": "Job Title",
    "property_type": "Property Type",
    "roof_material": "Roof Material",
    "house_square_feet": "Roof square Feet",
    "building_stories": "Building Stories",
    "service_type": "Type of Service"
  },
  "Gutter Cleaner": {
    "heading": "Job Title",
    "property_type": "Property Type",
    "location_type": "Type of Location",
    "building_stories": "Building Storiess",
    "service_type": "Type of Service"
  }
}



const serviceObj = [
  {
    id: 0,
    name: 'AC Service',
    label: 'Air Condition',
    textual: 'Air Condition Unit',
    description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/ac1.png').default,
    para: 'Need to install or repair AC? Hire verified AC experts.',
    subcategory: [
      { id: 0, value: 'AC installation', para: 'Hire experts who have skills and right tools to install your AC unit easily.' },
      { id: 1, value: 'AC dismantle', para: 'Need to remove your old AC unit? Our experts can complete the task efficiently.' },
      { id: 2, value: 'AC un-Install & Installation with a new unit – same place', para: 'Take no stress, providers will handle all work to uninstall and place your new unit professionally.' },
      { id: 3, value: 'AC Gas Charge', para: 'Save on energy bills and get experts to gas charge your AC for better functioning.' },
      { id: 4, value: 'AC Compressor Fitting With Gas Charge', para: 'Warm air from AC vent? You probably need a new compressor and gas charge, so hire an expert.' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3, 4], required: true },
      { id: 'air_condition_type', label: 'Air Condition Type', type: 'select', options: airConditionType, subCatId: [0, 1, 2, 3, 4], required: true },
      { id: 'brand_new', label: 'Is it brand new Installation', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 1, 2], required: true },
      { id: 'location_type', label: 'Type of Location', type: 'select', options: typeOfLocation, subCatId: [0, 1, 2], required: true },
      { id: 'building_stories', label: 'Building Stories', type: 'select', options: buildingStories, subCatId: [0, 1, 2], required: true },
      { id: 'total_square_feet', label: 'Total Square Feet (ft)', type: 'input', subCatId: [0, 1, 2], mode: 'number' },
      { id: 'total_units_install_type', label: 'Units to be installed', type: 'select', options: totalUnitsToInstallType, subCatId: [2] },
      { id: 'elevator_available', label: 'Elevator to carry items available?', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 1, 2] },
      // {id:'elevator_can_carry_items', label:'Allowed to use elevator to carry items', type:'select', options:booleanAns,mode:'radio',subCatId:[0,1,2]},
      { id: 'installation_location', label: 'Installation Location', type: 'select', options: installationLocation, subCatId: [0, 2, 3, 4] },
      { id: 'installation_location', label: 'Dismantle Location', type: 'select', options: installationLocation, subCatId: [1] },
      { id: 'ac_brand', label: 'AC Brand', type: 'input', subCatId: [0, 1, 2, 3, 4] },
      { id: 'ac_model', label: 'AC Model', type: 'input', subCatId: [0, 1, 2, 3, 4] },
      { id: 'ac_size', label: 'AC Size', type: 'select', options: acSize, subCatId: [0, 3, 4] },
      { id: 'gas_type', label: 'Gas Type', type: 'select', options: gasType, subCatId: [0, 1, 2, 3, 4] },
      { id: 'btu', label: 'Specify BTU', type: 'input', subCatId: [0, 1, 2, 3, 4] },
      { id: 'unitsType', label: 'Units to be installed', type: 'select', options: unitToInstalled, subCatId: [0, 1, 3, 4] },
      { id: 'number_of_units', label: 'Number of Units', type: 'input', subCatId: [0, 1, 2, 3, 4], mode: 'number' },
      { id: 'emergency', label: 'Is it emergency?', type: 'select', subCatId: [0, 1, 2, 3, 4], mode: 'radio', },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 1,
    name: 'Plumber',
    label: 'Plumber',
    textual: 'Tools',
    description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/plumber1.png').default,
    para: 'Clogged or slow drain can be frustrating? Let the experts handle the work for you.',
    subcategory: [
      { id: 0, value: 'Drain cleaning', para: 'Experts use right tools and chemicals to quickly clean the drain.' },
      { id: 1, value: 'Air Duct Cleaning', para: 'Get experts to clean air ducts for better air circulation and reduced allergy.' },
      { id: 2, value: 'Water line installation/Replacement', para: 'Poor water line installation can lead to leaks. Let professionals handle this.' },
      { id: 3, value: 'New plumbing fixture installation' },
      { id: 4, value: 'Faucet replacement', para: 'Save time and effort and hire experts to replace your faucet.' },
      { id: 5, value: 'Water Meter Installation' },
      { id: 6, value: 'New Sink Installation', para: 'Installing new sink? Here you will find right expert for your job.' },
      { id: 7, value: 'Sink Replacement', para: 'Verified taskers can replace sink and handle all necessary plumbing work.' },
      { id: 8, value: 'Garbage disposal installation/replacement', para: 'Garbae disposals help keep sinks clean. Hire expert today to install one.' },
      { id: 9, value: 'Toilet installation With Existing plumbing only', para: 'Upgrading your bathroom? Find experts that are right for your project.' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] },
      { id: 'property_type', label: 'Type of Property', type: 'select', options: [...propertyType, "Restaurant"], subCatId: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] },
      { id: 'location_type', label: 'Type of Location', type: 'select', options: [...typeOfLocation, "Restaurant"], subCatId: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] },
      { id: 'building_stories', label: 'Building Stories', type: 'select', options: buildingStories, subCatId: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] },
      { id: 'drain_type', label: 'Drain Type', type: 'select', options: drainType, subCatId: [0] },
      { id: 'duct_type', label: 'Duct Type', type: 'select', options: ductType, subCatId: [1] },
      { id: 'number_of_vents', label: 'Number of Vents servicing', type: 'select', options: ventsServing, subCatId: [1] },
      { id: 'system_located', label: 'Where is system located', type: 'select', options: systemLocated, subCatId: [1] },
      { id: 'eliminate_bacteria', label: 'Add service to remove mold, bacteria, and dust mites?', type: 'select', options: booleanAns, mode: 'radio', subCatId: [1] },
      { id: 'line_type', label: 'Line Type', type: 'select', options: lineType, subCatId: [2, 3] },
      { id: 'number_of_lines', label: 'How many lines', type: 'select', options: oneToTen, subCatId: [2, 3] },
      { id: 'service_type', label: 'Type of Service', type: 'select', options: serviceType, subCatId: [2] },
      { id: 'sink_installation_type', label: 'Sink Installation Type', type: 'select', options: sinkInstallationType, subCatId: [6, 7] },
      { id: 'pipe_type', label: 'Type of Pipe', type: 'select', options: pipeType, subCatId: [2, 3, 6, 7] },
      { id: 'pipe_in_feet', label: 'How Many feet of Line (Estimated)?', type: 'select', options: oneToTen, subCatId: [3, 6, 7] },
      { id: 'faucet_type', label: 'Faucet Installation Location', type: 'select', options: faucetType, subCatId: [4,] },
      { id: 'number_of_faucet', label: 'How many faucets need Installation/Replacement', type: 'select', options: oneToTen, subCatId: [4, 6, 7] },
      { id: 'more_faucet_type', label: 'Type of Faucet', type: 'select', options: moreFaucet, subCatId: [4] },
      { id: 'number_of_sink_holes', label: 'Number of Sink Holes', type: 'select', options: sinkHoles, subCatId: [4] },
      { id: 'meter_brand', label: 'Meter Brand', type: 'input', subCatId: [5, 8, 9] },
      { id: 'meter_model', label: 'Meter Model', type: 'input', subCatId: [5, 8, 9] },
      { id: 'meter_color', label: 'Color', type: 'input', subCatId: [9] },
      // {id:'sink_installation_type', label:'Sink Installation Location', type:'select',option:sinkInstallationLocation,subCatId:[6,7]},
      { id: 'number_of_room', label: 'Number of room or unit', type: 'select', options: oneToTen, subCatId: [7] },
      { id: 'remove_existing_sink', label: 'Need to remove existing sink', type: 'select', options: booleanAns, mode: 'radio', subCatId: [7] },
      { id: 'remove_existing_sink_plumbing', label: 'Need to remove existing sink and plumbing', type: 'select', options: booleanAns, mode: 'radio', subCatId: [7] },
      { id: 'remove_existing_sink_plumbing_faucet', label: 'Need to remove existing sink, plumbing and faucet', type: 'select', options: booleanAns, mode: 'radio', subCatId: [7] },
      { id: 'need_new_plumbing', label: 'Need New Plumbing', type: 'select', options: booleanAns, mode: 'radio', subCatId: [8] },
      { id: 'toilet_type_of_service', label: 'Type of Service', type: 'select', options: toiletInstallationType, subCatId: [8] },
      { id: 'number_of_unit', label: 'Number of Unit', type: 'select', options: oneToTen, subCatId: [8] },
      { id: 'number_of_toilet', label: 'How many toilets?', type: 'select', options: oneToTen, subCatId: [9] },
      { id: 'toilet_type', label: 'Toilet Types', type: 'select', options: toiletType, subCatId: [9] },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 2,
    name: 'Appliance',
    label: 'Appliance',
    textual: 'Tools',
    description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/appliance1.png').default,
    para: 'Non-Functional Appliance can be stress? Our certified experts can service and repair appliance for smooth operation.',
    subcategory: [
      { id: 0, value: 'Microwave Oven Repair', para: 'Microwave not working? Don\'t worry, hire professionals to fix it.' },
      { id: 1, value: 'Refrigerator', para: 'Certified professionals can diagnose the issue and get your refrigerator working again.' },
      { id: 2, value: 'Range/Stove', para: 'Experts can fix any brand and type of range and stove tops.' },
      { id: 3, value: 'Washing Machine', para: 'Laundry piling up? Don\'t stress, hire professional to repair the washing machine.' },
      { id: 4, value: 'Dryer', para: 'Servicing or repairing your dryer, find the professional to do just that.' },
      { id: 5, value: 'Kitchen Hood Installation or Replacement', para: 'For your safety, get experts to install the kitchen hood properly.' },
      { id: 6, value: 'Water Purifier', para: 'Water purifier needs repairing? Hire the right expert for the job.' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3, 4, 5, 6] },
      { id: 'property_type', label: 'Type of Property', type: 'select', options: propertyType, subCatId: [0, 1, 2, 3, 4, 5, 6] },
      { id: 'location_type', label: 'Type of Location', type: 'select', options: [...typeOfLocation, "Restaurant"], subCatId: [0, 1, 2, 3, 4, 5, 6] },
      { id: 'building_stories', label: 'Building Stories', type: 'select', options: buildingStories, subCatId: [0, 1, 2, 3, 4, 5, 6] },
      { id: 'meter_brand', label: 'Brand', type: 'input', subCatId: [0, 1, 2, 3, 4, 5, 6] },
      { id: 'meter_model', label: 'Model', type: 'input', subCatId: [0, 1, 2, 3, 4, 5, 6] },
      { id: 'service_type', label: 'Type of Service', type: 'select', options: applianceMTypeOfService, subCatId: [0] },
      { id: 'number_of_unit', label: 'Number of Unit', type: 'select', options: oneToTen, subCatId: [0, 1, 2, 3, 4, 5, 6] },
      { id: 'service_type', label: 'Type of Service', type: 'select', options: applianceRTypeOfService, subCatId: [1] },
      { id: 'service_type', label: 'Type of Service', type: 'select', options: applianceRSTypeOfService, subCatId: [2, 5, 6] },
      { id: 'service_type', label: 'Type of Service', type: 'select', options: applianceWMTypeOfService, subCatId: [3, 4] },
      { id: 'brand_new', label: 'Brand New Installation', type: 'select', options: booleanAns, mode: 'radio', subCatId: [2, 5, 6] },
      { id: 'demolishing', label: 'Need any demolition or prep work done before installation?', type: 'select', options: booleanAns, mode: 'radio', subCatId: [2, 5, 6] },
      { id: 'demolishing_date', label: 'Please Specify the type demolition or prep work required before installation?', type: 'input', subCatId: [2, 5, 6] },
      { id: 'old_unit', label: 'Do you still have old unit installed?', type: 'select', mode: 'radio', subCatId: [2, 5, 6] },
      { id: 'old_unit_remove', label: 'Do you need old unit to be removed and hauled?', type: 'select', mode: 'radio', subCatId: [2, 5, 6] },
      { id: 'move_haul_old_unit', label: 'In case of Replacement, who will move and haul old unit', type: 'select', options: operationType, subCatId: [2, 3, 4, 5, 6] },
      { id: 'move_haul_expect_money_customer', label: 'Does Customer expect to get any money for Old Unit before move', type: 'select', options: booleanAns, mode: 'radio', subCatId: [2, 3, 4, 5, 6] },
      { id: 'move_haul_expect_money_service_provider', label: 'Service provider Haul old unit without any additonal cost', type: 'select', options: booleanAns, mode: 'radio', subCatId: [2, 3, 4, 5, 6] },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 3,
    name: 'Electrician',
    label: 'Electrician',
    textual: 'Wires, tools etc',
    description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/electrician1.png').default,
    para: 'Hire licensed electricians to handle your electrical repairs or installations safely and up to code.',
    subcategory: [
      { id: 0, value: 'Ceiling Fan Installation', para: 'Feel safe and let an expert securely install and wire your ceiling fan.' },
      { id: 1, value: 'Ceiling Fan Replacement Only', para: 'Time to switch the old ceiling fan? Our experts will handle all electrical and installation work.' },
      { id: 2, value: 'Exhaust Fan Installation Only', para: 'Get licensed professional to install your exhaust fan and keep your kitchen smoke and grease free.' },
      { id: 3, value: 'Electrical Panel Replacement', para: 'Upgrading electrical panel? You can rely on our certified experts to replace any elecrical panel.' },
      { id: 4, value: 'Electrical Spa, Pool & Jacuzzi Wiring', para: 'Qualified servicers can take care of all associated electrical and installation work for your spa or jacuzzi.' },
      { id: 5, value: 'Electrical Outlet Replacement', para: 'Damaged electrical outlets can be safety issue? Hire qualified experts to replace them using proper hardware.' },
      { id: 6, value: 'Generator Installation', para: 'Power outage can be a serious matter. Licensed pros can entirely set it up for you' },
      { id: 7, value: 'Holiday Lighting', para: 'Bring the joy to celebration and have experts light up your house or yard.' },
      { id: 8, value: 'Kitchen/Dining Lighting', para: 'Find and hire experts to install any kind of lighting; chandelier, flush mount, recessed light, and more.' },
      { id: 9, value: 'Bathroom Lighting', para: 'Certified professionals can install or replace bathroom lighting with proper tools and harware.' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] },
      { id: 'location_type', label: 'Type of Location', type: 'select', options: typeOfLocation, subCatId: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] },
      { id: 'addition_location', label: 'Additional Lighiting Setup', type: 'select', options: electricianAdditionLocation, subCatId: [7, 9] },
      { id: 'total_square_feet', label: 'Total Square Feet (ft)', type: 'input', mode: 'number', subCatId: [7, 8, 9] },
      { id: 'building_stories', label: 'Building Stories', type: 'select', options: buildingStories, subCatId: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] },
      { id: 'lightening_service', label: 'Lighting Service', type: 'select', options: electricianLighteningService, subCatId: [7, 8, 9] },
      { id: 'lightening_material', label: 'Types of Lighting Materials', type: 'input', subCatId: [7, 8, 9] },
      { id: 'number_of_bathroom', label: 'Number of Bathroom', type: 'select', options: oneToTen, subCatId: [9] },
      { id: 'service_type', label: 'Service Type', type: 'select', options: electricianServiceType, subCatId: [8, 9] },
      { id: 'surface', label: 'Surface', type: 'select', options: electricianSurfaceType, subCatId: [8, 9] },
      { id: 'drilling_required', label: 'Is Drilling Required', type: 'select', options: booleanAns, mode: 'radio', subCatId: [8, 9] },
      { id: 'lightening_fixture_type', label: 'Lighting Fixtures Type', type: 'select', options: electricianFixturesType, subCatId: [8, 9] },
      { id: 'number_of_fixtures', label: 'Number of Fixtures', type: 'select', options: oneToTen, subCatId: [8, 9] },
      { id: 'occasion', label: 'Occasion', type: 'select', options: electricianOccassions, subCatId: [7] },
      { id: 'number_of_outlets', label: 'Number of Outlets', type: 'select', options: oneToTen, subCatId: [5] },
      { id: 'installation_area', label: 'Installation Area', type: 'select', options: electricianInstallationArea, subCatId: [0, 1, 2, 3] },
      { id: 'number_of_breakers', label: 'How many breaker', type: 'select', options: oneToTen, subCatId: [3] },
      { id: 'brand_type', label: 'Yes it a Brand new installation', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 2] },
      { id: 'meter_brand', label: 'Brand', type: 'input', subCatId: [0, 1, 2, 4, 6] },
      { id: 'meter_model', label: 'Model', type: 'input', subCatId: [0, 1, 2, 4, 6] },
      { id: 'generator_type', label: 'Type of Generator', type: 'select', options: electricianGeneratorType, subCatId: [6] },
      { id: 'generator_unit_sizes', label: 'Generator Unit Size', type: 'select', options: electricianGeneratorUnitSizes, subCatId: [6] },
      { id: 'spa_size', label: 'Spa & Jacuzzi size', type: 'input', mode: 'number', subCatId: [4] },
      { id: 'number_of_unit', label: 'Number of Unit', type: 'select', options: oneToTen, subCatId: [0, 1, 2, 3, 4] },
      { id: 'surfaces_spa', label: 'Surfaces for SPA', type: 'select', options: electriciansurfacesForSpa, subCatId: [4] },
      { id: 'is_surface_ready', label: 'Is Surface Ready', type: 'select', options: booleanAns, mode: 'radio', subCatId: [4] },
      { id: 'prepare_surface', label: 'Who will prepare or build Surface', type: 'select', options: booleanAns, mode: 'radio', subCatId: [4] },
      { id: 'prepare_thickness', label: 'Prefared Thickness of Surface(Gravel or Concrete)', type: 'input', mode: 'number', subCatId: [4] },
      { id: 'privacy_screen', label: 'Need any privacy screening', type: 'select', options: booleanAns, mode: 'radio', subCatId: [4] },
      { id: 'privacy_screen_type', label: 'Type of Privecy Screen', type: 'select', options: electricianPrivacyScreen, subCatId: [4] },
      { id: 'floor_height', label: 'Floor Height', type: 'select', options: electricianfloorUnits, subCatId: [0, 1, 2, 3] },
      { id: 'ceiling_provided', label: 'Who will provide Ceiling / Exhaust FAN', type: 'select', options: operationType, subCatId: [0, 1, 2] },
      { id: 'outlet_provided', label: 'Who will provide Outlet', type: 'select', options: operationType, subCatId: [5] },
      { id: 'generator_provided', label: 'Who will provide Generator Unit', type: 'select', options: operationType, subCatId: [6] },
      { id: 'lightening_provided', label: 'Who will provide Lighting Materials', type: 'select', options: operationType, subCatId: [7, 8, 9] },
      // {id:'supporting_hand', label:'Will there be a supporting hand by Customer', type:'select', options:booleanAns,mode:'radio', subCatId:[0,1,2,4,5,6,7,89]},
      // {id:'accessories_provided', label:'All accessories provided by', type:'select', options:operationType, subCatId:[0,1,2,4,5,6,7,8,9]},
      // {id:'tools_provided', label:'All tools provided by', type:'select', options:operationType, subCatId:[0,1,2,4,5,7,8,9]},
      { id: 'spa_provided_by', label: 'Who will provide new Electrical Spa/Jacuzzi unit', type: 'select', options: operationType, subCatId: [4] },
      { id: 'install_surface', label: 'Who will install surface', type: 'select', options: operationType, subCatId: [4] },
      { id: 'material_surface', label: 'Who will provide materials for surface', type: 'select', options: operationType, subCatId: [4] },
      { id: 'privacy_screening', label: 'Who will provide Privacy screening', type: 'select', options: operationType, subCatId: [4] },
      { id: 'privacy_materials', label: 'Who will provide Privacy Material for screening', type: 'select', options: operationType, subCatId: [4] },
    ],
    locationInformationStructure,
  },
  {
    id: 4,
    name: 'Tutor',
    label: 'Tutor',
    description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/tutor1.png').default,
    para: 'Tutoring enhance academic progress. Hire verified tutors for online or face-to-face tutoring.',
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0] },
      { id: 'tutor_medium', label: 'Which Medium', type: 'select', options: tutorMedium, subCatId: [0] },
      { id: 'tutor_grade', label: 'Grade', type: 'select', options: tutorGrade, subCatId: [0] },
      { id: 'tutor_subjects', label: 'Preferred Subject', type: 'select', options: tutorSubject, subCatId: [0] },
      { id: 'gender', label: 'Gender of Student', type: 'select', options: gender, subCatId: [0] },
      { id: 'tutor_gender', label: 'Gender of Teacher', type: 'select', options: gender, subCatId: [0] },
      { id: 'tution_week', label: 'Tuition Per Week', type: 'select', options: tutionPerWeek, subCatId: [0] },
      { id: 'tution_time', label: 'Preferred Tuition Time', type: 'select', options: tutorPreferredTime, subCatId: [0] },
    ],
    locationInformationStructure,
  },
  {
    id: 5,
    name: 'Photography',
    label: 'Photography',
    description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/photo1.png').default,
    para: 'Hire experts to capture that perfect moment with all its essence for picture perfect memory!',
    subcategory: [
      { id: 0, value: 'Wedding Party', para: 'Experienced photographer and right camera;  turn your special day into pristine memory.' },
      { id: 1, value: 'Birthday', para: 'You will never be younger than last year! Get photographed for memories.' },
      { id: 2, value: 'Baby Shower', para: 'Event photographers have an eye for details. Let them capture the highlights.' },
      { id: 3, value: 'Newborn', para: 'Photographers specialize in props, backgrounds, editing, retouching, etc. Newborn photoshoot? Hire experts.' },
      { id: 4, value: 'Commercial Event', para: 'Professional photographers will capture key moments, speakers, and highlights for your company event.' },
      { id: 5, value: 'Graduation Party', para: 'Celebration of your achievements deserve high quality photos. Hire experienced photographers today!' },
      { id: 6, value: 'Fashion', para: 'Photos for catalog or magazines require professional photographers for high quality images.' },
      { id: 7, value: 'Cricket Game', para: 'Capture that action and winning moment! Hire professional sports photographer.' },
      { id: 8, value: 'Soccer Game', para: 'Capture that action and winning moment! Hire professional sports photographer.' },
      { id: 9, value: 'Outdoor', para: 'Professional photographers work with the natural light and background for that perfect picture.' },
      { id: 10, value: 'Seminar/Office', para: 'Professional photographers will capture key moments, speakers, and highlights for your company event.' },
      { id: 11, value: 'Nature', para: 'Photographers use tech and equipment to photograph the essence of nature.' },
      { id: 12, value: 'Ecommerce/Website launch', para: 'Professional photographers will capture key moments, speakers, and highlights for your company event.' },
      { id: 13, value: 'Personal & Portfolio', para: 'Hire professional photographer for high quality pictures taken with specialized cameras and lighting.' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] },


    ]

  },
  // {
  //   id:6,
  //   name:'Travel',
  //   description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
  //   image: require('../assets/images_/travel1.png').default,
  //   para:'Let the car rental agencies find you. Pick the car that fits your travel needs',
  // },
  {
    id: 7,
    name: 'Shipping',
    label: 'Shipping',
    para: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/shipping1.png').default,
    options: [
      {
        id: 0,
        name: 'Vehicle & Equipments',
        label: 'Vehicle & Equipments',
        description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
        image: require('../assets/images_/car1.png').default,
        para: 'Vehicle shipping can be stressful. Get verified transporters and be stress-free.',
        subcategory: [
          { id: 0, value: 'Cars/Light Truck', para: 'Shipping your new car. Hire verified transporters and skip the worry.' },
          { id: 1, value: 'Motor Cycles', para: 'Find the right truck to safely transport your favorite motorcycle.' },
          { id: 2, value: 'Vehicle parts', para: 'Found vehicle part in another city? Hire truck to bring it to you.' },
          { id: 3, value: 'Other Vehicles', para: 'Find the right truck to ship any vehicle.' },
          { id: 4, value: 'Antique Vehicles', para: 'Find the right transportation to safely ship that special car.' },
          { id: 5, value: 'Farm equipment', para: 'Heavy vehicle? No problem, contractors with the right transportation will find you.' },
        ],
        formStructure: [
          { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3, 4, 5] },
          { id: 'year', label: 'Year', type: 'input', mode: 'number', subCatId: [0, 1, 3, 4, 5], max: 4 },
          { id: 'make', label: 'Make', type: 'input', subCatId: [0, 1, 3, 4, 5] },
          { id: 'model', label: 'Model', type: 'input', subCatId: [0, 1, 3, 4, 5] },
          { id: 'weight', label: 'Weight (lb)', type: 'input', subCatId: [2] },
          { id: 'call_before_delivery', label: 'Call before delivery', type: 'select', options: booleanAns, mode: 'radio', subCatId: [2] },
          { id: 'lifegate_service', label: 'Liftgate Service at pickup', type: 'input', subCatId: [2] },
          { id: 'inside_pickup', label: 'Inside Pickup', type: 'input', subCatId: [2] },
          { id: 'quantity', label: 'Quantity', type: 'input', mode: 'number', addition: "only", subCatId: [2] },
          { id: 'length', label: 'Length (Inches)', type: 'input', mode: 'number', subCatId: [2] },
          { id: 'width', label: 'Width (Inches)', type: 'input', mode: 'number', subCatId: [2] },
          { id: 'height', label: 'Height (Inches)', type: 'input', mode: 'number', subCatId: [2] },
          { id: 'hazardous', label: 'Hazardous', type: 'input', subCatId: [2] },
          { id: 'stackable', label: 'Stackable', type: 'select', options: booleanAns, mode: 'radio', subCatId: [2] },
          { id: 'delivery_apt', label: 'Delivery appt required', type: 'select', options: booleanAns, mode: 'radio', subCatId: [2] },
          { id: 'handling_unit', label: 'Handling Unit', type: 'select', options: handlingUnit?.filter(x => !["Pallet", "Crate", "box", "Bag", "Bale", "Bundle", "Can", "Carton"]?.includes(x)), subCatId: [2] },
          { id: 'type', label: 'Car Types', type: 'select', options: carTruckTypes, subCatId: [0] },
          { id: 'type', label: 'Motorcycles Types', type: 'select', options: motorCycleTypes, subCatId: [1] },
          { id: 'type', label: 'Types', type: 'select', options: [...otherVehicles], subCatId: [3] },
          { id: 'conditions', label: 'Vehicle Condition', type: 'select', options: carCondition, subCatId: [0, 1, 3, 4, 5] },
          { id: 'neccesary_documents', label: 'Does have all necessary paper', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 1, 2, 3, 4, 5] },
          { id: 'car_hauler', label: 'Type of hauler', type: 'select', options: carHauler, subCatId: [0, 1, 2, 3, 4] },

        ]
      },
      {
        id: 1,
        name: 'Everyday Items',
        label: 'Everyday Items',
        description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
        image: require('../assets/images_/household1.png').default,
        para: 'Shipping  across nation has never been this easy. List shipment and let transportation come to you.',
        subcategory: [
          { id: 0, value: 'Furniture', para: 'Add all details and requirement for safely transporting that special furniture.' },
          { id: 1, value: 'Appliances', para: 'Appliances need proper protection during transport. Verified carriers will do the job right.' },
          { id: 2, value: 'Antiquated', para: 'Find verified carriers that can ship your antique with proper care.' },
          { id: 3, value: 'Fragile (Mirror/Glass)', para: 'Fragile Item? Licensed carriers are equipped with proper transporation facilities.' },
          { id: 4, value: 'Musical instruments', para: 'Ship piano or guitar, carriers of all types can be found here.' },
        ],
      },
      {
        id: 2,
        name: 'Moves',
        label: 'Moves',
        description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
        image: require('../assets/images_/moves1.png').default,
        para: 'find all types of moving services to accommodate your requirements.',
        subcategory: [
          { id: 0, value: 'SMALL STUDIO' },
          { id: 1, value: 'LARGE STUDIO, 1 BEDROOM APT' },
          { id: 2, value: '2 BEDROOM APARTMENT' },
          { id: 3, value: '3 BEDROOM APT, 2 BEDROOM HOUSE' },
          // {id:4,  value:'Musical instruments (child Menu -1)'},
          { id: 5, value: '3 BEDROOM HOUSE' },
          { id: 6, value: '4 BEDROOM HOUSE' },
        ],
        formStructure: [
          { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3, 4, 5, 6] },
          { id: 'property_type', label: 'Type of residence', type: 'select', options: propertyType, subCatId: [0, 1, 2, 3, 4, 5, 6] },
          { id: 'moving_area_range', label: 'Moving Area Range', type: 'select', options: movingAreaRange, subCatId: [0, 1, 2, 3, 4, 5, 6] },
          { id: 'service_type', label: 'Type of service', type: 'select', options: officeShipType, subCatId: [0, 1, 2, 3, 4, 5, 6] },
          { id: 'number_of_bedroom', label: 'Number of bedroom', type: 'select', options: oneToTen, subCatId: [0, 1, 2, 3, 4, 5, 6] },
          { id: 'vehicle_transported', label: 'Any vehicle to be transported', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 1, 2, 3, 4, 5, 6] },
          { id: 'car_type', label: 'Car Type', dependent: 'vehicle_transported', type: 'select', options: officeCarType, subCatId: [0, 1, 2, 3, 4, 5, 6] },
          { id: 'year', label: 'Year', dependent: 'vehicle_transported', type: 'input', mode: 'date', subCatId: [0, 1, 2, 3, 4, 5, 6] },
          { id: 'make', label: 'Make', dependent: 'vehicle_transported', type: 'input', subCatId: [0, 1, 2, 3, 4, 5, 6] },
          { id: 'model', label: 'Model', dependent: 'vehicle_transported', type: 'input', subCatId: [0, 1, 2, 3, 4, 5, 6] },
          { id: 'conditions', label: 'Vehicle Condition', dependent: 'vehicle_transported', type: 'select', options: carCondition, subCatId: [0, 1, 2, 3, 4, 5, 6] },
          { id: 'all_papers', label: 'Has all necessary paper', dependent: 'vehicle_transported', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 1, 2, 3, 4, 5, 6] },

        ]
      },
      {
        id: 3,
        name: 'Office',
        label: 'Office',
        description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
        image: require('../assets/images_/office1.png').default,
        para: 'Commercial movers are highly specialized and organized making relocation a comfortable experience.',
        subcategory: [
          { id: 0, value: 'Office/Commercial', para: 'Find and hire all inclusive office/Commercial movers; furniture, tech, equipment  and more.' },
        ],
        formStructure: [
          { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3, 4, 5, 6] },
          { id: 'business_type', label: 'Type of Business', type: 'select', options: businessType, subCatId: [0] },
          { id: 'area', label: 'Moving Area Range', type: 'select', options: movingAreaRange, subCatId: [0] },
          { id: 'service_type', label: 'Type of Sevice', type: 'select', options: officeShipType, subCatId: [0] },
          { id: 'vehicle_transported', label: 'Any vehicle to be transported', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0] },
          { id: 'car_type', label: 'Car Type', dependent: 'vehicle_transported', type: 'select', options: officeCarType, mode: 'radio', subCatId: [0] },
          { id: 'year', label: 'Year', dependent: 'vehicle_transported', type: 'input', mode: 'date', subCatId: [0] },
          { id: 'make', label: 'Make', dependent: 'vehicle_transported', type: 'input', subCatId: [0] },
          { id: 'model', label: 'Model', dependent: 'vehicle_transported', type: 'input', subCatId: [0] },
          { id: 'conditions', label: 'Vehicle Condition', dependent: 'vehicle_transported', type: 'select', options: carCondition, subCatId: [0] },
          { id: 'all_papers', label: 'Has all necessary paper', dependent: 'vehicle_transported', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0] },
          { id: 'car_hauler', label: 'Preferred Vehicle Type', type: 'select', options: officeShipHauler, subCatId: [0] },
        ]
      },
      {
        id: 4,
        name: 'Construction',
        label: 'Construction',
        description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
        image: require('../assets/images_/construction1.png').default,
        para: 'Top-rated construction contractors provide high quality planning, leading, executing, supervising and inspecting a project.',
        subcategory: [
          { id: 0, value: 'Goods', para: 'Haul your construction materials safely and on time, on or off site.' },
          { id: 1, value: 'Demolition Debris', para: 'Construction haul professional offer on time services like loading and waste management.' },
          { id: 2, value: 'New commercial good', para: 'Find licensed freight specialist who are insured and safely transport goods.' },
          { id: 3, value: 'Old commercial good', para: 'Need basic freight service? Find fright coompanies with various service options.' },
        ],
        formStructure: [
          { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3, 4, 5, 6] },
          // {id:'title', label:'Title of Item', type:'input', subCatId:[0,1,2,3]},

          { id: 'type', label: 'Type of Item', type: 'select', options: constructionTypeOfGoods, subCatId: [0] },
          { id: 'type_of_places', label: 'Type of Places', type: 'select', options: typeOfPlaces, subCatId: [1] },
          { id: 'type', label: 'Type of Item', type: 'select', options: constructionTypesOfItems, subCatId: [1, 2, 3] },
          { id: 'service_type', label: 'Type of service', type: 'select', options: constructionTypeOfService, subCatId: [0, 1, 2, 3] },
          { id: 'car_hauler', label: 'Prefared Vehicle type', type: 'select', options: officeShipHauler, subCatId: [0, 1, 2, 3] },
        ]
      },
      {
        id: 5,
        name: 'Freight',
        label: 'Freight',
        description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
        image: require('../assets/images_/freight1.png').default,
        para: 'Freight companies ship heavy cargo by road, air, ocean. Find that fits your need best.',
        subcategory: [
          { id: 0, value: 'New commercial good', para: 'Find licensed freight specialist who are insured and safely transport goods.' },
          { id: 1, value: 'Old commercial good', para: 'Need basic freight service? Find fright companies with various service options. ' },
        ],
        formStructure: [
          { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1] },
          // {id:'title', label:'Title of Item', type:'input', subCatId:[0,1]},
          { id: 'type', label: 'Type of Item', type: 'input', subCatId: [0, 1] },
          { id: 'service_type', label: 'Type of service', type: 'select', options: constructionTypeOfService, subCatId: [0, 1] },
          // {id:'car_hauler', label:'Prefared Vehicle type', type:'select', options: officeShipHauler, subCatId:[0,1]},

        ]
      },
      {
        id: 6,
        name: 'Live Animals',
        label: 'Live Animals',
        description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
        image: require('../assets/images_/animal1.png').default,
        para: 'Hire licensed live animal movers for moving your pet or commercial animals safely.',
        subcategory: [
          { id: 0, value: 'Cow', para: 'Livestock carriers follow regulations and are equipped with proper transportation, air ventilation, etc.' },
          { id: 1, value: 'Buffalo', para: 'Livestock carriers follow regulations and are equipped with proper transportation, air ventilation, etc.' },
          { id: 2, value: 'Goat', para: 'Livestock carriers follow regulations and are equipped with proper transportation, air ventilation, etc.' },
          { id: 3, value: 'Poultry', para: 'Upright crates, ventilation, temperature control; find these and more features when hiring carrier.' },
        ],
        formStructure: [
          { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3] },
          // {id:'title', label:'Title', type:'input', subCatId:[0,1,2,3]},
          { id: 'number_of_animal', label: 'Number of Animal', type: 'input', mode: 'number', subCatId: [0, 1, 2, 3] },
          { id: 'desease_animal', label: 'Do the animals have any known disease?', type: 'select', options: booleanAns, mode: "radio", subCatId: [0, 1, 2, 3] },
          { id: 'service_type', label: 'Type of service', type: 'select', options: constructionTypeOfService, subCatId: [0, 1, 2, 3] },
          { id: 'car_hauler', label: 'Preferred Vehicle type', type: 'select', options: officeShipHauler, subCatId: [0, 1, 2, 3] },

        ]
      },
      {
        id: 7,
        name: 'Electronics',
        label: 'Electronics',
        description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
        image: require('../assets/images_/TV1.png').default,
        para: 'Large item or small appliance require different methods of shipment. Find right carrier and save money.',
        subcategory: [
          { id: 0, value: 'Home Electronics', para: 'Shipping small item? Finding LTL carrier might save you money for shipping.' },
          { id: 1, value: 'Commercial Electronics', para: 'When listing valuable item, adding specific details will enhance the shipping experirence.' },
        ],
        formStructure: [
          { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1] },
          // {id:'title', label:'Title of Item', type:'input', subCatId:[0,1]},
          { id: 'type', label: 'Electronic Items', type: 'input', subCatId: [0, 1] },
          { id: 'service_type', label: 'Type of service', type: 'select', options: constructionTypeOfService, subCatId: [0, 1] },
          { id: 'car_hauler', label: 'Preferred Vehicle Type', type: 'select', options: officeShipHauler, subCatId: [0, 1] },

        ]
      },
      {
        id: 8,
        name: 'Food',
        label: 'Food',
        description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
        image: require('../assets/images_/food1.png').default,
        para: 'Most food shipping companies will transport all food items and offer refrigerated and temperature controlled carriers.',
        subcategory: [
          { id: 0, value: 'Dry goods', para: 'Temperature controlled carrier ensure freshness of dry goods during shipping.' },
          { id: 1, value: 'Produce/Vegetables', para: 'Fresh produce require air circulation and refrigeration. Find experts to ship your produce.' },
          { id: 2, value: 'Fruits', para: 'For fresh delivery find companies with expertise, proper carrier, and on-time delivery.' },
          { id: 3, value: 'Packaged items', para: 'Fresh produce require air circulation and refrigeration. Find experts to ship your produce.' },
          { id: 4, value: 'Frozen Item', para: 'Before booking carefully examine if the company knows frozen food shippping regulations.' },
          { id: 5, value: 'Eggs & dairy', para: 'Transporting perishables subject to federal laws and regulations. Hire company that knows.' },
        ],
        formStructure: [
          { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3, 4, 5] },
          { id: 'weight', label: 'Weight (lb)', type: 'input', subCatId: [0, 1, 2, 3, 4, 5], mode: 'number' },
          { id: 'items', label: 'Dried Food', type: 'select', options: driedFood, subCatId: [0] },
          { id: 'items', label: 'Vegetable Items', type: 'select', options: vegeFood, subCatId: [1] },
          { id: 'items', label: 'Fruit Items', type: 'select', options: fruitFood, subCatId: [2] },
          { id: 'items', label: 'Items', type: 'input', subCatId: [3, 4, 5] },
          { id: 'service_type', label: 'Type of service', type: 'select', options: constructionTypeOfService, subCatId: [0, 1, 2, 3, 4, 5] },
          { id: 'car_hauler', label: 'Preferred Vehicle Type', type: 'select', options: [...officeShipHauler, "Add Frozen Temperature-Control Vehicle"], subCatId: [0, 1, 2, 3, 4, 5] },


        ]
      },

    ]
  },
  {
    id: 8,
    name: 'Cleaning',
    label: 'Cleaning',
    description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/cleaner1.png').default,
    para: 'All types of cleaning services to choose from. Make your  pick and get reliable contractors for the job.',
    subcategory: [
      { id: 0, value: 'Upholstery Cleaning', para: 'Give your upholstery look of newness. For fast cleaning and drying hire services.' },
      { id: 1, value: 'Carpet/Area Rug', para: 'Expensive rugs need extra care. Hire servicer for taking care of that special rug.' },
      { id: 2, value: 'Entire Apartment/Home', para: 'Get one time or regular cleaning services and save time and money.' },
      { id: 3, value: 'Move in/Move out', para: 'Moving? Don’t add cleaning to your to-do list. Hire licensed cleaning services.' },
      { id: 4, value: 'Commercial Spaces', para: 'Licensed cleaning services available to maintain the cleaniless of your office.' },
      { id: 5, value: 'Pool', para: 'Hire licensed contractors for maintain and service your pool. You enjoy the dive!' },
      // {id:6,  value:'Move in/Move out', para:'Moving? Don’t add cleaning to your to-do list. Hire licensed cleaning services.'},
      { id: 6, value: 'Tanks', para: 'Dirty water is a health concern. Get licensed services to clean water tanks.' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3, 4, 5, 6, 7] },
      { id: 'service_type', label: 'Type of Cleaning Service-Multiple Selection', type: 'select', multiple: true, options: cleaningServiceType, subCatId: [0, 1] },
      { id: 'service_type', label: 'Service Type', type: 'select', options: cleaningMoveServiceType, subCatId: [3] },
      { id: 'space', label: 'Space', type: 'select', options: spaceInfo, subCatId: [2, 3] },
      { id: 'service_type', label: 'Service Type', type: 'select', options: cleaningTankServiceType, subCatId: [6] },
      { id: 'number_of_tanks', label: 'Number of tanks', type: 'input', mode: 'number', subCatId: [6] },
      { id: 'service_location', label: 'Service For', type: 'select', options: cleaningCommericial, subCatId: [4] },
      { id: 'service_location', label: 'Service For', type: 'select', options: cleaningPoolServiceLocation, subCatId: [5] },
      { id: 'service_type', label: 'Service Type', type: 'select', options: cleaningPoolServiceType, subCatId: [5] },
      { id: 'emergency', label: 'Is this emergency', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 1, 2, 3] },
      { id: 'pool_size', label: 'Pool Size', type: 'select', options: cleaningPoolSize, subCatId: [5] },
      { id: 'total_square_feet', label: 'Total Square Feet (ft)', type: 'input', mode: 'number', subCatId: [4] },
      { id: 'upholstry_type', label: 'Type of Upholstery', type: 'select', options: cleaningupholsteryType, subCatId: [0] },
      { id: 'size_type', label: 'Size and Type (Size of sofa/chair) ', type: 'select', options: cleaningchairSize, subCatId: [0] },
      { id: 'number_pieces_furniture', label: 'Number of Pieces of Furniture', type: 'input', mode: 'number', subCatId: [0] },
      { id: 'service_for', label: 'Service For', type: 'select', options: cleaningServiceFor, subCatId: [1] },
      { id: 'area_rugs', label: 'Area rugs', type: 'select', options: booleanAns, mode: 'radio', subCatId: [1] },
      { id: 'rug_length', label: 'Area Length (Inches)', type: 'input', mode: 'number', subCatId: [1] },
      { id: 'rug_width', label: 'Area Width (Inches)', type: 'input', mode: 'number', subCatId: [1] },
      { id: 'number_of_rugs', label: 'Number of Rooms', type: 'select', options: oneToTen, subCatId: [1] },
      { id: 'service_tools_material', label: 'Service with tools and Materials', type: 'select', options: booleanAns, mode: 'radio', subCatId: [6] },
      { id: 'service_tools_material_labour', label: 'Service with tools and Materials (Labour Only)', type: 'select', options: booleanAns, mode: 'radio', subCatId: [6] },
      { id: 'tools_material_provided', label: 'Tools & materials provided by', type: 'select', options: operationType, subCatId: [6] },
      { id: 'furniture_moved', label: 'Furniture Moved By', type: 'select', options: operationType, subCatId: [1, 2, 3, 4, 6] },
      { id: 'tools_provided', label: 'Broom, Cleaning Spray, Rags, and other cleaning tools & materials provided by', type: 'select', options: operationType, subCatId: [2, 3, 4] },
      { id: 'recommended_product', label: 'Recommended Cleaning product', type: 'select', options: cleaningrecommendedProduct, subCatId: [2, 3, 4, 5] },
      { id: 'clean_type_basic', label: 'Clean Type Basic Clean', type: 'select', options: cleaningType, subCatId: [2, 3, 4] },
      { id: 'clean_type_deep', label: 'Clean Type Deep Clean', type: 'select', options: cleaningType, subCatId: [2, 3, 4] },
      // {id:'emergency',label:'Is it emergency', type:'select', options:booleanAns,mode:'radio',subCatId:[2,3]},
      // {id:'cleaning_space',label:'Space', type:'select', options:cleaningSpace,subCatId:[2,3]},
      { id: 'number_of_bathroom', label: 'Bathroom', type: 'select', options: oneToTen, subCatId: [2, 3] },
      // {id:'half_bathroom',label:'Bathroom', type:'select', options:oneToTen,subCatId:[2,3]},
      { id: 'number_of_balconies', label: 'Balconies', type: 'input', mode: 'number', options: oneToTen, subCatId: [2, 3] },
      // {id:'total_sf',label:'Total SF', type:'input',mode:"number",subCatId:[2,3,4,5]},
      { id: 'floor_type', label: 'Type of Floor', type: 'select', options: cleaningTypeOfFloor, subCatId: [2, 3, 4, 5] },
      { id: 'cleaning_included', label: 'Cleaning Includes', type: 'select', options: cleaningIncluded, subCatId: [2, 3] },

    ],
    locationInformationStructure,
  },
  {
    id: 9,
    name: 'Painter',
    label: 'Painter',
    description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/painter1.png').default,
    para: 'Paint can beautify and protect a surface. Hire licensed contractors for your paint projects.',
    subcategory: [
      { id: 0, value: 'Furniture Paint and Polish (Varnish)', para: 'Bring back shine to furnitures with fresh coat of paint and varnish.' },
      { id: 1, value: 'Interior Space', para: 'Paint your home to beautify or protect. Hire verified contractors to paint.' },
      { id: 2, value: 'Exterior Space', para: 'Protect your home from natural elements. Licensed contractors  can get the job done.' },
      { id: 3, value: 'Car', para: 'Does your car need paint job? Find the right professional for car paint.' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3] },
      { id: 'property_type', label: 'Type of Property', type: 'select', options: propertyType, subCatId: [0, 1, 2] },
      { id: 'location_type', label: 'Type of Location', type: 'select', options: typeOfLocation, subCatId: [0, 1, 2] },
      { id: 'building_stories', label: 'Building Stories', type: 'select', options: buildingStories, subCatId: [0, 1, 2] },
      { id: 'furniture_type', label: 'Furniture Type', type: 'select', options: furnitureType, subCatId: [0] },
      { id: 'furniture_material', label: 'Material', type: 'select', options: furniturematerialType, subCatId: [0] },
      { id: 'remove_upholstery_seat', label: 'Need to remove upholstery, seat and cover', type: 'select', options: [...booleanAns, "Other"], mode: 'radio', subCatId: [0] },
      { id: 'paint_surface', label: 'Paint Surface', type: 'select', options: paintSurface, subCatId: [0, 1, 2] },
      { id: 'paint_surface_location', label: 'Paint Surface Location', type: 'select', options: surfaceLocation, subCatId: [1] },
      { id: 'paint_surface_location', label: 'Paint Surface Location', type: 'select', options: exteriorSurfaceLocation, subCatId: [2] },
      { id: 'total_square_feet', label: 'Total Square Feet', type: 'select', options: totalSurfaceFeet, subCatId: [1, 2] },
      { id: 'total_square_feet_image', label: 'Total Square Feet Image', type: 'image', subCatId: [1, 2] },
      { id: 'service_type', label: 'Type of Service', type: 'select', options: furnitureTypeService, subCatId: [1, 2], multiple: true },
      { id: 'texture_paint_application', label: 'Textured Paint application', type: 'select', options: booleanAns, mode: 'radio', subCatId: [1, 2] },
      { id: 'texture_type', label: 'Texture Type', type: 'select', options: textureType, subCatId: [1, 2] },
      { id: 'scrap_surface', label: 'Need to scrape surface', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0] },
      { id: 'paint_type', label: 'Type of Paint', type: 'select', options: paintType, subCatId: [0, 1, 2, 3] },
      { id: 'paint_method', label: 'Paint Application Method', type: 'select', options: paintMethod, subCatId: [1, 2] },
      { id: 'more_paint_type', label: 'Paints Type', type: 'select', options: morePaintType, subCatId: [2] },
      { id: 'more_paint_type', label: 'More Paints Type', type: 'select', options: moreInteriorPaintType, subCatId: [1] },
      { id: 'more_paint_furnish', label: 'More Paints Furnish', type: 'select', options: paintInteriorFinish, subCatId: [1] },
      { id: 'paint_finish', label: 'Paint Finish', type: 'select', options: paintFinish, subCatId: [0, 2] },
      { id: 'labour_service', label: 'Service for Labor Only:- Material will be provided by Customer', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 1, 2, 3] },
      { id: 'labour_service_tools', label: 'Provide Service with tools and equipments', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 1, 2, 3] },
      { id: 'furniture_moving', label: 'Furniture moving provided by', type: 'select', options: operationType, subCatId: [0, 1, 2] },
      { id: 'drop_cloth_floor', label: 'Drop Cloth for Floor or Carpet provided by', type: 'select', options: operationType, subCatId: [0, 1, 2] },
      { id: 'sheet_covering', label: 'Sheet covering for furniture provided by', type: 'select', options: operationType, subCatId: [0, 1, 2] },
      { id: 'equipments_provided', label: 'Tools, Brushes, Rollers, putty, paint tape  and all other tools Provided by', type: 'select', options: operationType, subCatId: [0, 1, 2] },
      { id: 'car_type', label: 'Car Type', type: 'select', options: CarType, subCatId: [3] },
      { id: 'car_make', label: 'Car Make', type: 'input', subCatId: [3] },
      { id: 'meter_model', label: 'Car Model', type: 'input', subCatId: [3] },
      { id: 'car_year', label: 'Car Year', type: 'input', mode: 'number', max: 4, subCatId: [3] },
      { id: 'service_type', label: 'Car Service Type', type: 'select', options: paintCarType, subCatId: [3], multiple: true },
      { id: 'car_paint_color', label: 'Paint Color', type: 'input', subCatId: [3] },
      { id: 'car_preferred_brush', label: 'Prefared Paint Brand', type: 'input', subCatId: [3] },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 10,
    name: 'TV / Television',
    label: 'TV / Television',
    description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/TV1.png').default,
    para: 'Don\'t wait and miss your favorite show. Get expert today to fix or install TV.',
    subcategory: [
      { id: 0, value: 'LCD/LED repair', para: 'Issue assessment, repairing or installing TV? Search and hire the right expert.' },
      { id: 1, value: 'Wall Mount', para: 'Need to mount your TV?' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1] },
      { id: 'property_type', label: 'Type of Property', type: 'select', options: propertyType, subCatId: [0, 1] },
      { id: 'location_type', label: 'Type of Location', type: 'select', options: [...typeOfLocation, "Restaurant"], subCatId: [0, 1] },
      { id: 'building_stories', label: 'Building Stories', type: 'select', options: buildingStories, subCatId: [0, 1] },
      { id: 'number_of_unit', label: 'Number of Unit', type: 'select', options: oneToTen, subCatId: [0, 1] },
      { id: 'service_type', label: 'Type of Service', type: 'select', options: tvlcdserviceType, subCatId: [0] },
      { id: 'service_type', label: 'Type of Service', type: 'select', options: tvwellmountserviceType, subCatId: [1] },
      { id: 'wall_type', label: 'Type of Wall', type: 'select', options: wallType, subCatId: [1] },
      { id: 'meter_brand', label: 'Brand', type: 'select', options: tvBrand, subCatId: [0, 1] },
      { id: 'meter_model', label: 'Model', type: 'input', subCatId: [0, 1] },
      { id: 'tv_size', label: 'Tv Inches', type: 'select', options: tvInches, subCatId: [0, 1] },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 11,
    name: 'Household Item',
    label: 'Household Item',
    description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/household1.png').default,
    para: 'Find high quality contractors for household installation tasks.',
    subcategory: [
      { id: 0, value: 'Geyser Installation', para: 'Get licensed experts to install and connect the geyser safely.' },
      { id: 1, value: 'IPS Installation', para: 'Need help with IPS installation? Find the right tasker today.' },
      { id: 2, value: 'Window', para: 'Need new windows or replace old ones? Verified contractors can be found here.' },
      { id: 3, value: 'Door', para: 'Hire certified contractors to install or replace any kind of doors.' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3] },
      { id: 'property_type', label: 'Type of Property', type: 'select', options: propertyType, subCatId: [0, 1, 2, 3] },
      { id: 'location_type', label: 'Type of Location', type: 'select', options: [...typeOfLocation, "Restaurant"], subCatId: [0, 1, 2, 3] },
      { id: 'building_stories', label: 'Building Stories', type: 'select', options: buildingStories, subCatId: [0, 1, 2, 3] },
      { id: 'meter_brand', label: 'Brand', type: 'select', options: tvBrand, subCatId: [0, 1] },
      { id: 'meter_model', label: 'Model', type: 'input', mode: 'number', subCatId: [0, 1] },
      { id: 'unit_size', label: 'Unit Size', type: 'select', options: householdUnitSize, subCatId: [0] },
      { id: 'number_of_unit', label: 'Number of Unit', type: 'select', options: oneToTen, subCatId: [0, 1] },
      { id: 'provide_ips', label: 'Who will provide IPS', type: 'select', options: operationType, subCatId: [1] },
      { id: 'window_type', label: 'Window Type', type: 'select', options: windowType, subCatId: [2] },
      { id: 'window_material', label: 'Window Material', type: 'select', options: windowTypeMaterial, subCatId: [2] },
      { id: 'service_type', label: 'Type of Service', type: 'select', options: householdServiceType, subCatId: [2, 3] },
      { id: 'door_type', label: 'Door Type', type: 'select', options: doorType, subCatId: [3] },
      { id: 'door_material', label: 'Door Material', type: 'select', options: doorMaterial, subCatId: [3] },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 12,
    name: 'Car Rental',
    label: 'Car Rental',
    description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/car1.png').default,
    para: 'Let the car rental agencies find you. Pick the car that fits your travel needs',
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0] },
      { id: 'vehicle_type', label: 'Vehicle Type', type: 'select', options: rentalVehicleType, subCatId: [0] },
      { id: 'emergency', label: 'Any medical emergency passenger traveling', type: 'select', options: booleanAns, subCatId: [0], mode: 'radio' },
      { id: 'total_miles', label: 'Expected Total Miles', type: 'input', subCatId: [0], mode: 'number' },
      { id: 'number_of_passenger_adult', label: 'Adult Numbers', type: 'input', subCatId: [0], mode: 'number' },
      { id: 'number_of_passenger_children', label: 'Children Numbers', type: 'input', subCatId: [0], mode: 'number' },
    ],
    locationInformationStructure,
  },
  {
    id: 14,
    name: 'Furniture Assemble',
    label: 'Furniture Assemble',
    description: 'Lorem Ipsum is simply.Lorem Ipsum is simply.Lorem Ipsum is simply',
    image: require('../assets/images_/furniture1.png').default,
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0] },
      { id: 'furniture_type', label: 'Furniture Type', type: 'select', options: assembleFurnitureType, subCatId: [0] },
      { id: 'meter_brand', label: 'Furniture Brand', type: 'select', options: assembleFurnitureBrand, subCatId: [0] },
      { id: 'need_transportation_furniture', label: 'Do you need transportation for furniture pickup', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0] },
      { id: 'store_location_address1', label: 'Store Location Address (1)', type: 'input', subCatId: [0], depend: "need_transportation_furniture" },
      { id: 'store_location_address2', label: 'Store Location Address (2)', type: 'input', subCatId: [0], depend: "need_transportation_furniture" },
      { id: 'store_location_city', label: 'Store Location City', type: 'input', subCatId: [0], depend: "need_transportation_furniture" },
      { id: 'store_location_country', label: 'Store Location Country', type: 'input', subCatId: [0], depend: "need_transportation_furniture" },
      { id: 'store_location_zip', label: 'Store Location Zip', type: 'input', subCatId: [0], depend: "need_transportation_furniture" },
      { id: 'item_details', label: 'Item Details', type: 'input', subCatId: [0, 1], textarea: true },
      { id: 'customerhelp', label: 'Customer Will be available for item pick up', type: 'select', mode: 'radio', subCatId: [0], options: booleanAns, textarea: true },
      { id: 'customerhelppickup', label: 'Will customer help load and unload.', type: 'select', mode: 'radio', subCatId: [0], options: booleanAns, textarea: true }
    ],
    locationInformationStructure,
    additionServicesStructure
  },

  {
    id: 15,
    name: 'Pest Control',
    label: 'Pest Control',
    para: 'Pest problem? No problem! Hire licensed pest control for pest-free space.',
    image: require('../assets/images_/lawn1.png').default,
    subcategory: [
      { id: 0, value: 'Residential', para: 'Get pest control service and remove the pesky pests from home.' },
      { id: 1, value: 'Commercial', para: 'Regular pest servicing maintains a clean environment. Hire commercial pest control today.' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1] },
      { id: 'pest_type', label: 'Type of Pest Control', type: 'select', options: pestcontrolType, subCatId: [0, 1], multiple: true },
      { id: 'residence', label: 'Type of Residence', type: 'select', options: pestresidentialType, subCatId: [0] },
      { id: 'commercial', label: 'Type of Commericial', type: 'select', options: pestCommercial, subCatId: [1] },
      { id: 'number_of_bedroom', label: 'Number of Bedroom', type: 'select', options: pestnumberOfBedroom, subCatId: [0] },
      { id: 'service_type', label: 'Service For', type: 'select', options: pestServiceType, subCatId: [0, 1] },
      { id: 'expected_square_feet', label: 'Total sq ft for floor', type: 'input', mode: 'number', subCatId: [0, 1] },
      { id: 'number_of_stories_covered', label: 'Number of stories to be covered', type: 'select', options: oneToTen, subCatId: [0, 1] },
      // {id:'number_of_unit',label:'Number of Unit', type:'select', options:oneToTen,subCatId:[0,1]},
      { id: 'product_kidfriendly', label: 'Kid and Pet Safe Product Preferred When Possible', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 1] },
      // {id:'product_kids_save',label:'Kid and Pet Safe Product Preferred When Possible', type:'select', options:booleanAns,mode:'radio',subCatId:[1]},
      { id: 'parking', label: 'Do you want Pest Control for parking area as well', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 1] },
      { id: 'parking_area_insqft', label: 'What is the Parking Area sq ft', type: 'input', mode: 'number', subCatId: [0, 1] },
      { id: 'service_frequency', label: 'Service Frequency', type: 'select', options: pestserviceFrequency, subCatId: [0, 1] },
      { id: 'warranty', label: 'One Time: Warranty duration (months)', type: 'select', options: oneToTen, subCatId: [0, 1] },
      { id: 'receive_reservice', label: 'When in contract with Pest Control, would you like to receive reservice if infestation comes back', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 1] },
      { id: 'product_preference', label: 'Type of Product Preference', type: 'select', options: pestproductPreference, subCatId: [0, 1] },
      { id: 'furniture_moved', label: 'If needed, furniture will be moved and replaced by', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0] },
      { id: 'kitchen_moved', label: 'If required, item removal from kitchen cabinets provided by', type: 'select', options: operationType, subCatId: [0] },
      { id: 'boxes_moved', label: 'Boxes for kitchen cabinet items placement provided by', type: 'select', options: operationType, subCatId: [0] },
      { id: 'items_moved', label: 'Items back to kitchen shelf', type: 'select', options: operationType, subCatId: [0] },
      { id: 'bathroom_moved', label: 'If required, item removal from  Bathroom cabinets provided by', type: 'select', options: operationType, subCatId: [0] },
      { id: 'boxes_bathroom_moved', label: 'Boxes for bathroom cabinet items placement provided by', type: 'select', options: operationType, subCatId: [0] },
      { id: 'boxes_bathroom_moved_back', label: 'Items back to bathroom cabinet', type: 'select', options: operationType, subCatId: [0] },
      { id: 'number_of_bathrooms', label: 'Number of Bathrooms', type: 'select', options: oneToTen, subCatId: [0] },
      { id: 'drawers_moved', label: 'If required, item removal from drawers, shelf & armoire provided by', type: 'select', options: operationType, subCatId: [0] },
      { id: 'boxes_drawers_moved', label: 'Boxes for drawers, shelf & armoire  items placement provided by', type: 'select', options: operationType, subCatId: [0] },
      { id: 'boxes_drawers_moved_back', label: 'Items back to drawers, shelf & armoire', type: 'select', options: operationType, subCatId: [0] },
      { id: 'number_of_furniture', label: 'Number of Furniture', type: 'select', options: oneToTen, subCatId: [0] },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 16,
    name: 'Lawn Care',
    label: 'Lawn Care',
    para: 'Lawns are the first impresssion of your home. Keep is beautified by hiring lawn services.',
    image: require('../assets/images_/lawn1.png').default,
    subcategory: [
      { id: 0, value: 'Mowing Only' },
      { id: 1, value: 'Lawn Seeding' },
      { id: 2, value: 'Lawn Seeding and Mowing' },
      { id: 3, value: 'Sod' },
      { id: 4, value: 'Fall or Spring clean up' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2, 3, 4] },
      { id: 'property_type', label: 'Property Type', type: 'select', options: propertyType, subCatId: [0, 1, 2, 3, 4] },
      { id: 'lawn_care_yards', label: 'Lawn Care Yards', type: 'select', options: lawnCareYards, subCatId: [0, 1, 2, 3, 4] },
      { id: 'total_sf', label: 'Total SF', type: 'input', mode: 'number', subCatId: [0, 1, 2, 3, 4] },
      { id: 'lawn_area_layer', label: 'Lawn Area Layer', type: 'select', options: lawnAreLayer, subCatId: [0, 1, 2, 3, 4] },
      { id: 'lawn_mowing_frequency', label: 'Lawn mowing frequency', type: 'select', options: lawnMovingFrequency, subCatId: [0, 2] },
      { id: 'soon_need_service', label: 'How soon do you need lawn service', type: 'select', options: lawnserviceRequire, subCatId: [0, 1, 2, 3, 4] },
      { id: 'need_extensive_cleaning', label: 'Does your lawn need extensive Fall or Spring cleaning', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 2] },
      { id: 'lawn_seeding_type', label: 'Lawn Seeding type', type: 'select', options: lawnseedingType, subCatId: [1, 2] },
      { id: 'lawn_grass_type', label: 'Grass Type', type: 'select', options: lawngrassType, subCatId: [1, 2, 3] },
      { id: 'lawn_seed_type', label: 'Grass Seed Type', type: 'select', options: lawntypeofSeed, subCatId: [1, 2, 3] },
      { id: 'yard_sod', label: 'Does the yards need to be graded before sod is placed', type: 'select', options: [...booleanAns, 'I am not sure'], subCatId: [3] },
      { id: 'current_yard_surface', label: 'What is current yards surface', type: 'select', options: lawncurrentSurface, subCatId: [3, 4], multiple: true },
      { id: 'paint_details', label: 'Please specify recommended Paint details', type: 'input', subCatId: [0, 2] },
      { id: 'expected_size_square_feet', label: 'Expected size of Deck(square Feet)', mode: 'number', type: 'input', subCatId: [0, 2] },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 17,
    name: 'Deck',
    label: 'Deck',
    textual: 'Deck Materials',
    image: require('../assets/images_/deck1.png').default,
    subcategory: [
      { id: 0, value: 'Install new Deck' },
      { id: 1, value: 'Repair/Replace Existing Deck' },
      { id: 2, value: 'Deck Paint' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2] },
      { id: 'property_type', label: 'Property Type', type: 'select', options: propertyType, subCatId: [0, 1, 2] },
      { id: 'deck_issues', label: 'Type of Issues', type: 'select', options: deckIssue, subCatId: [1] },
      { id: 'deck_job_type', label: 'Job Type', type: 'select', options: deckJobType, subCatId: [2] },
      { id: 'materials', label: 'Deck Materials', type: 'select', options: deckMaterials, subCatId: [0, 1, 2] },
      { id: 'deck_placemnet', label: 'Deck placement', type: 'select', options: deckPlacement, subCatId: [0, 2] },
      { id: 'apply_sealer', label: 'Do you want to Apply sealer', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 2] },
      { id: 'duck_paint', label: 'Do you want to paint Deck', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0, 2] },
      { id: 'paint_details', label: 'Please specify recommended Paint details', type: 'input', subCatId: [0, 2] },
      { id: 'expected_size_square_feet', label: 'Expected size of Deck(square Feet)', type: 'input', mode: 'number', subCatId: [0, 1, 2] },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 18,
    name: 'Flooring',
    label: 'Flooring',
    image: require('../assets/images_/floor1.png').default,
    subcategory: [
      { id: 0, value: 'Tiles' },
      { id: 1, value: 'Laminate/Wood/LVP and more' },
      { id: 2, value: 'Carpet' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1, 2] },
      { id: 'property_type', label: 'Property Type', type: 'select', options: propertyType, subCatId: [0, 1, 2] },
      { id: 'tiles_material', label: 'Tiles Materials', type: 'select', options: floorTilesMaterial, subCatId: [0] },
      { id: 'tiles_installation', label: 'Tiles installation place', type: 'select', options: floorTilesInstallation, subCatId: [0] },
      { id: 'expected_square_feet', label: 'Expected square Feet for complete work', type: 'input', mode: 'number', subCatId: [0, 1, 2] },
      { id: 'service_type', label: 'Service Type', type: 'select', options: floorTilesServiceType, subCatId: [0] },
      { id: 'service_type', label: 'Service Type', type: 'select', options: flooringServiceType, subCatId: [1] },
      { id: 'service_type', label: 'Service Type', type: 'select', options: floorcarpetServiceType, subCatId: [2] },
      { id: 'improve_tiles_place', label: 'Do you need to prepare tile place', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0] },
      { id: 'floor_material', label: 'Floor Materials', type: 'select', options: flooringMaterials, subCatId: [1] },
      { id: 'floor_underlayment', label: 'Do you need flooring underlayment', type: 'select', options: booleanAns, mode: 'radio', subCatId: [1] },
      { id: 'floor_installation', label: 'Floor installation place', type: 'select', options: flooringInstallation, subCatId: [1, 2] },
      { id: 'carpet_material', label: 'Carpet Materials', type: 'select', options: floorcarpetMaterial, subCatId: [2] },
      { id: 'carpet_style', label: 'Carpet Styles', type: 'select', options: floorcarpetStyles, subCatId: [2] },
      { id: 'carpet_padding', label: 'Do you need Carpet Padding', type: 'select', options: booleanAns, mode: 'radio', subCatId: [2] },
      { id: 'carpet_type', label: 'Carpet Type', type: 'select', options: floorcarpetPadding, subCatId: [2] },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 19,
    name: 'Car Mechanic',
    label: 'Car Mechanic',
    image: require('../assets/images_/mechanic1.png').default,
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0] },
      { id: 'car_type', label: 'Car Type', type: 'input', subCatId: [0] },
      { id: 'meter_model', label: 'Car Model', type: 'input', subCatId: [0] },
      { id: 'car_year', label: 'Car Year', type: 'input', subCatId: [0] },
      { id: 'trim', label: 'Car Trim', type: 'input', subCatId: [0] },
      { id: 'car_start', label: 'Does your car start', type: 'select', options: booleanAns, mode: 'radio', subCatId: [0] },
      { id: 'garage_location', label: 'Where is your car located', type: 'select', options: carlocatedgarage, subCatId: [0] },
      { id: 'service_type', label: 'Service Type', type: 'select', options: carmechanicServiceType, subCatId: [0] },
      { id: 'service_engine', label: 'Engine', type: 'select', options: carmechanicEngine, subCatId: [0] },
      { id: 'service_ac', label: 'AC', type: 'select', options: carmechanicAC, subCatId: [0] },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 20,
    name: 'Pressure Washer',
    label: 'Pressure Washer',
    image: require('../assets/images_/pressure1.png').default,
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1] },
      { id: 'property_type', label: 'Property Type', type: 'select', options: propertyType, subCatId: [0, 1] },
      { id: 'power_washing_area', label: 'Power Washing Area', type: 'select', options: powerWashingArea, subCatId: [0] },
      { id: 'expected_square_feet', label: 'Expected square Feet for complete work', type: 'input', subCatId: [0], mode: 'number' },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 21,
    name: 'Roof',
    label: 'Roof',
    image: require('../assets/images_/roof1.png').default,
    subcategory: [
      { id: 0, value: 'Roof Replacement' },
      { id: 1, value: 'Repair' },
    ],
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0, 1] },
      { id: 'property_type', label: 'Property Type', type: 'select', options: propertyType, subCatId: [0, 1] },
      { id: 'roof_material', label: 'Roof Material', type: 'select', options: roofMaterialType, subCatId: [0, 1] },
      { id: 'house_square_feet', label: 'Roof square Feet', type: 'input', mode: 'number', subCatId: [0, 1] },
      { id: 'building_stories', label: 'Building Stories', type: 'select', options: buildingStories, subCatId: [0, 1] },
      { id: 'service_type', label: 'Type of Service', type: 'select', options: roofServiceType, subCatId: [0] },
      { id: 'service_type', label: 'Type of Service', type: 'select', options: roofRepairServiceType, subCatId: [1] },
    ],
    locationInformationStructure,
    additionServicesStructure
  },
  {
    id: 22,
    name: 'Gutter Cleaner',
    label: 'Gutter Cleaner',
    image: require('../assets/images_/gutter1.png').default,
    formStructure: [
      { id: 'heading', label: 'Job Title', type: 'input', subCatId: [0] },
      { id: 'property_type', label: 'Property Type', type: 'select', options: propertyType, subCatId: [0] },
      { id: 'location_type', label: 'Type of Location', type: 'select', options: [...typeOfLocation, 'Restaurant'], subCatId: [0] },
      { id: 'building_stories', label: 'Building Stories', type: 'select', options: buildingStories, subCatId: [0] },
      { id: 'service_type', label: 'Type of Service', type: 'select', options: gutterserviceType, subCatId: [0] },
    ],
    locationInformationStructure,
    additionServicesStructure
  },

]

const feedbackOptions = [
  { id: 0, name: 'Excellent (5 Stars)', value: 'Excellent' },
  { id: 1, name: 'Good (4 Stars)', value: 'Good' },
  { id: 2, name: 'Fair (3 Stars)', value: 'Fair' },
  { id: 3, name: 'Poor (2 Stars)', value: 'Poor' },
  { id: 4, name: 'Very Poor (1 Star)', value: 'Very Poor' },
];



export const faqs = [
  {
    question: "What is Bijlink?",
    answer: "Bijlink is a platform that connects customers with drivers for shipping and transportation needs. It offers an online marketplace to find reliable drivers for various types of shipments."
  },
  {
    question: "How do I use Bijlink to find a driver?",
    answer: "Using Bijlink is easy. Simply create an account, post your shipment details, and drivers in your area will bid on your job. You can choose the driver that best fits your needs."
  },
  {
    question: "Is Bijlink available nationwide?",
    answer: "Yes, Bijlink operates throughout the United States, so you can find drivers for your shipments in most locations."
  },
  {
    question: "What types of shipments can I find drivers for on Bijlink?",
    answer: "Bijlink caters to a wide range of shipments, from small packages to large freight. You can find drivers for local deliveries, long-distance hauls, and even specialized transport needs."
  },
  {
    question: "How do I pay for the services on Bijlink?",
    answer: "You can pay for the services securely through the platform. Payments are processed online, making the process convenient for both customers and drivers."
  },
  {
    question: "Are the drivers on Bijlink vetted for reliability and safety?",
    answer: "Yes, Bijlink takes safety seriously. Drivers are required to undergo a verification process, and customer reviews help ensure the reliability and quality of service."
  },
  {
    question: "Can I track my shipment in real-time?",
    answer: "Absolutely. Bijlink provides real-time tracking, so you can monitor the progress of your shipment from start to finish."
  },
  {
    question: "What if I have a problem with a driver or shipment?",
    answer: "Bijlink has a customer support team available to assist you with any issues or concerns. You can reach out for help with any aspect of your shipment."
  },
  {
    question: "Is my personal information secure on Bijlink?",
    answer: "Yes, your privacy is a priority. Bijlink employs robust security measures to protect your personal information and payment details."
  },
  {
    question: "How do I get started on Bijlink as a driver?",
    answer: "If you're a driver looking to join Bijlink, you can sign up, provide your credentials, and start bidding on shipments in your area. It's a great platform to grow your transportation business."
  }
];
export {
  categories, subcategory, acSize, firebaseConfig, serviceObj,
  airConditionType,
  typeOfLocation,
  buildingStories,
  installationLocation,
  unitToInstalled,
  gasType,
  booleanAns,
  acformStructure,
  photographyServices,
  photographyExpertise,
  photosProvidedC1,
  photosProvidedC3,
  photosProvidedC2,
  printedPhoto,
  printedPhotoType,
  durationCinemaAndDrone,
  photographyTypeServices,
  oneToTen,
  operationType,
  carTruckTypes,
  motorCycleTypes,
  otherVehicles,
  carHauler,
  carCondition,
  handlingUnit,
  businessType,
  movingAreaRange,
  officeCarType,
  officeShipType,
  officeShipHauler,
  constructionTypeOfPlaces,
  constructionTypesOfItems,
  constructionTypeOfService,
  driedFood,
  vegeFood,
  feedbackOptions,
  fruitFood,
  timeFrameObj,
  constructionTypeOfGoods,
  pulocationType,
  propertyType,
  diningArr,
  livingRoomArr,
  bedroomArr,
  kitchenArr,
  homeOfficeArr,
  garageArr,
  outdoorArr,
  misArr,
  boxesArr,
  InformationObj,
  googleAPIKey,
  totalUnitsToInstallType,
  imageURL,
  shippingLocationInfo,
  catLocationInfo,
  weddingPartyType,
  matchType,
  unallowedChar,
  packingSupplies,
  timeFrame,
  pickupLocationAdditionalInfo,
  S3BucketInfo
};