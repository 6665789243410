import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap'
import '../css/sass/invoices.css';
import '../css/style.css';
import '../css/sass/main.css';
import { Checkbox, CircularProgress, Rating } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import { Footer, Header, Spinner } from '../components';
import { connect } from 'react-redux';
import { _call } from '../utils/auth';
import { crypt, decrypt, handleDays } from '../utils/function';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Buyersidebar from '../components/buyersidebar';
import _ from 'lodash'

import { CSVLink } from "react-csv";



const keyId = process.env.REACT_APP_SECRET_KEY;


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: '800px', // Adjust the width as needed for the default (mobile) view
  '@media (max-width: 600px)': {
    width: '100%',
    top: '50%',
    left: '50%',
  },
};


const headers = [
  { label: "Title", key: "lead.title" },
  { label: "Category", key: "lead.category" },
  { label: "Sub Category", key: "lead.sub_category" },
  { label: "Price", key: "amountInfo.totalamount" },
  { label: "Currency", key: "amountInfo.currency" },
  { label: "Service Charge", key: "inset.serviceCharge" },
  { label: "State Charge", key: "inset.stateCharge" },
];


function mapStateToProps(state) {
  return {
    userInfo: state
  };
}

const InvoiceComponent = React.forwardRef((props, ref) => {
  const { selectedItem, subscriptionplan } = props;
  const [dataset, setdataset] = useState([]);
  const [usersInfo, setusersInfo] = useState([]);

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    try {

      const getLead = await _call(`get-lead/${crypt(process.env.REACT_APP_SECRET_KEY, selectedItem?.lead?.leadId.toString())}`);
      setdataset(getLead.data);

      const contractUsers = await _call(`get-lead-users/`, 'POST', {
        buyer: crypt(process.env.REACT_APP_SECRET_KEY, getLead.data?.userId.toString()),
        seller: "none",
        leadId: crypt(process.env.REACT_APP_SECRET_KEY, selectedItem?.lead?.leadId.toString())
      })
      setusersInfo(contractUsers.data)

    } catch (err) {

    }
  }


  return (
    <div ref={ref}>

      <div className='bg-black w-10 h-10 justify-center items-center inline-flex'>
        <img src={require('../assets/icons/logo.png').default} style={{ width: 25 }} />
      </div>
      <div className='grid grid-cols-2 gap-3 border p-2 mt-3'>
        <div className=''>
          <h4 className='font-bold text-xs lg:text-sm' style={{ fontSize: 10 }}>Title</h4>
          <h4 className='mt-1 text-xs lg:text-sm'>{dataset?.title}</h4>
        </div>
        <div className=''>
          <h4 className='font-bold text-xs lg:text-sm' style={{ fontSize: 10 }}>Order No.</h4>
          <h4 className='mt-1 text-xs lg:text-sm'>{dataset?.order_number}</h4>
        </div>
      </div>
      <div className='grid grid-cols-2 gap-3 border p-2 mt-2'>
        <div className=''>
          <h4 className='font-bold text-xs lg:text-sm' style={{ fontSize: 10 }}>Picked Up Address</h4>
          <h4 className='mt-1 text-xs lg:text-sm'>{dataset?.pickup_location}</h4>
        </div>

        <div className=''>
          <h4 className='font-bold text-xs lg:text-sm' style={{ fontSize: 10 }}>Destination Address</h4>
          <h4 className='mt-1 text-xs lg:text-xs'>{dataset?.destination_location}</h4>
        </div>
      </div>
      <div className='grid grid-cols-3 gap-3 border p-2 mt-2'>
        <div className=''>
          <h4 className='font-bold lg:text-sm' style={{ fontSize: 10 }}>Customer Name</h4>
          <h4 className='mt-1 text-xs'>{usersInfo?.buyerInfo?.name}</h4>
        </div>
        <div className='col-span-2'>
          <h4 className='font-bold text-xs lg:text-sm' style={{ fontSize: 10 }}>Email & Phone</h4>
          <h4 className='mt-1 text-xs'>{usersInfo?.buyerInfo?.email} / {usersInfo?.buyerInfo?.phone}</h4>
        </div>
      </div>
      <div className='grid grid-cols-3 gap-3 border p-2 mt-2 mb-2'>
        <div className=''>
          <h4 className='font-bold lg:text-sm' style={{ fontSize: 10 }}>Contractor Name</h4>
          <h4 className='mt-1 text-xs'>{usersInfo?.sellerInfo?.name}</h4>
        </div>
        <div className='col-span-2'>
          <h4 className='font-bold text-xs lg:text-sm' style={{ fontSize: 10 }}>Email & Phone</h4>
          <h4 className='mt-1 text-xs'>{usersInfo?.sellerInfo?.email} / {usersInfo?.sellerInfo?.phone}</h4>
        </div>
        {/* <div className=''>
          <h4 className='font-bold text-xs lg:text-sm'>Contractor Phone</h4>
          <h4 className='mt-2 text-sm'>{usersInfo?.sellerInfo?.phone}</h4>
        </div> */}
      </div>



      {/* <h4 className='font-bold text-md mb-2 lg:text-lg mt-3'>{selectedItem?.amountInfo?.title == "Tip" ? "Tip" : "Contract"} Information</h4> */}
      {selectedItem?.amountInfo?.title != "Tip" &&
        <>
          <div className='grid grid-cols-2 w-100 gap-10 border p-1'>
            <td className='p-2 text-xs lg:text-sm'>Lead Amount</td>
            <td className='p-2 text-xs lg:text-sm'>${(selectedItem?.amountInfo?.totalamount - (selectedItem?.inset?.serviceCharge + selectedItem?.inset?.stateCharge + selectedItem?.inset?.insuranceCharge)).toFixed(2) == 'NaN' ? 0 :
              _.round(selectedItem?.amountInfo?.totalamount - (selectedItem?.inset?.serviceCharge + selectedItem?.inset?.stateCharge + selectedItem?.inset?.insuranceCharge), 2) || 0}</td>
          </div>

          <div className='grid grid-cols-2 w-100 gap-10 border p-1'>
            <td className='p-2 text-xs lg:text-sm'>Service Charge</td>
            <td className='p-2 text-xs lg:text-sm'>${_.round(selectedItem?.inset?.serviceCharge, 2) || 0}</td>
          </div>

          <div className='grid grid-cols-2 w-100 gap-10 border p-1'>
            <td className='p-2 text-xs lg:text-sm'>State Charge</td>
            <td className='p-2 text-xs lg:text-sm'>${_.round(selectedItem?.inset?.stateCharge, 2) || 0}</td>
          </div>
        </>
      }
      <div className='grid grid-cols-2 w-100 gap-10 p-1'>
        <td className='p-2 text-xs lg:text-sm'>Total Amount</td>
        <td className='p-2 text-xs lg:text-sm'>${_.round(selectedItem?.amountInfo?.totalamount, 2)}</td>
      </div>

      <div className='text-right mt-4'>
        <a className='text-black text-right text-sm mt-2' target="__blank" href={selectedItem?.amountInfo?.receipt}>Download Stripe Invoice</a>
      </div>

    </div>
  );
});


const Invoice = (props) => {
  const [mode, setmode] = useState(1)
  const [wFilter, setwFilter] = useState(4);
  const [dataset, setdataset] = useState([]);
  const [subscriptionplan, setsubscriptionplan] = useState([]);
  const [loader, setloader] = useState(true);
  const [selectedItem, setselectedItem] = useState([]);
  const [open, setOpen] = React.useState(false);
  const componentRef = useRef();
  const handleClose = () => setOpen(false);
  const { userInfo } = props;

  const handleData = async () => {
    setloader(true);
    try {
      const getUsage = await _call(`account-history`, 'POST', {
        pageSize: 1,
        userId: crypt(keyId, JSON.stringify(userInfo.userId)),
        filter: wFilter
      });

      const data = getUsage.data;
      if (!_.isEmpty(data)) {
        setdataset(data)
      }
      setloader(false);
    } catch (err) {

      setloader(false);
    }
  }

  const handleChange = (item) => {

  }

  useEffect(() => {
    setdataset([]);
  }, [wFilter]);

  useEffect(() => {
    if (_.isEmpty(dataset)) {
      handleData();
    }
  }, [dataset]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleRoute = (route) => {
    const { history } = props;
    history.push(route);
  }



  const handlePageChange = async (id) => {
    const leadInfo = await _call('getsinglelead', 'POST', {
      leadId: parseInt(id)
    });

    const serviceId = decrypt(keyId, leadInfo.data.sId);
    const locationid = decrypt(keyId, leadInfo.data.lId);

    // props.history.push({
    //   pathname: '/app/leadviewsection',
    //   state: {
    //     serviceId: serviceId,
    //     locationId: locationid,
    //     categoryName: leadInfo.data.category,
    //     navigationflow: {
    //       screens: [
    //         { routeName: 'My Lead', route: '/buyer-lead' },
    //       ]
    //     }
    //   }
    // })
  }


  const ReturnInvoiceStatus = (item) => {
    if (item.activityInfo.disputed) {
      return 'Disputed'
    }
    if (item.activityInfo?.paid) {
      return 'Paid'
    }

    if (item.activityInfo.refunded) {
      return 'Refunded'
    }

    if (item.amountInfo?.title == "Tip") {
      return "Tip"
    }


    return 'Paid'
  }


  // if (loader) return <Spinner />
  return (

    <div id="wrapper" className="bgiparent landing ">
      <Header
        history={props.history}
      />
      <div className="clearfix" />
      <div className="row">
        <div className="col-lg-12">
          <div className="">

            <div className="dashboard-container">

              <Buyersidebar {...props} />

              <div className="flex flex-1 row pl-4 bg-slate-50 border-l" data-simplebar>

                <div className="row mt-4 w-100">
                  <div className="col-lg-12">
                    <h2 className="bold text-left ml-3">Invoice</h2>
                    <h4 className='mt-1 text-left ml-3'>Complete the form</h4>

                    <hr />
                    <div style={{ minHeight: 700 }}>

                      <div className="container-fluid p-0 mt-3">
                        <div className='grid grid-cols-2 gap-x-4'>
                          <div className='bg-yellow-200 h-[60px] w-[200px] items-center flex justify-center rounded-lg'>
                            {loader ? <CircularProgress size={14} /> : <CSVLink data={dataset}
                              filename={"my-account.csv"}
                              className='font-bold text-black'
                              headers={headers}>
                              Download CSV
                            </CSVLink>}

                          </div>
                          <select className=" input-style"
                            onChange={e => setwFilter(e.target.value)}
                          >
                            <option value={4}>All</option>
                            <option value={1}>Weekly</option>
                            <option value={2}>Biweekly</option>
                            <option value={3}>Monthly</option>

                          </select>
                        </div>

                        {loader && <div className='loaderContainer' style={{ alignItems: 'center', height: '100vh', justifyContent: 'center' }}><CircularProgress color="inherit" /></div>}
                        <table width="100%" className="mt-4" cellPadding="12" >
                          <tr style={{ background: '#0e415f' }}>

                            <th style={{ color: '#fff', fontSize: 16 }}>
                              <Checkbox />
                            </th>
                            <th style={{ color: '#fff', fontSize: 16 }}>Title</th>
                            <th style={{ color: '#fff', fontSize: 16 }}> Category - Sub Category</th>
                            <th style={{ color: '#fff', fontSize: 16 }}>Transaction Invoice</th>
                            <th style={{ color: '#fff', fontSize: 16 }}>Amount</th>
                            <th style={{ color: '#fff', fontSize: 16 }}>Status</th>
                            <th style={{ color: '#fff', fontSize: 16, minWidth: 100 }}>Created on</th>
                          </tr>
                          {dataset.map((item, index) => {
                            return (
                              <tr
                                style={{ border: '1px solid #efefef', fontSize: 16, cursor: 'pointer' }}>
                                <td><Checkbox value={item.state} onChange={() => handleChange(item)} /></td>
                                <td
                                  onClick={() => handlePageChange(item?.lead?.leadId)}
                                >{item.lead.title}</td>
                                <td>{item.lead.category?.replace('_', ' ')} {item.lead.subcategory}</td>
                                <td onClick={() => {
                                  setselectedItem(item);
                                  setOpen(true);
                                }}>View Invoice</td>
                                <td>{item?.amountInfo?.totalamount} USD</td>
                                <td>{ReturnInvoiceStatus(item)}</td>
                                <td>{handleDays(item?.amountInfo?.created_on)}</td>
                              </tr>
                            );
                          })}


                        </table>

                      </div>

                    </div>
                  </div>
                </div>


              </div>

            </div>

          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button className='icon-line-awesome-close modal-cross-btn'
            style={{
              position: 'absolute',
              top: 30,
              right: 30,
              background: '#3b73a9',
              padding: 5,
              borderRadius: 100,
              color: '#fff'
            }}

            onClick={handleClose}
          >
          </button>
          <InvoiceComponent selectedItem={selectedItem} subscriptionplan={subscriptionplan} ref={componentRef} />
          <button className="button full-width button-sliding-icon ripple-effect margin-top-20 mb-2"
            style={{ backgroundColor: '#f7f7f7', color: '#646464', width: 120, float: 'right' }}
            onClick={handlePrint}
            type="submit" form="login-form">Print<i className="icon-material-outline-arrow-right-alt" />
          </button>
          <button className="button full-width button-sliding-icon ripple-effect margin-top-20 mb-2"
            onClick={handleClose}
            style={{ backgroundColor: '#646464', color: '#f7f7f7', width: 120, marginRight: 10, float: 'right' }}
            type="submit" form="login-form">Close<i className="icon-material-outline-arrow-right-alt" />
          </button>

        </Box>
      </Modal>
      <div className='footer'><Footer {...props} /></div>
    </div>
  );
}




export default connect(
  mapStateToProps,
  null,
)(Invoice);