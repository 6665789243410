import React from 'react';


const ListItemHead = (...props) => {
    return (
        <div className='row lead-table w-[99%] m-0 mt-4 rounded-md'
            style={{
                height: 60,
                alignItems: 'center',
                border: '1px solid #efefef',

                background: '#0e415f'
            }}
        >
            <div className='col-2 '>
                <h4 className='bold fs_sm_head' style={{ letterSpacing: 1, color: "#fff", }}>Title</h4>
            </div>
            <div className='col-2'>
                <h4 className='bold fs_sm_head' style={{ letterSpacing: 1, color: "#fff", }}>Category</h4>
            </div>
            <div className='col-4'>
                <h4 className='bold fs_sm_head' style={{ letterSpacing: 1, color: "#fff", }}>Location</h4>
            </div>
            <div className='col-2'>
                <h4 className='bold fs_sm_head' style={{ letterSpacing: 1, color: "#fff", }}>Pricing</h4>
            </div>
            <div className='col-2'>
                <h4 className='bold fs_sm_head' style={{ letterSpacing: 1, color: "#fff", }}>Details</h4>
            </div>
        </div>
    );
}

export default ListItemHead;