import React, { useEffect, useState } from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap'
import '../css/style.css';
import '../css/sass/main.css';
import '../css/colors/blue.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { imageURL } from '../utils/constant';
import { connect, useSelector } from 'react-redux';
import { Link } from '@mui/material';
import { useHistory } from 'react-router-dom';


function mapStateToProps(state) {
  return {
    userInfo: state
  };
}



const BuyerSection = [
  { id: 0, name: 'Dashboard', route: '/app/dashboard', current: true, visible: true },
  { id: 1, name: 'Create Listing', route: '/app/category', current: false, visible: true },
  { id: 2, name: 'Inbox', route: '/app/inbox', current: false, visible: true },
  { id: 3, name: 'Profile', route: '/app/profile', current: false, visible: true },
  { id: 4, name: 'My Listing', route: '/app/buyer-lead', current: false, visible: true },
  { id: 5, name: 'Settings', route: '/app/setting', current: false, visible: true },
  { id: 6, name: 'View Invoice', route: '/app/account-info', current: false, visible: true },
];

const BuyerSidebar = (props) => {
  const { activeId = 0 } = props;
  const [activated, setactivated] = useState(activeId);
  const userInfo = useSelector(state => state);
  const history = useHistory();

  useEffect(() => {
    setactivated(activeId);
  }, [activeId])

  const handleRoute = (route) => {
    const { history } = props;
    history.push(route);
  }

  const handleLogged = (route = '/login') => {
    // if(token){

    //   props.history.push('/login')
    //   window.location.reload(false);
    // }else{
    //   props.history.push('/login')
    // }
    localStorage.removeItem('token');
    localStorage.removeItem('uid');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    localStorage.removeItem('state');
    localStorage.removeItem('role');

    props.history.push('/login')
    window.location.reload(true);
  }

  return (
    <div className='bg-white p-2 shadow-lg d-none d-sm-none d-xs-none d-xl-block d-md-block d-xxl-block min-h-screen' style={{ flex: '0 0 300px', minHeight: '100vh', }}>
      <div className='bg-yellow-500 shadow-xl p-4 ml-2 mt-4 mr-6 rounded text-center items-center flex flex-row items-center'>
        <img src={imageURL + userInfo?.profile_img} className='w-10 h-10 rounded-full' />
        <h4 className='text-white text-left ml-3'>{userInfo?.first_name} {userInfo?.last_name}</h4>
      </div>
      <div className='mt-4'>
        {BuyerSection?.map(item => <h4 onClick={() => history.push(item.route)} className='bg-slate-700 cursor-pointer text-sm text-white p-3 ml-2 mt-2 mr-6  rounded-md'>

          {item.name}</h4>)}
      </div>
    </div>

  );
}



export default connect(
  mapStateToProps,
  null,
)(BuyerSidebar);



