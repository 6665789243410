import React, { useEffect, useState } from 'react';
import { getApps, initializeApp } from 'firebase/app';
import * as firebase from 'firebase/app';
import { getAuth, linkWithCredential, EmailAuthProvider, RecaptchaVerifier, PhoneAuthProvider, reauthenticateWithCredential, updatePhoneNumber, signInWithPhoneNumber, updateEmail, updatePassword } from "firebase/auth";
import '../../css/style.css';
import '../../css/sass/main.css';
import '../../css/colors/blue.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { Formik } from 'formik'
import Button from '@mui/material/Button';
import { RModal, Spinner } from '../../components';
import { Checkbox, CircularProgress } from '@mui/material';
import { leadregisterValidationSchema, registerValidationSchema } from '../../utils/validition';
import { firebaseConfig, unallowedChar } from '../../utils/constant';
import { _call } from '../../utils/auth';
import { crypt, genPass } from '../../utils/function'
import { connect } from 'react-redux';
import _ from 'lodash';
import { ADD_USER, REMOVE_USER } from '../../redux/action';


const RegisterView = (props) => {
  const [mode, setmode] = useState(0);
  const [checked, setchecked] = useState(false);
  const [loader, setloader] = useState(false);
  const [otpState, setotpState] = useState(false);
  const [userData, setuserData] = useState({});
  const [confirmationObj, setconfirmationObj] = useState();
  const [alertObj, setalertObj] = useState({ state: false, message: '', title: '' });
  const [passwordgenerator, setpasswordgenerator] = useState(genPass())
  const [recaptchaverifier, setrecaptchaverifier] = useState();
  const [otp, setotp] = useState('');
  const [currentUserS, setcurrentUserS] = useState([]);
  const { AddUser } = props;

  if (getApps().length == 0) {
    const app = initializeApp(firebaseConfig);
  }

  const auth = getAuth();


  const handleRegister = async (values) => {

    const validateUser = await _call('/validate-user', 'POST', { email: values.email, phone: `+1${values.phone}` });
    const validateResponse = validateUser.data;

    if (!validateResponse.userExistance) {
      setloader(true)
      setuserData(values);
      try {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', { size: "invisible" }, auth);
        signInWithPhoneNumber(auth, '+1' + values.phone, window.recaptchaVerifier).then((confirmationResult) => {
          setconfirmationObj(confirmationResult);
          setloader(false);
        }).catch(err => {
        });
        setTimeout(() => { }, 1000);
        setloader(false);
        setotpState(true);
      } catch (error) {
      }
    } else {
      setloader(false);
      setalertObj({
        state: true,
        title: 'User Already Exist!',
        message: 'Please use different email or phone number',
        type: 2,
        movestate: false
      })
    }
  }


  const handleConfirmOTP = async () => {

    // const credential = EmailAuthProvider.credential(userData.email, userData.pass);
    if (!_.isEmpty(confirmationObj)) {
      setloader(true)
      confirmationObj.confirm(otp).then(async (result) => {
        const userD = result.user;
        setloader(false);
        const guid = userD.uid;
        setcurrentUserS(userD);
        handleRegisterUserInDB(guid, userD);

      }).catch((error) => {
        setloader(false);
        setalertObj({
          state: true,
          title: error?.message?.includes('auth/invalid-verification-code') ? 'Invalid OTP!' : 'Expired OTP',
          message: 'Some problem occured. Try again later.',
          type: 2,
          movestate: false
        })
      });
    }

  }

  const handleRegisterUserInDB = async (guid, user) => {

    const { email, fname, lname, phone, accountType, company_name } = userData;

    const dataset = {
      user_type: 'Buyer',
      company_name: company_name,
      user_level: (accountType),
      email: email,
      first_name: fname,
      guid: guid,
      last_name: lname,
      phone: '+1' + phone,
    }


    try {
      setloader(true)
      const addUser = await _call('registerUser', 'PUT', dataset);
      const updateUser = await handleOnMove(user);
      localStorage.setItem('email', crypt(process.env.REACT_APP_SECRET_KEY, email))
      localStorage.setItem('role', crypt(process.env.REACT_APP_SECRET_KEY, "Buyer"))
      localStorage.setItem('userId', crypt(process.env.REACT_APP_SECRET_KEY, guid))

      setloader(false);
      setalertObj({
        state: true,
        title: 'Congratulations!',
        message: 'Your account has been created successfully',
        type: 1,
        onMoveState: true
      })
      setTimeout(() => {
        props.handleMove();
      }, 3000);

    } catch (error) {
      setloader(false);
      setalertObj({
        state: true,
        title: 'Problem Occured!',
        message: 'Some problem occured. Try again later.',
        type: 2
      })
    }
  }


  const handleOnMove = async (user) => new Promise(async (resolve, reject) => {
    const setReduxState = await handleGetUserData(user);

    resolve(true);
  });



  const handleGetUserData = async (user) => new Promise(async (resolve, reject) => {
    if (user) {
      const uid = user.uid;
      try {
        const loggedInUser = await _call('login/', 'POST', { guid: uid });
        const token = loggedInUser.data;
        localStorage.setItem('token', token)
        const getData = await _call(`user/${uid}`, 'GET', {}, {
          Authorization: 'Bearer ' + token
        });
        const data = getData.data.data[0];
        AddUser(data);
        resolve(data);
      } catch (err) {
        reject(err);
      }
    } else {
      reject("Error");
    }
  });

  const handleResendToken = async () => {
    var recaptchaVerifier
    if (!recaptchaverifier) {
      recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-2', { size: "invisible" }, auth);
      setrecaptchaverifier(recaptchaVerifier);
    }


    signInWithPhoneNumber(auth, `+1${userData.phone}`, !recaptchaverifier ? recaptchaVerifier : recaptchaverifier).then((confirmationResult) => {
      setloader(false);
      setconfirmationObj(confirmationResult);
      setalertObj({
        state: true,
        title: 'Congratulations!',
        message: 'We sent the token',
        type: 1,
        movestate: false
      })
    }).catch(err => {
      setalertObj({
        state: true,
        title: 'Wrong OTP!',
        message: 'Some problem occured. Try again later.',
        type: 2,
        movestate: false
      })
    });

  }


  // if (loader) return <div className='loaderContainer' style={{ alignItems: 'center', height: '100vh', justifyContent: 'center', display: 'flex' }}><CircularProgress color="inherit" /></div>
  return (

    <div id="wrapper" className="bgiparent landing pt-0">
      <div className="clearfix" />

      <div className='row'>

        <div className='col-md-8 offset-md-2 text-center pt-2'>
          <div id="recaptcha-container"></div>
          <div id="recaptcha-container-2"></div>
          {loader &&
            <div className='mt-2'>
              <CircularProgress color="inherit" />
              <h4 className='mt-2'>Loading...</h4>
            </div>
          }
          <div style={{ pointerEvents: loader ? 'none' : 'auto' }}>
            {!otpState ?
              <div className="login-register-page mt-0 pt-0">
                <h4 className='text-xl font-bold'>Register to proceed further</h4>
                <Formik
                  validationSchema={leadregisterValidationSchema}
                  enableReinitialize
                  initialValues={{
                    fname: '',
                    lname: '',
                    email: '',
                    pass: mode == 0 ? passwordgenerator : '',
                    cpass: mode == 0 ? passwordgenerator : '',
                    phone: '',
                    accountType: '1',
                    company_name: '',
                  }}
                  onSubmit={values => handleRegister(values)}>
                  {({
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    setFieldValue
                  }) => (
                    <div className="grid lg:grid-col-2 gap-4 mt-5">
                      <div className="">

                        <input
                          className="input-style"
                          placeholder='First Name'
                          onChange={handleChange('fname')}
                        />
                        {errors.fname && touched.fname &&
                          <h6 className='validation-alert'>{errors.fname}</h6>
                        }
                        <input
                          className="input-style"
                          placeholder='Last Name'
                          onChange={handleChange('lname')}
                        />
                        {errors.lname && touched.lname &&
                          <h6 className='validation-alert'>{errors.lname}</h6>
                        }

                      </div>

                      <div className="">
                        {values.accountType === '2' && <>
                          <input
                            className="mt-2 input-style"
                            placeholder='Enter Company Name'

                            onChange={handleChange('company_name')}
                          />
                        </>}

                        <input
                          className="input-style"
                          placeholder='Email Address'

                          onChange={handleChange('email')}
                        />
                        {errors.email && touched.email &&
                          <h6 className='validation-alert'>{errors.email}</h6>
                        }

                        <div style={{ position: 'relative' }}>
                          <input
                            className="mt-2 input-style"
                            placeholder='Phone Number'
                            type="text"
                            maxLength="10"
                            style={{ height: 60, fontSize: 16, borderWidth: 1 / 2, paddingLeft: '60px !important' }}
                            // value={values.phone}
                            onKeyDown={e => unallowedChar.includes(e.key) && e.preventDefault()}
                            onChange={e => parseInt(e.target.value) && setFieldValue('phone', e.target.value)}

                          />
                          <div style={{ position: 'absolute', width: 50, height: 60, top: 0, background: '#646464' }}>
                            <p style={{ color: 'white', marginTop: 18 }}>+1</p>
                          </div>
                          {errors.phone && touched.phone &&
                            <h6 className='validation-alert'>{errors.phone}</h6>
                          }
                        </div>

                        <div className="row m-0">
                          <Checkbox
                            value={checked}
                            onChange={() => setchecked(!checked)}
                          />
                          <p style={{ marginTop: 20 }}>I accept <a href="/terms" style={{ textDecoration: 'underline' }}>Terms of Use</a> and <a href="/privacy-policy" style={{ textDecoration: 'underline' }}>Privacy Policy.</a> </p>
                        </div>

                      </div>
                      <button
                        // disabled={email === '' || password === ''}
                        disabled={!checked}
                        className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-3"
                        style={{ backgroundColor: '#1fc7c7', color: '#fff', maxWidth: '40%', margin: 'auto' }}
                        onClick={() => handleSubmit()}>Next <i className="icon-material-outline-arrow-right-alt" /></button>
                    </div>
                  )}

                </Formik>


                <h4 style={{ textAlign: 'center', cursor: 'pointer' }}
                  onClick={() => props.handleChangeView(1)}
                  className="mt-3">Already have an account? <b>Sign In</b></h4>

              </div>
              :
              <div className='mt-5 pt-5'>

                <h2 className="bold mt-5 pt-5">Validate Phone Number</h2>
                <p>Please validate your phone number.</p>
                <input
                  placeholder='Enter otp'
                  className="mt-2 input-style"
                  maxLength={6}
                  style={{ height: 60, fontSize: 16, borderWidth: 1 / 2, width: '40%', margin: 'auto   ' }}
                  value={otp}
                  onChange={e => setotp(e.target.value)}
                />
                <button
                  onClick={() => handleResendToken()}
                  style={{ marginTop: 20 }}
                >
                  Resend OTP?
                </button>

                <button
                  disabled={otp === ''}
                  onClick={() => handleConfirmOTP()}
                  className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-3"
                  style={{ backgroundColor: '#1fc7c7', color: '#fff', maxWidth: '40%', margin: 'auto' }}
                  type="submit" form="login-form">Confirm <i className="icon-material-outline-arrow-right-alt" /></button>
              </div>
            }
          </div>
        </div>

      </div>

      <div className="margin-top-70" />
      <RModal
        open={alertObj.state}
        title={alertObj.title}
        message={alertObj.message}
        type={alertObj.type}
        onClose={() => setalertObj({ ...alertObj, state: false })}
        onMoveState={alertObj.onMoveState}
        onMove={() => props.handleMove()}
      />
    </div>
  );
}


function mapDispatchToProps(dispatch) {
  return {
    AddUser: data => {
      dispatch(ADD_USER(data));
    },
    RemoveUser: () => {
      dispatch(REMOVE_USER());
    }
  };
}


export default connect(
  null,
  mapDispatchToProps,
)(RegisterView);

