import React,{useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import { InputSelector, SimpleInputSelector } from '..';
import { acSize, airConditionType, booleanAns, buildingStories, businessType, carCondition, carTruckTypes, constructionTypeOfGoods, constructionTypeOfService, constructionTypesOfItems, gasType, handlingUnit, installationLocation, motorCycleTypes, officeCarType, officeShipHauler, officeShipType, otherVehicles, pulocationType, serviceObj, timeFrameObj, totalUnitsToInstallType, typeOfLocation, unitToInstalled } from '../../utils/constant';

const CategoryDeck = ({userBasicInfo, onSubmit, subCatId,typeId}) => {
    const [submisive, setsubmisive] = useState(false);
    const dataset = serviceObj.find(x => x.id === typeId).formStructure;
    const [data, setdata] = useState(dataset.filter(x => x.subCatId.includes(subCatId)).map(item => { return {...item,value:''}}));
    var i =0;
    const handleChange = (key, value) =>{
        var data_ = [...data];
        const index = data_.findIndex(x => x.id === key);
        data_[index].value = value;
        setdata(data_);
    }   

    const handleCheck = (id) => {
        const sObj = data.find(x => x.id == id);
        return !_.isEmpty(sObj);
    }

    const handleRenderItem = (key) => {
        const sObj = data.find(x => x.id === key);
        if(handleCheck(key)){
            i++;
            return (
                <div className='col-md-4' style={{zIndex:data.length - i}}>
                    <SimpleInputSelector
                    label={sObj?.label}
                    type={sObj?.type}
                    options={sObj?.options}
                    onChange={val => handleChange(key,val)}
                    value={sObj?.value}
                    mode={sObj?.mode}
                    multiple={sObj?.multiple}
                />
                {submisive && sObj?.value === '' &&
                <h4 className='mb-2 mt-2' style={{color:'red',fontSize:14,marginTop:-10}}>{sObj?.label} is required</h4>
                }
                </div>
                );
        }
    }

    const handleSubmission = () => {
        const requiredState = data.filter(x => !x.value);
        if(_.isEmpty(requiredState)){
            var res = {};
            data.map(item => {
                res[item.id] = item.value;
            })
            onSubmit(res);
        }else{
            setsubmisive(true);
        }
    }

    
  
    return(
        <div className="container" style={{minHeight:'100vh'}}>
        <div className="row mt-4">
       
         {handleRenderItem('heading')}
         {handleRenderItem('property_type')}
         {handleRenderItem('deck_issues')}
         {handleRenderItem('deck_job_type')}
         {handleRenderItem('materials')}
         {handleRenderItem('deck_placemnet')}
         {handleRenderItem('apply_sealer')}
         {handleRenderItem('duck_paint')}
         {handleRenderItem('paint_details')}
         {handleRenderItem('expected_size_square_feet')}
            </div>
            <div>
            <button 
               onClick={() => handleSubmission()}
               className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-5 float-right bold" 
               style={{backgroundColor:'#0e415f',color:'#fff',maxWidth:'220px',fontSize:20,zIndex:-1}}
                    type="submit" form="login-form">Save Information<i className="icon-material-outline-arrow-right-alt" />
                  </button>
            </div>
    </div>
    );
}

export default CategoryDeck;