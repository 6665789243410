import React, { useEffect, useState } from 'react';
import { Form, Button, Dropdown, ListGroup } from 'react-bootstrap'
import '../css/style.css';
import '../css/sass/main.css';
import '../css/colors/blue.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { imageURL } from '../utils/constant';
import { connect, useSelector } from 'react-redux';
import { Link, List } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { AccountBalance, AccountBox, Analytics, ArrowBackIos, ArrowForwardIos, ArrowRight, Inbox, LightSharp, ListAlt, PriceChange, Settings, SupervisedUserCircle } from '@mui/icons-material';
import { ArrowLongLeftIcon } from '@heroicons/react/24/outline';


function mapStateToProps(state) {
    return {
        userInfo: state
    };
}



const SellerSection = [
    { id: 0, name: 'Dashboard', icon: <Analytics />, route: '/app/seller/dashboard', current: true, visible: true },
    { id: 1, name: 'Listings', icon: <LightSharp />, route: '/app/seller/lead', current: false, visible: true },
    { id: 2, name: 'Inbox', icon: <Inbox />, route: '/app/seller/inbox', current: false, visible: true },
    { id: 3, name: 'Profile', icon: <SupervisedUserCircle />, route: '/app/seller/profile', current: false, visible: true },
    { id: 4, name: 'My Listing', icon: <ListAlt />, route: '/app/seller/mylead', current: false, visible: true },
    { id: 5, name: 'Quotes', icon: <PriceChange />, route: '/app/seller/quotes', current: false, visible: true },
    { id: 6, name: 'Settings', icon: <Settings />, route: '/app/seller/setting', current: false, visible: true },
    { id: 7, name: 'View Invoice', icon: <AccountBox />, route: '/app/seller/invoice', current: false, visible: true },
    { id: 8, name: 'Earnings', icon: <AccountBalance />, route: '/app/seller/balance', current: false, visible: true },
];

const SellerSidebar = (props) => {
    const { activeId = 0 } = props;
    const [activated, setactivated] = useState(activeId);
    const userInfo = useSelector(state => state);
    const history = useHistory();

    useEffect(() => {
        setactivated(activeId);
    }, [activeId])

    const handleRoute = (route) => {
        const { history } = props;
        history.push(route);
    }

    const handleLogged = (route = '/login') => {
        // if(token){

        //   props.history.push('/login')
        //   window.location.reload(false);
        // }else{
        //   props.history.push('/login')
        // }
        localStorage.removeItem('token');
        localStorage.removeItem('uid');
        localStorage.removeItem('userId');
        localStorage.removeItem('email');
        localStorage.removeItem('state');
        localStorage.removeItem('role');

        props.history.push('/login')
        window.location.reload(true);
    }

    return (
        <div className='bg-white shadow-lg d-none d-sm-none d-xs-none d-xl-block d-md-block d-xxl-block min-h-screen' style={{ flex: '0 0 300px', minHeight: '100vh', }}>
            <div className='flex flex-col items-center justify-center pt-4 ml-[-25px] border-b pb-3 bg-slate-50'>
                <img src={imageURL + userInfo?.profile_img} className='w-20 h-20 rounded-full' />
                <h4 className='text-black text-xl font-bold mt-2 text-left'>{userInfo?.first_name} {userInfo?.last_name}</h4>
                <h4 className='text-slate-500 text-sm'>{userInfo?.email}</h4>
            </div>
            <div className='px-4 py-2'>
                {SellerSection?.map(item => <div onClick={() => history.push(item.route)}
                    className='cursor-pointer my-3 flex flex-row items-center'>
                    <div className='bg-yellow-500 flex flex-row items-center justify-center p-1 rounded-full w-8 h-8 border-yellow-500 mr-3 text-white'>{item?.icon}</div>
                    <div className='flex-1'>
                        <h4 className='font-bold'>{item.name}</h4>
                    </div>
                    <ArrowForwardIos className='text-yellow-800 text-sm' />
                </div>)}

            </div>
        </div>
    );

    return (
        <div className='bg-white p-2 shadow-lg d-none d-sm-none d-xs-none d-xl-block d-md-block d-xxl-block min-h-screen' style={{ flex: '0 0 300px', minHeight: '100vh', }}>
            <div className='bg-yellow-500 shadow-xl p-4 ml-2 mt-4 mr-6 rounded text-center items-center flex flex-row items-center'>
                <img src={imageURL + userInfo?.profile_img} className='w-10 h-10 rounded-full' />
                <h4 className='text-white text-left ml-3'>{userInfo?.first_name} {userInfo?.last_name}</h4>
            </div>
            <div className='mt-4'>
                {SellerSection?.map(item => <h4 onClick={() => history.push(item.route)} className='bg-slate-700 cursor-pointer text-sm text-white p-3 ml-2 mt-2 mr-6  rounded-md'>

                    {item.name}</h4>)}
            </div>
        </div>

    );
}



export default connect(
    mapStateToProps,
    null,
)(SellerSidebar);



