import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import {
  Elements,
  CardElement,
  useElements,
  CardCvcElementComponent,
  useStripe,
  CheckoutForm,
  CardCvcElement
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  minHeight: 250,
  paddingTop: 4,
  borderRadius: 4,
  borderLeft: '10px solid #666',
  borderRight: '10px solid #666',
};

const boxStyle = {
  background: '#f7f7f7',
  width: '100%',
  height: '200px',
  marginTop: 30
}

const PayModel = ({ ...props }) => {
  const { open, onClose, type = 1, title, message, onMove, onMoveState = false, onSubmit } = props;


  const handleSubmission = async (stripe, elements) => {
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      alert('Your payment method was declined. Please try again or enter another payment method.')
    } else {
      onSubmit(paymentMethod.id)
      // ... SEND to your API server to process payment intent
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className='text-center'>
        <button className='icon-line-awesome-close modal-cross-btn'
          style={{
            position: 'absolute',
            top: 5,
            right: 10,
            background: '#3b73a9',
            padding: 10,
            borderRadius: 100,
            color: '#fff'
          }}

          onClick={onClose}
        >

        </button>
        <Elements stripe={stripePromise} >
          <PaymentForm handleSubmit={(e, v) => handleSubmission(e, v)} />
        </Elements>
      </Box>
    </Modal>
  );
};

export default PayModel;

const PaymentForm = ({ ...props }) => {
  const stripe = useStripe();
  const elements = useElements();

  return (
    <div style={{ padding: 20, paddingTop: 0 }}>
      <h1 className='bold' style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'left' }}>Add Payment Method</h1>
      <p className='para' style={{ fontSize: 12, textAlign: 'left' }}>Save your card for all type of future payments</p>
      <CardElement
        className="box-shadow-variant form-control pt-2 mt-4"
        options={{
          border: '1px solid #efefef',
          style: {
            base: {
              color: "#666",
              fontSize: "16px",


            },
            invalid: {
              color: "#fa755a",
              fontSize: "fa755a",
            }
          }
        }}
      />
      <button
        onClick={() => props.handleSubmit(stripe, elements)}
        className="button full-width button-sliding-icon ripple-effect mb-3 mt-2"
        style={{ backgroundColor: '#444', color: '#fff' }}
        type="type" form="login-form">Save</button>
    </div>
  );
}