import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import DatePicker from "react-datepicker";
import './../../css/style.css';
import '../../css/sass/main.css';
import '../../css/colors/blue.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { FormControl, FormControlLabel, Radio, RadioGroup, Rating } from '@mui/material';
import "react-datepicker/dist/react-datepicker.css";
import { acSize, airConditionType, booleanAns, buildingStories, installationLocation, typeOfLocation, unitToInstalled } from '../../utils/constant';
import { InputSelector } from '..';
import _ from 'lodash';


class ACCatFirstSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            subCatId: this.props.subCatId,
            formStructure: this.props.formStructure,
            submitMode: false,
            setarr: [],
            loader: true,
        }
        this.refs = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    inputRefs = [];

    componentDidMount() {
        var setarr_ = [];
        this.state.formStructure.map((item, index) => {
            if (item.subCatId.includes(this.state.subCatId)) {
                setarr_.push({ ...item, required: true });
            }
        });
        this.setState({
            setarr: setarr_,
            loader: false
        });
    }

    setRef = (ref, id) => {
        var refObj = {};
        refObj[id] = ref;
        this.inputRefs.push(ref);
    };
    handleSubmit = () => {
        const { setarr } = this.state;
        var arr_ = [...setarr];
        this.inputRefs.map(item => {
            if (_.isNull(item.value)) {
                const index = arr_.findIndex(x => x.id === item.id);
                arr_[index].value = item.value;
                arr_[index].required = false;
            }
        });
        const findnoempty = arr_.find(x => x.required);
        if (findnoempty) {
            this.setState({
                submitState: true,
                setarr: arr_
            });
        } else {
            // this.props.onSubmit(arr_)
        }

    }

    render() {
        const { formStructure, subCatId, submitState, loader, setarr } = this.state;
        if (loader) return <div />
        return (
            <div className="container-fluid">
                <h2 className="bold">Service Information Section</h2>
                <p>Lorem ipsum is the best key to success at that early age.</p>

                <div className="row">
                    {setarr.map((item, index) => {
                        // if(item.subCatId?.includes(subCatId)){
                        return (
                            <div className="col-md-4" key={item.id}>
                                <InputSelector
                                    id={item.id}
                                    index={index}
                                    label={item.label}
                                    type={item.type}
                                    options={item.options}
                                    ref={ref => this.setRef(ref, item.id)}
                                />
                                {submitState && item.required &&
                                    <h4 style={{ color: 'red' }}>{item.label} is required</h4>
                                }
                            </div>
                        );
                        // }
                    })}

                </div>
                <div>
                    <button
                        onClick={this.handleSubmit}
                        className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-3"
                        style={{ backgroundColor: '#1fc7c7', color: '#fff', maxWidth: '40%', margin: 'auto' }}
                        type="submit" form="login-form">Save<i className="icon-material-outline-arrow-right-alt" />
                    </button>
                </div>
            </div>
        );
    }

}

export default ACCatFirstSection;