import * as yup from 'yup';

const registerValidationSchema = yup.object().shape({
  accountType: yup
    .string()
    .required('Account Type is Required'),

  profile_img: yup
    .string()
    .required('Profile Image is Required'),
  email: yup
    .string()
    .email("Please enter valid email")
    .required('Please enter a valid email address'),
  fname: yup
    .string()
    .required('Please enter your first name'),
  lname: yup
    .string()
    .required('Please enter your last name'),
  // ssn: yup
  //   .string()
  //   .min(1, "Phone number is too short")
  //   .max(9, "Phone number is too long")
  //   .required('You must enter a valid  9 digit SSN'),
  phone: yup
    .string()
    .min(10, "Phone number is too short")
    .max(10, "Phone number is too long")
    .required('Please enter a valid phone number with area code first'),
  pass: yup
    .string()
    .required('Password is Required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  cpass: yup
    .string()
    .oneOf([yup.ref('pass'), null], 'Passwords must match')
    .required("Confirm Password is Required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
});

const irisValidation = yup.object().shape({

  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  tin_type: yup.string().required('Tin Type is required'),
  ssn_tin: yup.string().required('SSN / TIN is required'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  postal_code: yup.string().required('Postal Code is required'),
  phone_number: yup.string().required('Phone Number is required'),



})



const leadregisterValidationSchema = yup.object().shape({
  accountType: yup
    .string()
    .required('Account Type is Required'),
  email: yup
    .string()
    .email("Please enter valid email")
    .required('Email Address is Required'),
  fname: yup
    .string()
    .required('First Name is Required'),
  lname: yup
    .string()
    .required('Last Name is Required'),
  // ssn: yup
  //   .string()
  //   .required('Field is Required'),
  phone: yup
    .string()
    .required('Phone Number is Required'),
  pass: yup
    .string()
    .required('Password is Required'),
  cpass: yup
    .string()
    .oneOf([yup.ref('pass'), null], 'Passwords must match')
    .required("Confirm Password is Required")
});


export { registerValidationSchema, leadregisterValidationSchema, irisValidation };