import React, { Suspense, useState } from 'react';
import logo from './logo.svg';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { StyledEngineProvider } from '@mui/material/styles';


import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Dashboard from './scenes/dashboard.scene';
import Invoice from './scenes/invoice.scene';
import { useEffect } from 'react';
import { firebaseConfig } from './utils/constant';
import './index.css';
import { ProtectedRoute } from './utils/authHelper';
import IrisScene from './scenes/_iris';

const ViewApp = React.lazy(() =>
  import(/* webpackChunkName: "views-app" */ './routes/application')
);
const ViewUser = React.lazy(() =>
  import(/* webpackChunkName: "views-user" */ './routes/user')
);
const ViewSellerMode = React.lazy(() =>
  import(/* webpackChunkName: "views-user" */ './routes/seller')
);

const App = () => {

  return (
    <StyledEngineProvider injectFirst>
      <Suspense fallback={<div className="loading" />}>
        <Router>
          <Switch>
            <ProtectedRoute
              path={'/app/'}
              component={ViewApp}
              sellercomponent={ViewSellerMode}
            />

            <Route
              path="/"
              render={(props) => <ViewUser {...props} handleClick={route => props.history.push(route)} val={10} />}
            />



            <Route
              path="/error"
              exact
              render={(props) => <div {...props} />}
            />


          </Switch>
        </Router>
      </Suspense>
    </StyledEngineProvider>

  );
}

export default App;
