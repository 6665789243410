import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash'
import { LinkIcon } from '@heroicons/react/24/outline';
import { Remove } from '@mui/icons-material';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  paddingTop: '50px',
  height: 200,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fff',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function getExtension(filename) {
  return filename.split('.').pop()
}


const ImageUploader = (props) => {
  const { sendFiles, numberOfFiles, removeFile, customStyles = {}, textStyle = {}, noIcon = false, text = "Drop or choose file (png, jpg, pdf)" } = props;
  const [allfiles, setallfiles] = useState([]);
  const onDrop = React.useCallback(acceptedFiles => {

    setallfiles(acceptedFiles?.filter(x => ['jpg', 'jpeg', 'png'].includes(getExtension(x['name'].toLowerCase()))))
  }, []);
  const { getRootProps, getInputProps, isDragActive,
    isDragAccept,
    isDragReject } = useDropzone({ onDrop, maxFiles: numberOfFiles });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const files = allfiles.map(file => (
    <div className='bg-white rounded h-14 flex flex-row justify-center items-center px-3'>
      <p className='mt-3' style={{ flex: 1 }}>{file.path}</p>
      <Remove onClick={() => handleRemoveFile(file)} />

    </div>
  ));


  const handleRemoveFile = (_file) => {
    setallfiles(allfiles.filter(x => x != _file))
  }

  useEffect(() => {
    sendFiles(allfiles)
  }, [allfiles]);



  return (
    <section className="container p-0">
      <div {...getRootProps({ style })} className=' bg-slate-50 rounded-md  py-4 items-center justify-center flex flex-col' style={{ ...customStyles }}>
        <input {...getInputProps()} />
        <div className='w-10 h-10  p-2'>
          <LinkIcon />
        </div>
        <p className="bold mt-2" style={{ ...textStyle }}>{text}</p>

      </div>
      <aside className='mt-2'>
        {files}
        {/* {!_.isEmpty(files) && } */}

      </aside>
    </section>
  );
}

export default ImageUploader;