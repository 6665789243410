/*global google*/
import React, { Component } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  DirectionsRenderer,
} from "react-google-maps";
import Circle from "react-google-maps/lib/components/Circle";

const RenderMap = withGoogleMap(props => (
  <GoogleMap
    defaultCenter={props.destination}
    defaultZoom={13}
    options={{
      fullscreenControl: false,
      zoomControl: false,
      mapControl: false,
      mapTypeControl: false,
      gestureHandling: "none",
      zoomControl: false,
    }}
  >
    <Circle
      defaultCenter={props.destination}
      radius={1500}
      options={{
        fillColor: `#FF0000`,
        fillOpacity: 0.2,
        strokeWeight: 0.1,
        clickable: false,
        editable: true,
        zIndex: 1,
      }}
    />
  
  </GoogleMap>
));

class Map2Section extends Component {
  state = {
    directions: null
  };



  render() {


    return (
      <div>
        <RenderMap
          destination={this.props.destination}
          containerElement={<div style={{ height: `400px`, width: "100%" }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}

export default Map2Section;
