import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { handleDays } from '../../utils/function';


const ListItem = ({ ...props }) => {
    const { title, postedOn, categoryName, subcategoryName, location, data, price, handleClick,
        onmoreInfo, wishlisted, handleSaveWishlist, noauth, selectedcategory } = props;

    return (
        <div

            className='row w-[99%] m-0 mt-3 p-2 lg:p-0 shadow-md rounded-md border pb-3'
            style={{
                background: '#fff',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <div className='col-md-2 mt-3 lg:mt-0'>

                <h4 className='bold text-overflow text-md capitalize' style={{ letterSpacing: 1, maxWidth: '100%' }}>{title}</h4>
                <p className="mb-0 mt-1 text-xs" style={{ color: '#afafaf', lineHeight: 1 }}>Created on: {moment(data?.created_on).tz('America/New_York')?.format('YYYY-MM-DD')}</p>
            </div>
            <div className='col-md-2 mt-3 lg:mt-0'>
                <h4 className='bold text-xs' style={{ letterSpacing: 1 }}>{categoryName.includes('Shipping') ? categoryName.split('_')[1] : categoryName}</h4>
                <p className="mb-0 mt-1 text-sm" style={{ color: '#afafaf', lineHeight: 1 }}>{subcategoryName}
                    {/* {JSON.stringify(data.pickup_location)} */}
                </p>
            </div>
            <div className='col-md-4 mt-3 lg:mt-0'>
                {/* fontSize:data.categoryName.includes('Shipping') ? 12 : 14 */}
                <h4 className='bold mt-2 text-xs' style={{ letterSpacing: 1 }}>
                    {'Destination - ' + data.destination_location.replace('United States', '').replace('USA', '')}
                </h4>
                <h4 className='bold mt-2 text-xs' style={{ letterSpacing: 1 }}>
                    {'Pick up - ' + data.pickup_location.replace('United States', '').replace('USA', '')}
                </h4>

            </div>
            <div className='col-md-2 mt-3 lg:mt-0'>
                <h4 className='bold fs_sm_head' style={{ letterSpacing: 1 }}>{price.includes('Open') ? price : `$${price}`}</h4>
                <p className="mb-0 mt-2 text-sm" style={{ color: '#afafaf', maxWidth: '100%', lineHeight: 1 }}>{handleDays(data.created_on)}</p>
            </div>
            <div className='col-md-2 mt-2 lg:mt-0 flex flex-row lead-list-icon-container' >
                <button
                    onClick={onmoreInfo}

                    className="bg-slate-50 rounded-lg border w-8 lg:w-12 h-8 lg:h-12 pt-2"
                >
                    <i className='icon-feather-arrow-down text-lg'></i>
                </button>
                <button
                    onClick={handleClick}
                    className="bg-slate-50 rounded-lg border w-8 lg:w-12 h-8 lg:h-12 pt-1 ml-3"
                >
                    <i className='icon-feather-eye text-md'></i>
                </button>
                {!noauth &&
                    <button
                        onClick={handleSaveWishlist}
                        className="bg-slate-50 rounded-lg border w-8 lg:w-12 h-8 lg:h-12 pt-2 ml-3"
                        style={{
                            backgroundColor: wishlisted ? 'rgb(207 112 112)' : '#f7f7f7',

                        }}
                    >
                        <i className='icon-feather-heart text-md ' style={{ color: wishlisted ? '#fff' : '#444' }}></i>
                    </button>
                }
            </div>

        </div>
    );
}

export default ListItem;



