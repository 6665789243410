import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import { InputSelector, MoveItem, SimpleInputSelector, ImageUploader } from '../index';
import {
    booleanAns, buildingStories,
    pulocationType, timeFrameObj,
    operationType,
    pickupLocationAdditionalInfo,
    timeFrame,
    packingSupplies
} from '../../utils/constant';
import TimePicker from 'react-time-picker/dist/entry.nostyle';
import moment from 'moment';

const requiredKey = {
    pickup_location: true,
    // pickup_stories:true,
    elevator_pickup: true,
    location_type_tf: true,
    pickup_tf: true,
    pickupdate: true,
    pickuptime: true,
    // dropoff_stories:true,
    elevator_dropoff: true,
    location_type_df: true,
    dropoff_tf: true,
    dropoffdate: true,
    dropofftime: true,
    destination_location: true,
}

const ShipLocationInfo = ({ userBasicInfo, handleSubmit, subcatId, ImageSet, msubcatId }) => {
    const [shipmentInfo, setshipmentInfo] = useState({ pickupdate: new Date(), pickuptime: '10:00pm', dropoffdate: new Date(), dropofftime: '11:00pm' });
    const [requiredKeys, setrequiredKeys] = useState(requiredKey);
    const [images, setimages] = useState([]);
    const [submisive, setsubmisive] = useState(false);
    const [pickup, setpickup] = useState([]);
    const [pickuplocation, setpickuplocation] = useState('');
    const [destination, setdestination] = useState([]);
    const [destinationlocation, setdestinationlocation] = useState('');
    // useEffect(() =>{
    //     setshipmentInfo(userBasicInfo);
    // },[]);

    const handleChange = (key, value) => {

        var shipmentInfoClone = { ...shipmentInfo };
        shipmentInfoClone[key] = value;
        var requiredKeys_ = { ...requiredKeys };
        if (Object.keys(requiredKeys).includes(key)) {
            requiredKeys_[key] = false;
            setrequiredKeys(requiredKeys_);
        }
        setshipmentInfo(shipmentInfoClone);
    }

    const handleOnChange = (key, key2, value, value2) => {
        var shipmentInfoClone = { ...shipmentInfo };
        shipmentInfoClone[key] = value;
        shipmentInfoClone[key2] = value2;
        setshipmentInfo(shipmentInfoClone);
    }

    const handleSubmission = () => {
        // handleSubmit({...shipmentInfo, extra_info:JSON.stringify(shipmentInfo.extra_info)})
        // console.log({...shipmentInfo, extra_info:JSON.stringify({...shipmentInfo.pickup,...shipmentInfo.destination})})
        // console.log()
        if (Object.values(requiredKeys).every((v) => v === true)) {
            setsubmisive(true);
        } else {
            if (pickuplocation && destinationlocation) {
                handleSubmit({
                    ...shipmentInfo,
                    pictures: images,
                    pickup_location: pickuplocation,
                    destination_location: destinationlocation,
                    extra_info: JSON.stringify({
                        pickup,
                        destination
                    })
                })
            } else {
                setsubmisive(true);
            }

        }
    }

    return (
        <div className="container">

            <div className='row mt-4'>


                <div className='col-md-6'>
                    <h4 className="mb-2"> Pick up location</h4>
                    <Autocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                        className="mt-2 input-style"
                        id="abcd"
                        onPlaceSelected={(place) => {
                            // handleGetAddressInfo(place);
                            // handleChange('extra_info',JSON.stringify({pickup:place}));
                            // handleChange('pickup_location',place.formatted_address);
                            // handleOnChange('pickup','pickup_location',place,place.formatted_address);
                            // console.log(place)
                            // setshipmentInfo({...shipmentInfo,pickup_location:place.formatted_address,pickup:place})
                            setpickup(place)
                            setpickuplocation(place.formatted_address)
                        }}
                        options={{ fields: ["address_components", "geometry", "formatted_address"], types: ["address"] }}
                    />
                    {submisive && requiredKeys['pickup_location'] &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Destination is required</h4>
                    }
                    {/* <SimpleInputSelector
                    label="Pickup stories"
                    type="select"
                    options={buildingStories}
                    value={shipmentInfo.pickup_stories}
                    onChange={e => handleChange('pickup_stories',e)}
                    />
                       {submisive && requiredKeys['pickup_stories'] &&
                        <h4 className='mb-2 mt-2' style={{color:'red',fontSize:14,marginTop:-10}}>Pickup stories is required</h4>
                        }   */}
                    <SimpleInputSelector
                        label="Elevator at pick up location"
                        type="select"
                        options={booleanAns}
                        value={shipmentInfo.elevator_pickup}
                        onChange={e => handleChange('elevator_pickup', e)}
                    />
                    {submisive && requiredKeys['elevator_pickup'] &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Elevator at pick up location is required</h4>
                    }
                    <div className='mt-4' />
                    <SimpleInputSelector
                        label="Pick up location type"
                        type="select"
                        options={pickupLocationAdditionalInfo}
                        value={shipmentInfo.location_type_tf}
                        onChange={e => handleChange('location_type_tf', e)}
                    />
                    {submisive && requiredKeys['location_type_tf'] &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Pick up location type is required</h4>
                    }
                    <SimpleInputSelector
                        label="Shipment Pick up date"
                        type="select"
                        options={timeFrameObj}
                        value={shipmentInfo.pickup_tf}
                        onChange={e => handleChange('pickup_tf', e)}
                    />
                    {submisive && requiredKeys['pickup_tf'] &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Shipment Pick up date is required</h4>
                    }
                    <h4 className='mt-3'>Pick up date</h4>
                    <DatePicker
                        className="mt-2 input-style"
                        minDate={moment().toDate()}
                        selected={Date.parse(shipmentInfo.pickupdate)}
                        onChange={e => handleChange('pickupdate', e)} />
                    <div style={{ marginTop: -20 }} />
                    {submisive && requiredKeys['pickupdate'] &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Pick up date is required</h4>
                    }
                    {shipmentInfo.pickup_tf === 'Timeframe' && <>
                        <h4 className='mt-3'>Pick up other date</h4>
                        <DatePicker
                            className="mt-2 input-style"
                            minDate={moment().toDate()}
                            selected={Date.parse(shipmentInfo.pickupdate_range)}
                            onChange={e => handleChange('pickupdate_range', e)} />
                        <div style={{ marginTop: -20 }} />
                        {submisive && requiredKeys['pickupdate_range'] &&
                            <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Pick up other date is required</h4>
                        }
                    </>}
                    <SimpleInputSelector
                        label="Pick up time"
                        type="select"
                        options={timeFrame}
                        onChange={e => handleChange('pickuptime', e)}
                    />
                    {/* <SimpleInputSelector
                        label="Shipment Pick up date"
                        type="select"
                        options={timeFrameObj}
                        value={shipmentInfo.pickuptime}
                        onChange={e => handleChange('pickuptime', e)}
                    /> */}
                    {submisive && requiredKeys['pickuptime'] &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Pick up time is required</h4>
                    }




                </div>
                <div className='col-md-6'>
                    <h4 className="mb-2"> Drop off location</h4>
                    <Autocomplete
                        className="mt-2 input-style"
                        id="abcdef"
                        apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                        onPlaceSelected={(place) => {
                            setdestination(place)
                            setdestinationlocation(place.formatted_address)
                        }}
                        options={{ fields: ["address_components", "geometry", "formatted_address"], types: ["address"] }}
                    />
                    {submisive && requiredKeys['destination_location'] &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Drop off location is required</h4>
                    }
                    {/* <SimpleInputSelector
                    label=" Drop off stories"
                    type="select"
                    value={shipmentInfo.dropoff_stories}
                    options={buildingStories}
                    onChange={e => handleChange('dropoff_stories',e)}
                    />
                     {submisive && requiredKeys['dropoff_stories'] &&
                        <h4 className='mb-2 mt-2' style={{color:'red',fontSize:14,marginTop:-10}}>Drop off stories is required</h4>
                        } */}
                    <SimpleInputSelector
                        label="Elevator at drop off location"
                        type="select"
                        options={booleanAns}
                        value={shipmentInfo.elevator_dropoff}
                        onChange={e => handleChange('elevator_dropoff', e)}
                    />
                    {submisive && requiredKeys['elevator_dropoff'] &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Elevator at drop off location is required</h4>
                    }
                    <div className='mt-4' />
                    <SimpleInputSelector
                        label="Drop off location type"
                        type="select"
                        options={pickupLocationAdditionalInfo}
                        value={shipmentInfo.location_type_df}
                        onChange={e => handleChange('location_type_df', e)}
                    />
                    {submisive && requiredKeys['location_type_df'] &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Drop off location type is required</h4>
                    }
                    {/* <SimpleInputSelector
                    label="Lead Expiry Date"
                    type="input"
                    maxdate
                    mode="date"
                    date
                    value={shipmentInfo.leadExpiry}
                    onChange={e => handleChange('leadExpiry', e)}
                    />  */}
                    <SimpleInputSelector
                        label="Shipment drop off date"
                        type="select"
                        options={timeFrameObj}
                        value={shipmentInfo.dropoff_tf}
                        onChange={e => handleChange('dropoff_tf', e)}
                    />
                    {submisive && requiredKeys['dropoff_tf'] &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Shipment drop off date is required</h4>
                    }
                    <h4 className='mt-3'>Drop off date</h4>
                    <DatePicker
                        className="mt-2 input-style"
                        minDate={moment(shipmentInfo.pickupdate).toDate()}
                        selected={Date.parse(shipmentInfo.dropoffdate)}
                        onChange={e => handleChange('dropoffdate', e)} />
                    <div style={{ marginTop: -20 }} />
                    {submisive && requiredKeys['dropoffdate'] &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Drop off date is required</h4>
                    }
                    {shipmentInfo.dropoff_tf === 'Timeframe' && <>
                        <h4 className='mt-3'>Drop off other date</h4>
                        <DatePicker
                            className="mt-2 input-style"
                            minDate={moment(shipmentInfo.pickupdate).toDate()}
                            selected={Date.parse(shipmentInfo.dropoffdate_range)}
                            onChange={e => handleChange('dropoffdate_range', e)} />
                        <div style={{ marginTop: -20 }} />
                        {submisive && requiredKeys['dropoffdate_range'] &&
                            <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Drop off other date is required</h4>
                        }
                    </>}
                    {/* <SimpleInputSelector
                        label="Drop off time"
                        type="input"
                        time={true}
                        onChange={e => handleChange('dropofftime', e)}
                    /> */}
                    <SimpleInputSelector
                        label="Drop off time"
                        type="select"
                        options={timeFrame}
                        onChange={e => handleChange('dropofftime', e)}
                    />
                    {submisive && requiredKeys['dropofftime'] &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Drop off time is required</h4>
                    }

                </div>


            </div>
            <h2 className='bold mt-5'>Additional Information</h2>
            <div className='row mt-4'>

                <div className='col-md-6'>

                    <SimpleInputSelector
                        label="Packing Materials Provided By"
                        type="select"
                        options={operationType}
                        value={shipmentInfo.packing_info}
                        onChange={e => handleChange('packing_info', e)}
                    />
                    <SimpleInputSelector
                        label="Packing Materials"
                        type="select"
                        options={packingSupplies}
                        multiple
                        value={shipmentInfo.packing_supplies}
                        onChange={e => handleChange('packing_supplies', e)}
                    />
                    <h4 className="mt-5">Pictures</h4>
                    <ImageUploader
                        sendFiles={e => setimages(e ? [...images, ...e] : [...images])}
                        numberOfFiles={20}
                        previousFiles={[...images]}
                    />
                    <SimpleInputSelector
                        label="Additional Details"
                        type="textarea"
                        value={shipmentInfo.description}
                        onChange={e => handleChange('description', e)}
                    />
                </div>
                <div className='col-md-6'>

                    {/* <SimpleInputSelector
              label="Prefared Vehicle type"
              type="select"
              value={shipmentInfo.car_hauler}
              options={officeShipHauler}
              onChange={e => handleChange('car_hauler', e)}
              /> */}


                    {subcatId == 3 || subcatId == 2 && <>
                        <SimpleInputSelector
                            label="Will cubicles or other items need to be disassembled"
                            type="select"
                            options={booleanAns}
                            value={shipmentInfo.cubicles_disassembled}
                            onChange={e => handleChange('cubicles_disassembled', e)}
                        />
                        <SimpleInputSelector
                            label="Will cubicles or other items need to be re-assembled"
                            type="select"
                            options={booleanAns}
                            value={shipmentInfo.cubicles_resassembled}
                            onChange={e => handleChange('cubicles_resassembled', e)}
                        />
                    </>}
                    {subcatId == 1 && <>
                        <SimpleInputSelector
                            label="Item(s) stackable: (item has flat surfaces and can sustain the weight of another object)"
                            type="select"
                            options={booleanAns}
                            value={shipmentInfo.item_stackable}
                            onChange={e => handleChange('item_stackable', e)}
                        />
                        <SimpleInputSelector
                            label="Item Palletized or boxed"
                            type="select"
                            options={booleanAns}
                            value={shipmentInfo.item_crated}
                            onChange={e => handleChange('item_crated', e)}
                        />

                        {/* <SimpleInputSelector
                            label="Item on Pallete:(item is on a wooden or plastic pallet and can be moved by forklift))"
                            type="select"
                            options={booleanAns}
                            value={shipmentInfo.item_pallete}
                            onChange={e => handleChange('item_pallete', e)}
                        /> */}
                    </>}

                    {subcatId == 2 && (msubcatId >= 1 && msubcatId < 4) && <>
                        <SimpleInputSelector
                            label="Water Protection"
                            type="select"
                            options={booleanAns}
                            value={shipmentInfo.water_protection}
                            onChange={e => handleChange('water_protection', e)}
                        />
                        <SimpleInputSelector
                            label="Need Support to load and Unload Item"
                            type="select"
                            options={booleanAns}
                            value={shipmentInfo.support_load}
                            onChange={e => handleChange('support_load', e)}
                        />
                        <SimpleInputSelector
                            label="Number of People"
                            type="input"
                            value={shipmentInfo.number_of_people}
                            onChange={e => handleChange('number_of_people', e)}
                        />

                        {/* <SimpleInputSelector
              label="Who will provide support for load and unload"
              type="select"
              options={operationType}
              value={shipmentInfo.item_pallete}
              onChange={e => handleChange('support_need', e)}
              />  */}
                    </>}

                    {subcatId == 7 && <>
                        {/* <SimpleInputSelector
                            label="Need Support to load and Unload Item"
                            type="select"
                            options={booleanAns}
                            value={shipmentInfo.support_load}
                            onChange={e => handleChange('support_load', e)}
                        /> */}
                        {/* <SimpleInputSelector
                            label="Number of People"
                            type="input"
                            value={shipmentInfo.number_of_people}
                            onChange={e => handleChange('number_of_people', e)}
                        /> */}

                        {/* <SimpleInputSelector
                            label="Who will provide support for load and unload"
                            type="select"
                            options={operationType}
                            value={shipmentInfo.item_pallete}
                            onChange={e => handleChange('support_by', e)}
                        /> */}
                    </>}



                </div>
            </div>

            <div>
                <button
                    onClick={() => handleSubmission()}
                    className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-5 float-right bold"
                    style={{ backgroundColor: '#444', color: '#fff', maxWidth: '300px', fontSize: 16, borderRadius: 100 }}
                    type="submit" form="login-form">Save Information<i className="icon-material-outline-arrow-right-alt" />
                </button>
            </div>
        </div>
    );
}

export default ShipLocationInfo;