import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  minHeight: 150,
  paddingTop: 4,
};

const boxStyle = {
  background: '#f7f7f7',
  width: '100%',
  height: '200px',
  marginTop: 30
}

const AlertAsk = ({ ...props }) => {
  const { open, onClose, onAccept, message, onCancel } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className='text-center'>

        <Typography
          style={{ paddingTop: 0, fontSize: 25, fontWeight: 'bold' }}
        >
          {message}
        </Typography>
        <div className='row'>
          <button
            onClick={onClose}
            className="button full-width button-sliding-icon ripple-effect p-2 mb-3 mt-5"
            style={{ backgroundColor: '#f7f7f7', color: '#444', maxWidth: '40%', margin: 'auto', border: '1px solid #efefef' }}
            type="type" form="login-form">Cancel</button>
          <button
            onClick={onAccept}
            className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-5"
            style={{ backgroundColor: '#444', color: '#fff', maxWidth: '40%', margin: 'auto' }}
            type="type" form="login-form">OK</button>
        </div>
      </Box>
    </Modal>
  );
};

export default AlertAsk;