import React, { useState, useRef, useEffect } from 'react';
import { booleanAns } from '../utils/constant';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from 'react-select/creatable';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons'

var invalidChars = [
    "-",
    "+",
    "e",
    '.'
];
const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        width: '100%',
        color: state.selectProps.menuColor,
        padding: 20,
    }),

    container: () => ({
        width: '100%',
    }),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

const SimpleInputSelector = (props) => {
    const inputRef = useRef();
    const { id, type, options = [], maxdate = false, value, onChange, addition = "not", label, placeholder, nomargintop, time, style, date, inputType = 'text', min, mode = 'text', multiple = false, max, inputstyle = {} } = props;
    const [booleanRes, setbooleanRes] = useState(value);
    var dataset = [];
    if (type == 'select') {
        options.map(item => {
            dataset.push({
                label: item,
                value: item
            })
        })
    }


    if (type === 'select' && mode === 'radio') {
        return (
            <div>
                <h4 className='mt-4'>{label}</h4>
                <RadioGroup onChange={e => onChange(e)} horizontal className="mt-2 radio-inner-bdc"
                >
                    <RadioButton
                        iconSize={25}
                        iconInnerSize={15}
                        rootColor="#afafaf"
                        pointColor="#0e415f"
                        value="Yes">
                        Yes
                    </RadioButton>
                    <RadioButton
                        iconSize={25}
                        iconInnerSize={15}
                        rootColor="#afafaf"
                        pointColor="#0e415f"
                        value="No">
                        No
                    </RadioButton>
                </RadioGroup>
            </div>

        );
    }

    if (type === 'select' && multiple) {
        return (
            <div>
                <h4 className='mt-4'>{label}</h4>
                <Select
                    isMulti
                    name="colors"
                    // styles={customStyles}
                    options={dataset}
                    styles={colourStyles}
                    className='mt-2 bg-white b-none'
                    onChange={e => onChange(e.map(item => item.label).toString())}
                />
            </div>

        );
    }

    if (type === 'simpleselect') {
        return (
            <div>
                <h4 className='mt-4'>{label}</h4>
                <Select
                    name="colors"
                    // styles={customStyles}
                    onChange={e => onChange(e.value)}
                    // onInputChange={this.handleInputChange}
                    options={dataset}
                    className="mt-2 input-style b-none bg-white"
                    classNamePrefix="select"
                />
            </div>


        );
    }



    if (type === 'input' && mode === 'date') {
        return (
            <div>
                {label &&
                    <h4 className='mt-4'>{label}</h4>
                }
                <DatePicker
                    className="mt-2 input-style"
                    placeholderText='MM-DD-YYYY'
                    minDate={moment().toDate()}
                    maxDate={new Date(maxdate ? moment().add(14, 'd') : moment().add(365, 'd'))}
                    selected={value}
                    onChange={e => onChange(e)}>

                </DatePicker>
                <div style={{ marginBottom: -20 }} />
            </div>
        );
    } else
        if (type === 'input') {
            return (
                <div>
                    <h4 className='mt-4'>{label}</h4>
                    <input
                        type={inputType !== 'text' ? inputType : date ? 'date' : time ? 'time' : mode}
                        className="mt-2 input-style pl-3"
                        onKeyDown={(e) => mode == "number" && ["e", "E", "+", "-", ".", ","].includes(e.key) && e.preventDefault()}
                        // min={min}
                        maxlength={max ? max : 140}
                        style={{ height: 60, fontSize: 16, borderWidth: 1 / 2, maxWidth: '100%', margin: 'auto', ...inputstyle }}
                        value={value}
                        placeholder={placeholder}
                        onChange={e => label == 'Year' ? e.target.value?.length <= 4 && onChange(e.target.value) : mode == 'number' ? e.target.value > -1 & !e.target.value.includes('.') && onChange(parseInt(e.target.value)) : onChange(e.target.value)}
                    />
                </div>
            );
        } else if (type === 'select') {
            return (
                <div>
                    <h4 className='mt-4'>{label}</h4>

                    <CreatableSelect
                        isClearable
                        onChange={e => onChange(e.value)}
                        // onInputChange={this.handleInputChange}
                        isMulti={multiple}
                        options={dataset}
                        // className="mt-2 input-style b-none bg-white"
                        styles={colourStyles}
                        className='mt-2 bg-white b-none'
                    />
                </div>
            );
        } else if (type === 'textarea') {
            return (
                <div>
                    <h4 className='mt-4'>{label}</h4>
                    <textarea
                        className="mt-2 input-style"
                        style={{ height: 200, fontSize: 16, borderWidth: 1 / 2, maxWidth: '100%', margin: 'auto', borderRadius: 10 }}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        placeholder={placeholder}
                    ></textarea>
                </div>
            );

        } else {
            return (
                <div>
                    <h4 className='mt-4'>{label}</h4>
                    <input
                        type={inputType !== 'text' ? inputType : date ? 'date' : time ? 'time' : mode}
                        className="mt-2 input-style pl-3"
                        min={min}
                        ref={inputRef}
                        style={{ height: 60, fontSize: 16, borderWidth: 1 / 2, maxWidth: '100%', margin: 'auto', }}
                        value={value}
                        placeholder={placeholder}
                        onChange={e => mode == 'number' ? e.target.value > -1 && onChange(e.target.value) : onChange(e.target.value)}
                        onKeyDown={e => e.preventDefault()}
                    />
                </div>
            );
        }

};

const colourStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: 0,
        borderWidth: 0
    }),
}

export default SimpleInputSelector;