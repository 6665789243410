import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import { InputSelector, SimpleInputSelector } from '..';
import { acSize, airConditionType, booleanAns, buildingStories, businessType, carCondition, carTruckTypes, constructionTypeOfGoods, constructionTypeOfService, constructionTypesOfItems, gasType, handlingUnit, installationLocation, motorCycleTypes, officeCarType, officeShipHauler, officeShipType, otherVehicles, pulocationType, serviceObj, timeFrameObj, totalUnitsToInstallType, typeOfLocation, unitToInstalled } from '../../utils/constant';

const CategoryPest = ({ userBasicInfo, onSubmit, subCatId, typeId }) => {
    const [submisive, setsubmisive] = useState(false);
    const dataset = serviceObj.find(x => x.id === typeId).formStructure;
    const [data, setdata] = useState(dataset.filter(x => x.subCatId.includes(subCatId)).map(item => { return { ...item, value: '' } }));
    var i = 0
    const handleChange = (key, value) => {
        var data_ = [...data];
        const index = data_.findIndex(x => x.id === key);
        data_[index].value = value;
        setdata(data_);
    }

    const handleCheck = (id) => {
        const sObj = data.find(x => x.id == id);
        return !_.isEmpty(sObj);
    }

    const handleRenderItem = (key) => {
        const sObj = data.find(x => x.id === key);
        if (handleCheck(key)) {
            i++;
            return (
                <div className='col-md-4' style={{ zIndex: data.length + 100 - i }}>
                    <SimpleInputSelector
                        label={sObj?.label}
                        type={sObj?.type}
                        options={sObj?.options}
                        onChange={val => handleChange(key, val)}
                        value={sObj?.value}
                        mode={sObj?.mode}
                        multiple={sObj?.multiple}
                    />
                    {submisive && sObj?.value === '' &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>{sObj?.label} is required</h4>
                    }
                </div>
            );
        }
    }

    const handleSubmission = () => {
        var requiredState = data.filter(x => !x.value);

        if (data.find(x => x.id === 'parking').value === 'Yes') {
            requiredState = data.filter(x => !x.value);
        } else {
            requiredState = data.filter(x => x.id != 'parking_area_insqft').filter(x => !x.value);
        }

        if (data.find(x => x.id === 'service_frequency')?.value === 'One Time') {
            requiredState = requiredState.filter(x => !x.id.includes('warranty'));
        }

        if (data.find(x => x.id === 'furniture_moved')?.value === 'Yes') {
            requiredState = requiredState.filter(x => !['kitchen_moved', 'boxes_moved', 'items_moved', 'boxes_bathroom_moved',
                'bathroom_moved', 'boxes_bathroom_moved_back', 'number_of_bathrooms', 'drawers_moved', 'boxes_drawers_moved', 'boxes_drawers_moved_back', 'number_of_furniture'].includes(x.id));
        }
        if (_.isEmpty(requiredState)) {
            var res = {};
            data.map(item => {
                if (item.value !== '') {
                    res[item.id] = item.value;
                }
            })
            onSubmit(res);
        } else {
            setsubmisive(true);
        }
    }



    return (
        <div className="container" style={{ minHeight: '100vh' }}>
            <div className="row mt-4">

                {handleRenderItem('heading')}
                {handleRenderItem('pest_type')}
                {handleRenderItem('residence')}
                {handleRenderItem('commercial')}
                {handleRenderItem('number_of_bedroom')}
                {handleRenderItem('service_type')}
                {handleRenderItem('expected_square_feet')}
                {handleRenderItem('number_of_stories_covered')}
                {handleRenderItem('product_kidfriendly')}
                {/* {handleRenderItem('product_kids_save')} */}
                {handleRenderItem('parking')}
                {data.find(x => x.id === 'parking').value === 'Yes' &&
                    (
                        <>
                            {handleRenderItem('parking_area_insqft')}
                        </>
                    )}
                {handleRenderItem('service_frequency')}
                {data.find(x => x.id === 'service_frequency')?.value === 'One Time' &&
                    (
                        <>
                            {handleRenderItem('warranty')}
                        </>
                    )}


                {handleRenderItem('receive_reservice')}
                {handleRenderItem('product_preference')}
                {handleRenderItem('furniture_moved')}
                {data.find(x => x.id === 'furniture_moved')?.value === 'No' &&
                    (
                        <>
                            {handleRenderItem('kitchen_moved')}
                            {handleRenderItem('boxes_moved')}
                            {handleRenderItem('items_moved')}
                            {handleRenderItem('bathroom_moved')}
                            {handleRenderItem('boxes_bathroom_moved')}
                            {handleRenderItem('boxes_bathroom_moved_back')}

                            {handleRenderItem('number_of_bathrooms')}
                            {handleRenderItem('drawers_moved')}
                            {handleRenderItem('boxes_drawers_moved')}
                            {handleRenderItem('boxes_drawers_moved_back')}
                            {handleRenderItem('number_of_furniture')}
                        </>
                    )}



            </div>
            <div style={{ zIndex: 1 }}>
                <button
                    onClick={() => handleSubmission()}
                    className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-5 float-right bold"
                    style={{ backgroundColor: '#0e415f', color: '#fff', maxWidth: '220px', fontSize: 20 }}
                    type="submit" form="login-form">Save Information<i className="icon-material-outline-arrow-right-alt" />
                </button>
            </div>
        </div>
    );
}

export default CategoryPest;