import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  minHeight: 350,
  paddingTop: 4,
};

const boxStyle = {
  background: '#f7f7f7',
  width: '100%',
  height: '200px',
  marginTop: 30
}

const RModal = ({ ...props }) => {
  const { open, onClose, type = 1, title, message, onMove, onMoveState = false } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-responsive-nedd"
    >
      <Box sx={style} className='text-center'>

        <button className='icon-line-awesome-close modal-cross-btn'
          style={{
            position: 'absolute',
            top: 5,
            right: 10,
            background: '#3b73a9',
            padding: 10,
            borderRadius: 100,
            color: '#fff'
          }}

          onClick={onClose}
        >

        </button>
        <img
          src={
            type === 1 ? require('../assets/images_/success.png').default
              :
              require('../assets/images_/failure.png').default
          }
          style={{ width: 100 }}
          className='m-auto black'
        />
        <div
          style={boxStyle}
        >

          <Typography
            style={{ paddingTop: 20 }}
          >
            {title}
          </Typography>
          <Typography
          >
            {message}
          </Typography>
          <button
            onClick={!onMoveState ? onClose : onMove}
            className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-5"
            style={{ backgroundColor: '#1fc7c7', color: '#fff', maxWidth: '40%', margin: 'auto' }}
            type="type" form="login-form">Resume</button>
        </div>
      </Box>
    </Modal>
  );
};

export default RModal;