import { List, ListItem, ListItemText } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { decrypt } from '../../utils/function';
import { Email, Instagram, Map, Phone } from '@mui/icons-material';

const HashKey = {
    landing: 'main',
    my_leadSeller: 'app/seller/mylead',
    my_leadBuyer: 'app/buyer-lead',
    categoryBuyer: 'app/category',
    category: 'category',
    contact_us: 'contact-us',
    contact_usSeller: 'app/seller/contact-us',
    contact_usBuyer: 'app/contact-us',
    privacy_policy: 'privacy-policy',
    privacy_policySeller: 'app/seller/privacy-policy',
    privacy_policyBuyer: 'app/privacy-policy',
    terms_condition: 'terms',
    terms_conditionSeller: 'app/seller/terms',
    terms_conditionBuyer: 'app/terms',
    help_center: 'help',
    help_centerSeller: 'app/seller/help',
    help_centerBuye: 'app/help',
    lead: 'lead',
    leadSeller: 'app/seller/lead'
}

// sg-0e2208fb784fdfb65

const Footer = ({ ...props }) => {
    var role = localStorage.getItem('role');
    if (role) {
        role = decrypt(process.env.REACT_APP_SECRET_KEY, role);
    }

    const handlePageChange = (key) => {
        var uKey = role ? key + role : key;
        props.history.push('/' + HashKey[uKey]);
    }

    return <footer className="bg-black">
        <div className="mx-auto w-full max-w-screen-2xl p-4 py-6 lg:py-8">
            <div className="md:flex md:justify-between">
                <div className="mb-6 md:mb-0">
                    <a href="/" className="flex flex-col items-start">
                        <img
                            src={require('../../assets/icons/logo.png').default}
                            className="h-32 mr-3"
                            alt="Bijlink Logo"
                        />
                        {/* <span className="self-center text-2xl font-semibold whitespace-nowrap text-white ">
                            Bijlink Inc.
                        </span> */}
                    </a>
                </div>
                <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                            Resources
                        </h2>

                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                            {role && <li className="mb-4">
                                <a href="#" onClick={() => handlePageChange('my_lead')} className="hover:underline">
                                    My Listing
                                </a>
                            </li>}
                            {role === "Buyer" ?
                                <li className="mb-4">
                                    <a href="#" onClick={() => handlePageChange('category')} className="hover:underline">
                                        Category
                                    </a>
                                </li>
                                :
                                <li className="mb-4">
                                    <a href="#" onClick={() => handlePageChange('lead')} className="hover:underline">
                                        Listing
                                    </a>
                                </li>

                            }
                            <li className="mb-4">
                                <a href="#" onClick={() => handlePageChange('help_center')} className="hover:underline">
                                    Help Section
                                </a>
                            </li>
                            <li className="mb-4">
                                <a href="#" onClick={() => handlePageChange('contact_us')} className="hover:underline">
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                            Legal
                        </h2>

                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                            <li className="mb-4" onClick={() => handlePageChange('privacy_policy')}>
                                <a href="" className="hover:underline">
                                    Privacy Policy
                                </a>
                            </li>
                            <li onClick={() => handlePageChange('terms_condition')}>
                                <a href="#" className="hover:underline">
                                    Terms of Use
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                            Contact at
                        </h2>

                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                            <li className="mb-4 flex flex-row" >
                                <Phone className='mr-2' />
                                +1 (404)-551-7011
                            </li>

                            <li className="mb-4 flex flex-row" >
                                <Email className='mr-2' />
                                admin@bijlink.com
                            </li>


                            <li className="mb-4 flex flex-row" >
                                <Map className='mr-2' />
                                Roswell, Gerogia 30076
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <div className="sm:flex sm:items-center sm:justify-between">
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                    © 2023{" "}
                    <a href="/" className="hover:underline">
                        Bijlink
                    </a>
                    . All Rights Reserved.
                </span>
                <div className="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
                    <a
                        href="https://www.facebook.com/profile.php?id=100093697082456"
                        className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                    >
                        <svg
                            className="w-4 h-4"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 8 19"
                        >
                            <path
                                fillRule="evenodd"
                                d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="sr-only">Facebook page</span>
                    </a>
                    <a
                        href="https://twitter.com/Bijlink"
                        className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                    >
                        <svg
                            className="w-4 h-4"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 17"
                        >
                            <path
                                fillRule="evenodd"
                                d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="sr-only">Twitter page</span>
                    </a>
                    <a
                        href="https://www.instagram.com/bijlink.inc/"
                        className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                    >
                        <Instagram className='w-4 mt-[-10px]' />
                        <span className="sr-only">Instagram account</span>
                    </a>
                </div>
            </div>
        </div>
    </footer>


    return (
        <div className='min-h-[200px] bg-black w-full grid grid-cols-6 gap-4 overflow-hidden p-20'>
            <img src={require('../../assets/icons/logo.png').default} className='w-20'
                onClick={() => handlePageChange('landing')}
            />
            <div className='col-span-2'>
                <h3 className='text-white mt-2 bold'>Pages</h3>

                {role &&
                    <h4 className='text-white mt-4 bold text-lg'
                        onClick={() => handlePageChange('my_lead')}
                        style={{ cursor: 'pointer' }}>My Listing</h4>
                }
                {role === "Buyer" ?
                    <h4 className='text-white mt-3 bold text-lg'
                        onClick={() => handlePageChange('category')}
                        style={{ cursor: 'pointer' }}>Category</h4>
                    :
                    <h4 className='bg-yellow-50 p-2 w-[150px] rounded text-lg'
                        onClick={() => handlePageChange('lead')}
                        style={{ cursor: 'pointer' }}>Listing</h4>

                }
                <h4 className='bg-yellow-50 p-2 w-[150px] rounded text-lg'
                    onClick={() => handlePageChange('contact_us')}
                    style={{ cursor: 'pointer' }}>Contact Us</h4>
                <h4 className='bg-yellow-50 p-2 w-[150px] rounded text-lg'
                    onClick={() => handlePageChange('help_center')}
                    style={{ cursor: 'pointer' }}>Help Center</h4>
            </div>
        </div>
    );

    return (
        <div className='min-h-[200px] bg-black w-full flex flex-row overflow-hidden p-10'>
            <div className='col-md-1'>
                <img src={require('../../assets/icons/logo.png').default} className='w-20'
                    onClick={() => handlePageChange('landing')}
                />

            </div>

            <div className='col-md-4 offset-md-2 mt-4'>
                <div className='grid grid-cols-2 gap-4'>
                    <div>
                        <h3 className='text-white mt-2 bold'>Pages</h3>
                        {role &&
                            <h4 className='text-white mt-4 bold text-sm'
                                onClick={() => handlePageChange('my_lead')}
                                style={{ cursor: 'pointer' }}>- My Listing</h4>
                        }
                        {role === "Buyer" ?
                            <h4 className='text-white mt-3 bold text-sm'
                                onClick={() => handlePageChange('category')}
                                style={{ cursor: 'pointer' }}>- Category</h4>
                            :
                            <h4 className='text-white mt-3 bold text-sm'
                                onClick={() => handlePageChange('lead')}
                                style={{ cursor: 'pointer' }}>- Listing</h4>

                        }
                        <h4 className='text-white mt-3 bold text-sm'
                            onClick={() => handlePageChange('contact_us')}
                            style={{ cursor: 'pointer' }}>- Contact Us</h4>
                        <h4 className='text-white mt-3 bold text-sm'
                            onClick={() => handlePageChange('help_center')}
                            style={{ cursor: 'pointer' }}>- Help Center</h4>
                    </div>

                    <div>
                        <h3 className='text-white mt-2 bold underline underline-offset-4'>About Us</h3>

                        <h4 className='text-white mt-4 bold text-sm' style={{ cursor: 'pointer' }}>- Resources</h4>
                        <h4 className='text-white mt-3 bold text-sm' style={{ cursor: 'pointer' }}>- Blogs</h4>
                        <h4 className='text-white mt-3 bold text-sm'
                            onClick={() => handlePageChange('privacy_policy')}
                            style={{ cursor: 'pointer' }}>- Privacy Policy</h4>
                        <h4 className='text-white mt-3 bold text-sm'
                            onClick={() => handlePageChange('terms_condition')}
                            style={{ cursor: 'pointer' }}>- Terms of Use</h4>
                    </div>
                </div>
            </div>


            <div className='col-md-2 mt-4'>
                <h3 className='text-white mt-2 bold underline underline-offset-4'>Contact Us</h3>
                <h4 className='text-white mt-4 bold text-sm'>Phone: +1 (404)-551-7011.</h4>
                <h4 className='text-white mt-3 bold text-sm'>Email: admin@bijlink.com</h4>
                <h4 className='text-white mt-3 bold text-sm'>Roswell, Gerogia 30076</h4>
                <div className='flex flex-row items-center'>
                    <a href="https://www.facebook.com/profile.php?id=100093697082456" className='icon icon-brand-facebook text-white'></a>
                    <a href="https://www.instagram.com/bijlink.inc/" className='icon icon-brand-instagram ml-3 text-white'></a>
                    <a href="https://twitter.com/Bijlink" className='icon icon-brand-twitter ml-3 text-white'></a>
                </div>

            </div>
            <h4 className='mt-2 text-center text-white text-sm w-100'>@2023 Bijlink Inc All rights reserved</h4>
        </div>
    );
}

export default Footer;



