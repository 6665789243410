import { Alert, CircularProgress } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import MaskInput from 'react-maskinput/lib';
import { _call } from '../../utils/auth';

const imageURL = process.env.REACT_APP_IMAGE_URL;


//im2.jpeg

const ProfileBasicInfo = ({ userBasicInfo, handleSubmit, userId }) => {
  const [userInfo, setuserInfo] = useState(userBasicInfo);

  const [profilepicture, setprofilepicture] = useState('');
  const [imageuploaded, setimageuploaded] = useState(false);
  const [validationstatus, setvalidationstatus] = useState({});
  const [agelimitation, setagelimitation] = useState(true);

  useEffect(() => {
    if (userInfo.profile_img) {
      setprofilepicture(`${imageURL}${userInfo.profile_img}`);
    }
    if (moment().diff(userInfo.dateOfBirth, 'years') >= 18) {
      setagelimitation(true)
    } else {
      setagelimitation(false);
    }
    handleCheckApplicationStatus();
  }, []);

  const handleCheckApplicationStatus = async () => {
    try {
      const getapplicationstatus = await _call('validate-seller-info', 'POST', {
        validation: 1
      });
      setvalidationstatus(getapplicationstatus.data.result.complete);
    } catch (err) {
    }
  }


  const handleChange = (key, value) => {
    var userInfoClone = { ...userInfo };
    userInfoClone[key] = value;
    setuserInfo(userInfoClone);
  }

  const handleUploadProfilePicture = async (e) => {
    handleChange('profile_img', e);
    setprofilepicture(URL.createObjectURL(e));
    setimageuploaded(true);

  }

  const handleOnChange = (place) => {
    var userInfoClone = { ...userInfo };
    userInfoClone.address = place.formatted_address;
    var city = '';
    place.address_components.map(item => {
      if (item.types[0]?.includes('administrative_area_level_1')) {
        userInfoClone.state = item.long_name;
      }
      if (item.types[0]?.includes('locality')) {
        userInfoClone.city = item.long_name
      }
      if (item.types[0] === 'postal_code') {
        userInfoClone.postcode = item.long_name;
      }
      if (item.types[0] === 'country') {
        userInfoClone.country = item.long_name;
      }
    });

    setuserInfo({ ...userInfoClone })

  }


  return (
    <div className="container">
      {validationstatus &&
        <Alert severity={validationstatus.basic ? "success" : "error"} className='mb-3'>{validationstatus.basic ? "Your information is already added" : "Please provide all the required information"}</Alert>
      }
      <div className="row pt-2 pl-4">

        <div className="col-md-6 ">
          <img
            src={profilepicture}
            className="image-box mt-2 rounded-full"
            style={{ border: '2px solid yellow', borderRadius: 100 }}
          />
          {/* <input
            type='file'
            className="mt-4 input-style"
            onChange={e => handleUploadProfilePicture(e.target.files[0])}

          /> */}
          <h4 className='mt-4 mb-1'>First Name</h4>
          {/* <input
            className="mt-1 input-style"
            value={userInfo.firstName}
            disabled
            onChange={e => handleChange('firstName', e.target.value)}
          /> */}
          <div className="mt-1 input-style" style={{ backgroundColor: '#efefef' }}>
            <h4 className='mt-1 mb-1'>{userInfo.firstName}</h4>
          </div>
          <h4 className='mt-2 mb-1'>Last Name</h4>
          {/* <input
            className="mt-1 input-style"
            value={userInfo.lastName}
            disabled
            onChange={e => handleChange('lastName', e.target.value)}
          /> */}
          <div className="mt-1 input-style" style={{ backgroundColor: '#efefef' }}>
            <h4 className='mt-1 mb-1'>{userInfo.lastName}</h4>
          </div>
          <h4 className='mt-2 mb-1'>Social Security Number</h4>
          <MaskInput alwaysShowMask maskChar=" " mask="000-00-0000" size={10}
            value={userInfo.social_security_number}
            onChange={e => handleChange('social_security_number', e.target.value)}
            className="mt-2 input-style" />
          <h4 className='mt-2 mb-1'>Date of Birth</h4>
          <DatePicker
            className="mt-1 input-style"
            selected={Date.parse(userInfo.dateOfBirth)}
            onChange={e => {
              if (moment().diff(e, 'years') >= 18) {
                handleChange('dateOfBirth', e);
                setagelimitation(true)
              } else {
                setagelimitation(false)
              }
            }} />
          {!agelimitation &&
            <h4 className='mb-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Age should be greater than 18</h4>
          }
          <h4 className='mt-1 mb-1'>Gender</h4>
          <select
            className="mt-1 input-style"
            value={userInfo.gender}
            onChange={e => handleChange('gender', e.target.value)}
          >
            <option>Select any option</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        <div className="col-md-6 pt-5">
          <h4 className='mt-2 mb-1'>Address</h4>
          <ReactGoogleAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            className="mt-1 input-style"
            defaultValue={userInfo.address}
            onPlaceSelected={async (place) => {
              handleOnChange(place);
            }}
            options={{ fields: ["address_components", "geometry", "formatted_address"], types: ["address"] }}
          />

          <h4 className='mt-2 mb-1'>City</h4>
          <input
            className="mt-1 input-style"
            value={userInfo.city}
            onChange={e => handleChange('city', e.target.value)}
          />
          <h4 className='mt-2 mb-1'>Country</h4>
          <input
            className="mt-1 input-style"
            value={userInfo.country}
            onChange={e => handleChange('country', e.target.value)}
          />
          <h4 className='mt-2 mb-1'>State</h4>
          <input
            className="mt-1 input-style"
            value={userInfo.state}
            onChange={e => handleChange('state', e.target.value)}
          />
          <h4 className='mt-2 mb-1'>Post code</h4>
          <input
            className="mt-1 input-style"
            value={userInfo.postcode}
            onChange={e => handleChange('postcode', e.target.value)}
          />
          <h4 className='mt-2 mb-1'>About yourself.</h4>
          <textarea
            className="mt-1 input-style"
            value={userInfo.about}
            onChange={e => handleChange('about', e.target.value)}
          ></textarea>

        </div>

      </div>
      <div>
        <button
          onClick={() => {
            handleSubmit({ ...userInfo, newimage: imageuploaded });
            setimageuploaded(false);
          }}
          className='bg-yellow-500 float-right w-48 rounded h-12 mt-4 text-white text-md'
          type="button">
          Submit
        </button>

      </div>
    </div>
  );
}

export default ProfileBasicInfo;