import React, { useState } from 'react';
import _ from 'lodash'
import { Alert, Rating } from '@mui/material';
import moment from 'moment-timezone';
import { QnaComp } from '.';
import { imageURL } from '../utils/constant';
import { handleDays } from '../utils/function';


const reviewsStandard = [
  { id: 0, name: 'Excellent', value: 5 },
  { id: 1, name: 'Good', value: 4 },
  { id: 3, name: 'Fair', value: 3 },
  { id: 2, name: 'Poor', value: 2 },
]

const BidRequest = ({ ...props }) => {
  const { imageClick,
    imagesrc,
    ratings,
    description,
    price,
    duration,
    name,
    selection,
    // amountEarned,
    // totalOrders,
    // completedOrders,
    // progressOrders,
    sellerInfo,
    onConfirm,
    userType,
    duration2,
    durationType,
    onDecline,
    qnadataset,
    onReply,
    onQuestion,
    useraccess,
    expiry,
    extraInfo,
    categoryName,
    declinereason,
    cancelled_by,
    status,
    orderInformation,
    history,
    data
  } = props;
  const [onshow, setonshow] = useState(false);
  const handleQuestionSellerRating = (performance) => {
    var ratings = 0;
    if (performance) {
      performance?.map(item => {
        if (item.performance) {
          ratings += reviewsStandard.find(x => x.name === item.performance)?.value;
        }
      })
      return ratings;
    }
    return 1;
  }

  console.log(data)

  const extraInfoSection = JSON.parse(data?.extra_info)
  return (
    <div className="job-listing w-100 mt-4">
      <div className="job-listing-details">
        <div className="job-listing-description p-0 m-0">
          {(status === 'Cancel' || status == 'Cancel_Request') ? <Alert severity="error" className='mt-0 w-100'>Cancelled by {cancelled_by}</Alert>
            : selection === 2 && status == 'Accept' && <Alert severity="success" className='mt-0 w-100'>Accepted the request</Alert>
          }
          {status === "Decline" && <Alert severity="error" className='mt-0  w-100'>Declined. Reason: ({declinereason}) </Alert>}
          <div className='row m-0 mt-2' style={{ alignItems: 'center', borderBottom: '2px solid #efefef', paddingBottom: 10 }}>
            <div style={{ flex: 1 }}>
              <h4 className='bold' style={{ fontSize: 34 }}>${price} </h4>
              <span className='text-slate-500 text-lg'>Vehicle: {extraInfoSection?.vehicle}</span>
              <p style={{
                color: '#afafaf',
                fontSize: 12,
                lineHeight: '16px',
                letterSpacing: 1,
                marginTop: 2
              }}>
                {/* ({durationType}) */}
                Posted on {moment(data?.created_on).tz('America/New_York')?.format('MM-DD-YYYY')}
                {duration && `${'Start on:' + moment(duration).tz('America/New_York')?.format('MM-DD-YYYY')}`}
                {durationType === 'Between the dates' && ` End on: ${moment(duration2).tz('America/New_York')?.format('MM-DD-YYYY')}`}
                &nbsp; Expired on {moment(expiry).tz('America/New_York')?.format('MM-DD-YYYY')}
              </p>
              {/* {categoryName.includes('Shipping') &&
                <p style={{
                  color: '#afafaf',
                  fontSize: 12,
                  lineHeight: '16px',
                  letterSpacing: 1,
                  marginTop: 2
                }}>
                  Accessories: {extraInfo?.accessories}
                  {durationType === 'Between the dates' && ` End on: ${moment(duration2)?.format('MM-DD-YYYY')}`}
                  &nbsp; Vehicle Type {extraInfo?.vehicle}
                </p>
              } */}
              <p style={{
                color: '#afafaf',
                fontSize: 12,
                lineHeight: '16px',
                letterSpacing: 1,
                marginTop: 2
              }}>
                Pick up Date: {moment(extraInfo?.pickupdate).tz('America/New_York').format('MM-DD-YYYY')}

                &nbsp; Drop off Date: {moment((extraInfo?.dropoffdate)).tz('America/New_York').format('MM-DD-YYYY')}

              </p>
            </div>
            {/* require('../assets/images_/user1.jpeg').default */}
            <img
              onClick={imageClick}
              src={imagesrc}
              style={{ width: 40, height: 40, borderRadius: 0 }}
            />
            <div className='mt-2 ml-2'>
              <h4 className='bold'>{name}</h4>
              <Rating
                disabled
                value={sellerInfo?.ratings}
              />
            </div>
            {userType === 'Buyer' &&
              <div className="job-listing-footer p-1 w-100 mt-2">
                <ul>
                  <li style={{ fontSize: 10, padding: 0 }}>

                    <span>Amount Earned</span><h4 style={{ fontSize: 14 }}>${sellerInfo?.amountEarned}</h4>
                  </li>
                  {/* <li style={{ fontSize: 10, padding: 0 }}>
                    <span>Total Orders</span>
                    <h4 style={{ fontSize: 14 }}>{sellerInfo?.total}</h4></li> */}
                  <li style={{ fontSize: 10, padding: 0 }}>
                    <span>Completed Orders</span>
                    <h4 style={{ fontSize: 14 }}>{sellerInfo?.completed}</h4></li>

                  <li style={{ fontSize: 10, padding: 0 }}>
                    <span>In Process</span>
                    <h4 style={{ fontSize: 14 }}>{sellerInfo?.process}</h4></li>
                  <li style={{ fontSize: 10, padding: 0 }}>
                    <span>Cancelled Orders</span>
                    <h4 style={{ fontSize: 14 }}>{sellerInfo?.cancelled}</h4></li>
                </ul>
              </div>
            }
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <p style={{
                color: '#afafaf',
                fontSize: 14,
                lineHeight: '16px',
                letterSpacing: 1
              }}>
                {description}
              </p>

              {userType === 'Buyer' && status != 'Cancel' && status != 'Cancel_Request' && status != 'Expiry' && status != 'Decline' && selection === 1 &&
                <div className="row w-100 mt-4">
                  <button className="button full-width button-sliding-icon ripple-effect margin-top-0 p-2"
                    style={{ backgroundColor: '#34BE82', color: '#f7f7f7', width: 150, margin: 10, borderRadius: 0 }}
                    onClick={onConfirm}
                    // onClick={() => handleConfirmOrder(1, item)}
                    type="submit" form="login-form">Accept <i className="icon-material-outline-arrow-right-alt" /></button>
                  <button className="button full-width button-sliding-icon ripple-effect margin-top-0 p-2"
                    style={{ backgroundColor: '#fff', color: '#646464', width: 150, margin: 10, borderRadius: 0 }}
                    // onClick={() => handleConfirmOrder(0, item)}
                    onClick={onDecline}
                    type="submit" form="login-form">Reject <i className="icon-material-outline-arrow-right-alt" /></button>
                </div>
              }


            </div>
            <div className='row m-0  mt-3'>

              {useraccess && _.isEmpty(qnadataset) &&
                <button className="button full-width  margin-top-0 p-2"
                  style={{ backgroundColor: '#34BE82', color: '#fff', width: 40, height: 40, margin: 10, borderRadius: 100, display: 'flex' }}
                  onClick={onQuestion}
                  type="submit" form="login-form"><i className="icon-material-outline-add" style={{ fontSize: 30, marginLeft: -3, marginTop: 10, backgroundColor: 'green' }} /></button>
              }
              {useraccess &&
                <button className="button full-width button-sliding-icon ripple-effect margin-top-0 p-2"
                  style={{ backgroundColor: '#fff', color: '#646464', width: 150, margin: 10, borderRadius: 0 }}
                  // onClick={() => handleConfirmOrder(0, item)}
                  onClick={() => setonshow(!onshow)}
                  type="submit" form="login-form">{!onshow ? 'Show' : 'Hide'} Q/A <i className="icon-material-outline-arrow-right-alt" /></button>
              }
            </div>
            {onshow &&
              <div className='col-md-12'>


                {!_.isEmpty(qnadataset) &&
                  qnadataset.bidsdata.filter(x => x.userId === data.userId).map(item => {
                    return (
                      <QnaComp
                        name={item?.first_name}
                        history={history}
                        data={item}
                        profileImg={imageURL + item?.profile_img}
                        // ratings={handleQuestionSellerRating(item?.performance)}
                        ratings={item?.performance}
                        userType={item?.user_type}
                        answers={item?.answers}
                        useraccess={useraccess}
                        time={handleDays(item?.created_on)}
                        question={item?.question}
                        onReply={() => onReply(item)}
                      />
                    );
                  })

                }
              </div>
            }
          </div>


        </div>
      </div>
    </div>

  );
};

export default BidRequest;