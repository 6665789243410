import { InformationObj } from "./constant";
import _ from 'lodash';
import moment from "moment";
import crypto from "crypto";

const algorithm = 'aes-256-cbc';
const encryptionKey = crypto.randomBytes(32);
const iv = crypto.randomBytes(16);

export function encryptWithSalt(object, salt) {
  const key = crypto.pbkdf2Sync(salt, 'salt', 100000, 32, 'sha512');
  const cipher = crypto.createCipheriv(algorithm, key, iv);
  let encrypted = cipher.update(JSON.stringify(object), 'utf8', 'hex');
  encrypted += cipher.final('hex');
  return encrypted;
}

export function decryptWithSalt(encrypted, salt) {
  const key = crypto.scryptSync(salt, 'salt', 32);
  const decipher = crypto.createDecipheriv(algorithm, key, iv);
  let decrypted = decipher.update(encrypted, 'hex', 'utf8');
  decrypted += decipher.final('utf8');
  return JSON.parse(decrypted);
}

export const crypt = (salt, text) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
};

export const sessionMaintainer = () => new Promise(async (resolve, reject) => {
  const session = await localStorage.getItem('session');

  if (session) {

    const sessionTime = moment(session).add(2, 'day');
    const compareTime = moment();
    if (sessionTime < compareTime) {
      resolve('log-out')
    } else {
      resolve('on-going')
    }

  } else {
    localStorage.setItem('session', moment().toString())
    resolve('initiated')
  }

});

export const decrypt = (salt, encoded) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user = localStorage.getItem('token')

    if (user != null) {
      return {
        userid: (localStorage.getItem('userId')),
        token: (localStorage.getItem('token')),
        role: (localStorage.getItem('role') ? decrypt(process.env.REACT_APP_SECRET_KEY, localStorage.getItem('role')) : ''),
      }
    } else {
      return null;
    }

  } catch (error) {
    user = null;
  }
  return user;
};

export const getFieldName = (categoryName, data) => {
  var arr = [];

  _.reduce(data, function (result, value, key) {
    const data = {
      key: InformationObj[categoryName][key],
      value: value
    }
    arr.push(data);
    return arr;
  }, {});

  return arr;
}

export const getLocationFieldName = (dataObj, dataset) => {
  var arr = [];

  _.reduce(dataset, function (result, value, key) {
    const data = {
      key: dataObj[key],
      value: value
    }
    arr.push(data);
    return arr;
  }, {});

  return arr;
}

export const handleDays = (date) => {
  // var now = moment()
  // var days = now.diff(date, "days");
  // var hours = now.diff(date, "hours");
  // return hours <= 1 ? 'Just now' : days <= 0 ? hours + ' hour(s) ago' : days + ' day(s) ago'
  // weeks = now.diff(date, "weeks")
  return moment(date).fromNow() == 'Invalid date' ? 'Just Now' : moment(date).fromNow();
}

export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function genPass() {
  //https://sita.app/password-generator
  let stringInclude = '';
  stringInclude += "!\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~";
  stringInclude += '0123456789';
  stringInclude += 'abcdefghijklmnopqrstuvwxyz';
  stringInclude += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var password = '';
  for (let i = 0; i < 40; i++) {
    password += stringInclude.charAt(Math.floor(Math.random() * stringInclude.length));
  }
  return password;
}