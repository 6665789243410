import React,{useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import { InputSelector, SimpleInputSelector } from '..';
import { acSize, airConditionType, booleanAns, buildingStories, businessType, carCondition, carTruckTypes, constructionTypeOfGoods, constructionTypeOfService, constructionTypesOfItems, gasType, handlingUnit, installationLocation, motorCycleTypes, officeCarType, officeShipHauler, officeShipType, otherVehicles, pulocationType, serviceObj, timeFrameObj, totalUnitsToInstallType, typeOfLocation, unitToInstalled } from '../../utils/constant';
import { Form } from 'react-bootstrap';
import { Checkbox } from '@mui/material';

const CategoryAppliance = ({userBasicInfo, onSubmit, subCatId,typeId}) => {
    const [submisive, setsubmisive] = useState(false);
    const [checked, setchecked] = useState(true);
    const dataset = serviceObj.find(x => x.id === typeId).formStructure;
    const [data, setdata] = useState(dataset.filter(x => x.subCatId.includes(subCatId)).map(item => { return {...item,value:''}}));
    var i = 0;
    const handleChange = (key, value) =>{
        var data_ = [...data];
        const index = data_.findIndex(x => x.id === key);
        data_[index].value = value;
        setdata(data_);
    }   

    const handleCheck = (id) => {
        const sObj = data.find(x => x.id == id);
        return !_.isEmpty(sObj);
    }

    const handleRenderItem = (key) => {
        const sObj = data.find(x => x.id === key);
        if(handleCheck(key)){
            i++;
            return (
                <div className='col-md-4' style={{zIndex:data.length + 100 - i}}>
                    <SimpleInputSelector
                    label={sObj?.label}
                    type={sObj?.type}
                    options={sObj?.options}
                    onChange={val => handleChange(key,val)}
                    value={sObj?.value}
                    mode={sObj?.mode}
                    multiple={sObj?.multiple}
                />
                {submisive && sObj?.value === '' &&
                  <h4 className='mb-2 mt-2' style={{color:'red',fontSize:14,marginTop:-10}}>{sObj?.label} is required</h4>
                }
                </div>
                );
        }
    }

    
    const handleSubmission = () => {
        var requiredState = data.filter(x => !x.value);
        if(data.find(x => x.id === 'brand_new')?.value === 'Yes'){
            requiredState = requiredState.filter(x => !['old_unit','old_unit_remove'].includes(x.id));
          
            if(data.find(x => x.id === 'demolishing').value === 'No'){
                requiredState = requiredState.filter(x => x.id !== 'demolishing_date');
            }
        }else{
            requiredState = requiredState.filter(x => !['demolishing','demolishing_date'].includes(x.id));
        }
        
      
        if(_.isEmpty(requiredState)){
            var res = {};
            data.map(item => {
                if(item.value !== ''){
                    res[item.id] = item.value;
                }
            })
            onSubmit(res);
        }else{
            setsubmisive(true);
        }
    }

    
  
    return(
        <div className="container" style={{minHeight:'100vh'}}>
         <div className="row mt-4">
       
         {handleRenderItem('heading')}
         {handleRenderItem('property_type')}
         {handleRenderItem('location_type')}
         {handleRenderItem('building_stories')}
         {handleRenderItem('meter_brand')}
         {handleRenderItem('meter_model')}
         {handleRenderItem('service_type')}
         {handleRenderItem('number_of_unit')}
         {handleRenderItem('brand_new')}
         {data.find(x => x.id === 'brand_new')?.value === 'Yes' ?
            (
               <>
                 {handleRenderItem('demolishing')}
                 {data.find(x => x.id === 'demolishing')?.value === 'Yes' && handleRenderItem('demolishing_date')}
               </>
            ) : data.find(x => x.id === 'brand_new')?.value === 'No' &&
            (
                <>
                 {handleRenderItem('old_unit')}
                {handleRenderItem('old_unit_remove')}
                </>
            )
            }
        
        
         {handleRenderItem('move_haul_old_unit')}
         {handleRenderItem('move_haul_expect_money_customer')}
         {handleRenderItem('move_haul_expect_money_service_provider')}
         <div className="row m-0 mt-3" style={{alignItems:'center'}}>
              <Checkbox
              value={checked}
              onChange={() => setchecked(!checked)}
              />
              <p style={{margin:0, maxWidth:'90%',fontSize:10, lineHeight:'10px'}}>
              You agree and understand that you are not selling your old unit to the service provider and cannot charge them any price for removing or hauling your unit if you chose that option during listing
                You agree and understand that selecting the option to have your old unit removed and hauled by service provider will incur additional charges which will be included in the quote provided to you by the service provider
              </p>
              </div>
            </div>
            <div style={{zIndex:1}}>
            <button 
                disabled={checked}
               onClick={() => handleSubmission()}
               className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-5 float-right bold" 
               style={{backgroundColor:'#0e415f',color:'#fff',maxWidth:'220px',fontSize:20}}
                    type="submit" form="login-form">Save Information<i className="icon-material-outline-arrow-right-alt" />
                  </button>
            </div>
    </div>
    );
}

export default CategoryAppliance;