import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { getFieldName } from '../utils/function';
import { Map, GoogleApiWrapper, Marker, InfoWindow, Circle, } from 'google-maps-react';
import Geocode from "react-geocode";
import { googleAPIKey } from '../utils/constant';
import { CircularProgress } from '@mui/material';

const mapStyles = {
    width: '100%',
    height: '100%',
    position:'relative'
  };



const MapSection  = ({...props}) => {
   const {destination, position} = props;
   const [loader, setloader] = useState(false);

    if(loader) return <CircularProgress/>
    window.google = window.google || {};
    return (
        <div
        style={{
            height:200
        }}
        >
        <Map
        google={window?.google}
        zoom={14}
        style={mapStyles}
        initialCenter={
          {
            lat: position.lat,
            lng: position.lng
          }
        }
        className={'map'}
      >
          {/* <Marker
          name={"Australia"}
          position={{lat:position.lat,lng:position.lng}}
          /> */}

<Circle
        radius={1200}
        center={{lat:position.lat,lng:position.lng}}
        strokeColor='transparent'
        strokeOpacity={0}
        strokeWeight={5}
        fillColor='#FF0000'
        fillOpacity={0.2}
      />

          {/* <InfoWindow
          marker={{lat:position.lat,lng:position.lng}}
          visible={true}>
            <div>
              <h1>Australia</h1>
            </div>
        </InfoWindow> */}
          </Map>
       
        </div>
       
    );
};

export default GoogleApiWrapper({
    apiKey: googleAPIKey
  })(MapSection);