import { ADDUSER, REMOVEUSER } from "../action/type";



const UserReducer = (state = [], action) => {
    switch (action.type) {
        case ADDUSER:
            const { payload } = action;
            if (payload) {
                return {...state, ...payload};
              }
            return state;
        case REMOVEUSER:
            return [];
       
    }
    return state
}
export default UserReducer;