import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import { InputSelector, SimpleInputSelector } from '..';
import { acSize, airConditionType, booleanAns, buildingStories, businessType, carCondition, carTruckTypes, constructionTypeOfGoods, constructionTypeOfService, constructionTypesOfItems, gasType, handlingUnit, installationLocation, motorCycleTypes, officeCarType, officeShipHauler, officeShipType, otherVehicles, pulocationType, serviceObj, timeFrameObj, totalUnitsToInstallType, typeOfLocation, unitToInstalled } from '../../utils/constant';

const CategoryClean = ({ userBasicInfo, onSubmit, subCatId, typeId }) => {
    const [submisive, setsubmisive] = useState(false);
    const dataset = serviceObj.find(x => x.id === typeId).formStructure;
    const [data, setdata] = useState(dataset.filter(x => x.subCatId.includes(subCatId)).map(item => { return { ...item, value: '' } }));
    var i = 0;
    const handleChange = (key, value) => {
        var data_ = [...data];
        const index = data_.findIndex(x => x.id === key);
        data_[index].value = value;
        setdata(data_);
    }

    const handleCheck = (id) => {
        const sObj = data.find(x => x.id == id);
        return !_.isEmpty(sObj);
    }

    const handleRenderItem = (key) => {
        const sObj = data.find(x => x.id === key);
        if (handleCheck(key)) {
            i++;
            return (
                <div className='col-md-4' style={{ zIndex: data.length - i }}>
                    <SimpleInputSelector
                        label={sObj?.label}
                        type={sObj?.type}
                        options={sObj?.options}
                        onChange={val => handleChange(key, val)}
                        value={sObj?.value}
                        mode={sObj?.mode}
                        multiple={sObj?.multiple}
                    />
                    {submisive && sObj?.value === '' &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>{sObj?.label} is required</h4>
                    }
                </div>
            );
        }
    }

    const handleSubmission = () => {
        var requiredState = data.filter(x => !x.value);

        if (data.find(x => x.id === 'area_rugs')?.value === 'Yes') {
            requiredState = requiredState.filter(x => !x.id.includes('number_of_rugs'));

        } else {
            requiredState = requiredState.filter(x => !x.id.includes('rug_length', 'rug_width'));
        }

        if (_.isEmpty(requiredState)) {
            var res = {};
            data.map(item => {
                if (item.value !== '') {
                    res[item.id] = item.value;
                }
            })
            onSubmit(res);
        } else {
            setsubmisive(true);
        }
    }



    return (
        <div className="container" style={{ minHeight: '100vh' }}>
            <div className="row mt-4" >

                {handleRenderItem('heading')}
                {handleRenderItem('service_type')}
                {handleRenderItem('number_of_tanks')}
                {handleRenderItem('service_location')}
                {handleRenderItem('pool_size')}
                {handleRenderItem('total_square_feet')}
                {handleRenderItem('space')}
                {handleRenderItem('upholstry_type')}
                {handleRenderItem('size_type')}
                {handleRenderItem('number_pieces_furniture')}
                {handleRenderItem('service_for')}
                {handleRenderItem('area_rugs')}
                {data.find(x => x.id === 'area_rugs')?.value === 'Yes' ?
                    (
                        <>
                            {handleRenderItem('rug_length')}
                            {handleRenderItem('rug_width')}
                        </>
                    ) : data.find(x => x.id === 'area_rugs')?.value === 'No' &&
                    (
                        <>
                            {handleRenderItem('number_of_rugs')}
                        </>
                    )
                }



                {handleRenderItem('service_tools_material')}
                {handleRenderItem('service_tools_material_labour')}
                {handleRenderItem('tools_material_provided')}
                {handleRenderItem('furniture_moved')}
                {handleRenderItem('tools_provided')}
                {handleRenderItem('recommended_product')}
                {handleRenderItem('number_of_bathroom')}
                {handleRenderItem('clean_type_basic')}
                {handleRenderItem('clean_type_deep')}

                {/* {handleRenderItem('half_bathroom')} */}
                {handleRenderItem('number_of_balconies')}
                {/* {handleRenderItem('total_sf')} */}
                {handleRenderItem('floor_type')}
                {handleRenderItem('cleaning_included')}
                {handleRenderItem('emergency')}

            </div>
            <div>
                <div className='mt-5 pt-5' />
                <button
                    onClick={() => handleSubmission()}
                    className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-5 float-right bold"
                    style={{ backgroundColor: '#0e415f', color: '#fff', maxWidth: '220px', fontSize: 20, zIndex: 1 }}
                    type="submit" form="login-form">Save Information<i className="icon-material-outline-arrow-right-alt" />
                </button>
            </div>
        </div>
    );
}

export default CategoryClean;