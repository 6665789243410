import React from 'react';

const InputSelector  = React.forwardRef((props,  ref) => {
    const {id,type, options = [], value, onChange, label, placeholder, required} = props;
    if(type === 'input'){
        return (
            <div className='mt-2'>
                <h4>{label}</h4>
                <input
                    id={id}
                    className="mt-2 input-style"
                    style={{height:60,fontSize:16,borderWidth:1/2,maxWidth:'100%',margin:'auto'}}
                    ref={ref}
                    placeholder={placeholder}
                    required={required}
                />
            </div>
        );
    }else if(type === 'select'){
        return (
            <div className='mt-2'>
               <h4>{label}</h4>
                <select
               className="mt-2 input-style"
               style={{height:60,fontSize:16,borderWidth:1/2,maxWidth:'100%',margin:'auto'}}
                // value={value}
                id={id}
                ref={ref}
                required={required}
                // onChange={e => onChange(e.target.value)}
                >
                    <option>Select any option</option>
                    {options.map(item => {
                        return(
                            <option value={`${item}`}>{`${item}`}</option>
                        );
                    })}
                   
                </select> 
            </div>
        );
    }
    
});

export default InputSelector;