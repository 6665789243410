import React, { useEffect, useState } from "react";
import { Footer, Header, RModal } from "../components";
import { useFormik } from "formik";
import { irisValidation } from "../utils/validition";
import { CircularProgress } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { _call } from "../utils/auth";
import { decrypt } from "../utils/function";

const _initialValues = {
    first_name: '', last_name: '', suffix: '', middle_name: '', tin_type: '', ssn_tin: '', country: '', city: '', postal_code: '', phone_number: '', address: ''
}

const IrisScene = (props) => {

    const history = useHistory();
    const location = useLocation();

    const role = localStorage.getItem('role');
    const accessToken = location.pathname?.replace('/iris/', '')?.replace('/app/seller', '');

    // useEffect(() => {
    //     if (role) {
    //         history.push(`/iris/${accessToken}`);
    //     }
    // }, [role, accessToken, history]);


    const [loader, setloader] = useState(false);

    const [alertObj, setalertObj] = useState({ state: false, title: '' });



    // useEffect(() => {
    //     handleRedirection()
    // }, [])

    // const handleRedirection = async () => {
    //     var role = localStorage.getItem('role');
    //     if (role) {
    //         props.history.push(`/iris/${accessToken}`)
    //     }

    // }

    const formik = useFormik({
        initialValues: _initialValues,
        validationSchema: irisValidation,
        onSubmit: data => saveIrisInfo(data)
    })

    const saveIrisInfo = async (data) => {

        try {
            setloader(true)

            const createIris = await _call(`insert-iris`, 'POST', data, {
                Authorization: 'Bearer ' + accessToken
            });
            setloader(false)
            setalertObj({ type: 1, state: true, title: 'Filled Successfully', message: 'Form Filled Successfully' });
            resetForm();

            setTimeout(() => {
                props.history.push('/app/seller/')
            }, 1000);


        } catch (err) {
            setloader(false)
        }

    }



    const { values, errors, touched, handleChange, handleSubmit, resetForm } = formik;
    const { first_name, last_name, suffix, middle_name, tin_type, ssn_tin, country, city, address, phone_number, postal_code } = values;

    const composeErrorValidation = (field) => {

        if (touched[field] && errors[field]) {
            return <h4 className="text-red-500 text-center text-sm mt-1">{errors[field]}</h4>
        }

        return <></>
    }

    return <>
        <Header />
        <div className="min-h-[100vh] bg-slate-50 px-10 lg:px-20 py-5">
            <div className=" bg-white border w-full h-full p-5">
                <h4 className="text-3xl"><span className="text-orange-500">Iris</span> Information</h4>
                <p className="mt-1 text-sm">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form of a webpage or publication, without the meaning of the text influencing the design.
                    Lorem ipsum is typically a corrupted version of De finibus bonorum et malorum, a 1st-century BC text by the Roman statesman and philosopher Cicero, with words altered, added, and removed to make it nonsensical and improper Latin. The first two words themselves are a truncation of 'dolorem ipsum' ('pain itself').</p>
                <hr />

                <h4>First Name </h4>
                <input value={first_name} onChange={handleChange('first_name')} className="w-100 bg-slate-50 border h-16 shadow-none mt-2" />
                {composeErrorValidation('first_name')}

                <h4 className="mt-2">Last Name</h4>
                <input value={last_name} onChange={handleChange('last_name')} className="w-100 bg-slate-50 border h-16 shadow-none mt-2" />
                {composeErrorValidation('last_name')}

                <h4 className="mt-2">Middle Name</h4>
                <input value={middle_name} onChange={handleChange('middle_name')} className="w-100 bg-slate-50 border h-16 shadow-none mt-2" />
                {composeErrorValidation('middle_name')}

                <h4 className="mt-2">Suffix</h4>
                <input value={suffix} onChange={handleChange('suffix')} className="w-100 bg-slate-50 border h-16 shadow-none mt-2" />
                {composeErrorValidation('suffix')}

                <h4 className="mt-2 text-xl font-bold">My Tax Info</h4>

                <h4 className="mt-2">Tin Type</h4>
                <select value={tin_type} onChange={handleChange('tin_type')} className="w-100 bg-slate-50 border h-16 shadow-none mt-2" >
                    <option value="SSN">Social Security Number</option>
                    <option value="TIN">TIN</option>
                </select>
                {composeErrorValidation('tin_type')}

                <h4 className="mt-2">SSN / TIN</h4>
                <input value={ssn_tin} onChange={handleChange('ssn_tin')} className="w-100 bg-slate-50 border h-16 shadow-none mt-2" />
                {composeErrorValidation('ssn_tin')}

                <h4 className="mt-2 text-xl font-bold">My Address</h4>

                <h4 className="mt-2">Address</h4>
                <input value={address} onChange={handleChange('address')} className="w-100 bg-slate-50 border h-16 shadow-none mt-2" />
                {composeErrorValidation('address')}

                <h4 className="mt-2">City</h4>
                <input value={city} onChange={handleChange('city')} className="w-100 bg-slate-50 border h-16 shadow-none mt-2" />
                {composeErrorValidation('city')}

                <h4 className="mt-2">Country</h4>
                <input value={country} onChange={handleChange('country')} className="w-100 bg-slate-50 border h-16 shadow-none mt-2" />
                {composeErrorValidation(country)}

                <h4 className="mt-2">Postal Code</h4>
                <input value={postal_code} onChange={handleChange('postal_code')} className="w-100 bg-slate-50 border h-16 shadow-none mt-2" />
                {composeErrorValidation('postal_code')}

                <h4 className="mt-2">Phone Number</h4>
                <input value={phone_number} onChange={handleChange('phone_number')} className="w-100 bg-slate-50 border h-16 shadow-none mt-2" />
                {composeErrorValidation('phone_number')}

                <button onClick={handleSubmit} className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-5"
                    style={{ backgroundColor: '#444', color: '#fff', maxWidth: '300px', margin: 'auto', borderRadius: 100 }}>
                    {loader ? <CircularProgress /> : "Submit"}
                </button>


            </div>
        </div>
        <Footer />
        <RModal
            open={alertObj.state}
            title={alertObj.title}
            message={alertObj.message}
            type={alertObj.type}
            onClose={() => {
                setalertObj({ ...alertObj, state: false });
            }}
        />
    </>
}

export default IrisScene;