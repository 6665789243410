import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { getFieldName } from '../utils/function';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import GoogleMapReact from 'google-map-react';
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel"
import Geocode from "react-geocode";
import { googleAPIKey } from '../utils/constant';
import { CircularProgress } from '@mui/material';

const mapStyles = {
  width: '95%',
  height: '100vh',
  position: 'relative'
};


const Marker2 = (props) => {
  const [view, setview] = useState(false);
  const { onClick, name, data } = props;
  return (
    <>
      <div
        onClick={() => setview(true)}
        className="sm-marker"
      />
      {view &&
        <div
          onClick={() => {
            setview(false);
          }}
          className="marker2"
          style={{ backgroundColor: '#fff', cursor: 'pointer', alignItems: 'center', justifyContent: 'center', padding: 10, zIndex: 100 }}
          title={name}
        >
          <img
            src={require('./../assets/images_/cross.png').default}
            style={{
              width: '17px',
              position: 'absolute',
              right: '10px',
              top: '7px'
            }}
          />
          <h4 style={{ fontSize: 12, fontWeight: 'bold', marginLeft: 4 }}>{data.title}
          </h4>
          <p style={{ margin: 4, lineHeight: '10px', fontSize: 8 }}>{data.categoryName.includes('Shipping') ? `${data.categoryName?.split('_')[0]} ${data.categoryName?.split('_')[1]}` : `${data.categoryName} ${data.subCategoryName}`}</p>
          <p style={{ margin: 4, lineHeight: '10px', fontSize: 8 }}>{data.price === 'Open' ? 'Open for bid' : '$' + data.price}</p>
          <h4
            className='mp-button'
            onClick={onClick}
          >View</h4>
        </div>
      }
    </>
  );
};

const MultiMapSection = ({ ...props }) => {
  const { destination, position, handleClick, curposition, dataset } = props;
  const [selected, setselected] = useState([])
  const [loader, setloader] = useState(true);
  const [open, setopen] = useState(false);
  const [mylatlong, setmylatlong] = useState({
    lat: _.isEmpty(curposition) ? 33.753746 : curposition?.lat,
    lng: _.isEmpty(curposition) ? -84.386330 : curposition?.lng
  })

  useEffect(() => {

    navigator.geolocation.getCurrentPosition(function (position) {
      setmylatlong({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      })
      setloader(false);
    });
    setloader(false);
  }, []);



  if (loader) return <CircularProgress />
  window.google = window.google || {};
  return (
    <div
      style={{
        height: '80vh',

      }}
      className='mt-2'
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleAPIKey }}
        defaultCenter={{
          lat: mylatlong.lat,
          lng: mylatlong.lng
        }}
        defaultZoom={10}
      >
        {destination.filter(x => x.geocode).map((item, index) => {
          const parseOne = JSON.parse(item.geocode);
          return (
            <Marker2
              lat={parseOne.lat}
              lng={parseOne.lng}
              name={item?.title}
              data={item}
              color="blue"
              onClick={() => handleClick(item)}
            />
          )
        })}
      </GoogleMapReact>

    </div>

  );
};

export default GoogleApiWrapper({
  apiKey: googleAPIKey
})(MultiMapSection);






{/* <Map 
        google={window?.google}
        zoom={14}
        style={mapStyles}
        initialCenter={
          {
            lat: 38.9071923,
            lng: -77.0368707
          }
        }
        className={'map'}
      >
          {destination.filter(x => x.position).map((item,index) => {
              return(
                <Marker2
                lat={item.position.lat}
                lng={item.position.lng}
                name="My Marker"
                color="blue"
              />
            //       <AnyReactComponent
            // lat={item.position.lat}
            // lng={item.position.lng}
            // text="My Marker"
          
          // />
  
          
          
          // <div>
          //     <Marker
          //   name="Location"
          //   id={index}
          //   onClick={() => handleClick(item)}
          //   position={{lat:item.position.lat, lng: item.position.lng}} 
          //   onMouseover={() => setselected(item)}
          //   icon={require('../assets/images_/mapmarker.png').default}
          //   containerStyle={{width:100,height:100}}
          //   >
            
          // </Marker>
          //    <div
          // style={{position:'absolute',top:0,zIndex:10,background:'#fff',opacity:1}}
          //   onClick={() => handleClick(selected)}
          //   className="job-listing-details">
          //       <div className="job-listing-description " style={{overflow:'hidden'}}>
                
          //         <div className="single-page-section" style={{paddingBottom:0}}>
          //         <h4 className='bold' style={{color:'#000'}}>{selected.title}</h4>
          //     <p style={{maxWidth:'100%',overflow:'hidden'}}>
          //     {selected.description}
          //     </p>
          //   </div>
          //   </div>
          //   </div>
          // </div>

          // <MarkerWithLabel
          // position={{lat:item.position.lat, lng: item.position.lng}} 
          // />
        
              );
          })}
          
          </Map>
        */}