import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUser } from '../utils/function';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ProtectedRoute = ({
  component: Component,
  sellercomponent: SComponent,
  ...rest
}) => {
  const location = useLocation();
  const setComponent = (props) => {

    const accessToken = location.pathname?.replace('/iris/', '')?.replace('/app/seller', '');

    // useEffect(() => {
    //     if (role) {
    //         history.push(`/iris/${accessToken}`);
    //     }
    // }, [role, accessToken, history]);
    const currentUser = getCurrentUser();
    const currentRole = getCurrentUser()?.role;

    if (location.pathname?.includes('iris')) {
      if (!currentUser && !currentRole) {
        return <Redirect exact from="/" to={`/iris/${accessToken}`} />
      } else {
        // return <Redirect exact from="/" to={`/app/seller/iris/${accessToken}`} />
        return <SComponent {...props} />;
      }

    }

    if (currentUser) {
      if (currentRole === 'Seller') {
        return <SComponent {...props} />;
      } else {
        return <Component {...props} />;
      }
    }
    return (
      <Redirect exact from="/" to={'/login'} />
    );
  };

  return <Route {...rest} render={setComponent} />;
};

export { ProtectedRoute };
