import React, { useState } from 'react';
import _ from 'lodash'
import { Alert, Rating } from '@mui/material';
import moment from 'moment-timezone';

const QnaComp = (props) => {
  const { question, time, profileImg, name, ratings, answers, onReply, useraccess, history, data } = props;
  const [openstate, setopenstate] = useState(false);

  const handleNavigate = (item) => {

    const role = localStorage.getItem('role');
    var navigationObj = {};
    if (role === "Seller") {
      navigationObj['userId'] = item.uid;
      navigationObj['route'] = item.Type === 'Buyer' ? '/app/seller/buyer-view-profile' : '/app/seller/user-profile';
    } else if (role === "Buyer") {
      navigationObj['userId'] = item.uid;
      navigationObj['route'] = item.Type === 'Buyer' ? '/app/buyer-view-profile' : '/app/user-profile';
    }

    if (!_.isEmpty(navigationObj)) {
      history.push({
        pathname: navigationObj.route,
        state: {
          userId: navigationObj.userId
        }
      })
    }

  }

  return (
    <div className="job-listing w-100 mt-4">
      <div className="job-listing-details">
        <div className="job-listing-description p-0 m-0">
          <div className='row m-0' style={{ alignItems: 'center', paddingBottom: 10 }}>
            <div style={{ flex: 1, }}>
              <h4 className='bold' style={{ fontSize: 18 }}>
                {question}
              </h4>
              <p style={{
                color: '#afafaf',
                fontSize: 12,
                lineHeight: '16px',
                letterSpacing: 1,
                marginTop: 2
              }}>{time}</p>
            </div>
            {useraccess &&
              <button className="button full-width button-sliding-icon ripple-effect mt-3 p-2"
                style={{ backgroundColor: '#34BE82', color: '#f7f7f7', width: 80, borderRadius: 0 }}
                onClick={onReply}
                // onClick={() => handleConfirmOrder(1, item)}
                type="submit" form="login-form">Reply</button>
            }

            <button className="button full-width button-sliding-icon ripple-effect mt-3 p-2"
              style={{ backgroundColor: '#efefef', color: '#000', width: 80, borderRadius: 0, marginLeft: 10 }}
              onClick={() => setopenstate(!openstate)}
              // onClick={() => handleConfirmOrder(1, item)}
              type="submit" form="login-form">View</button>
          </div>
          {openstate &&
            <>
              <div className='mt-2' />
              {answers.map((item, index) => {
                return (
                  <div className="row m-0 mt-1 " style={{ border: "1px solid #efefef", padding: 10, paddingTop: 0, alignItems: "center" }} >
                    <h4 onClick={() => handleNavigate(item)} className='cursor-pointer border w-8 mt-2.5 mr-3 h-8 text-center py-1.5'>{item.Name?.split('')[0]}</h4>
                    <p className='text-slate-500 flex flex-1'>
                      {item.answer}
                    </p>
                    <p style={{
                      color: '#afafaf',
                      fontSize: 10,
                      lineHeight: '16px',
                      letterSpacing: 1
                    }}>
                      {moment(item.created_on).tz('America/New_York')?.format('MM-DD-YYYY HH:mm a')}
                    </p>
                  </div>
                );
              })}
            </>
          }

        </div>
      </div>
    </div>
  );
}

export default QnaComp;