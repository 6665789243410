import React from 'react'


const Spinner = () => {

    return <div className="flex flex-col items-center justify-center w-100 bg-slate-50" style={{ height: '100vh' }}>
        <div className='w-20 h-20 rounded-full bg-black inline-flex justify-center items-center'>
            <img src={require('../assets/icons/logo.png').default} className="w-14 h-14 animate-spin" />
        </div>

        <h4 className="mt-3 font-bold">Loading... Please Wait</h4>
    </div>
}

export default Spinner;