import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Button } from 'react-bootstrap'
import '../css/style.css';
import '../css/sass/main.css';
import '../css/colors/blue.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { Alert, Rating } from '@mui/material';
import Blink from 'react-blink-text';
import { Header, SellerSidebar, Spinner } from '../components';
import CanvasJSReact from './../libraries/canvasjs.react';
import { ADD_USER, REMOVE_USER } from '../redux/action';
import { signInWithEmailAndPassword, getAuth, onAuthStateChanged } from "firebase/auth";
import { connect } from 'react-redux';
import { _call, useApiRequest } from '../utils/auth';
import { firebaseConfig, imageURL } from '../utils/constant';
import Footer from '../components/UI/footer';
import { sessionMaintainer } from '../utils/function';
import { useHistory } from 'react-router-dom';
import { round } from 'lodash'
import { initializeApp } from 'firebase/app';

function mapStateToProps(state) {
  return {
    userInfo: state
  };
}


const reviewsStandard = [
  { id: 0, name: 'Excellent', value: 5 },
  { id: 1, name: 'Good', value: 4 },
  { id: 3, name: 'Fair', value: 3 },
  { id: 2, name: 'Poor', value: 2 },
  { id: 1, name: 'Very Poor', value: 1 },
]

const Dashboard = (props) => {
  const history = useHistory();
  const [uid, setuid] = useState('');
  const [stateMessage, setstateMessage] = useState('');
  const [loader, setloader] = useState(false);
  const [emails, setemails] = useState();
  const [ratings, setratings] = useState('');
  const [userstatus, setuserStatus] = useState('');
  const [dataset, setdataset] = useState({
    "pending": 0,
    "completed": 0,
    "earned": 0,
    "feedback": 0,
    "declined": 0,
    "jobs": 0,
    "cancel": 0,
    "totalbids": 0
  });

  initializeApp(firebaseConfig)

  useEffect(() => {
    handleGetUserData();
  }, []);

  const auth = getAuth()
  const { AddUser, userInfo, } = props;

  const handleGetUserData = async () => {
    onAuthStateChanged(auth, async (user) => {
      setloader(true);
      if (user) {
        const uid = user.uid;
        localStorage.setItem('uid', uid);
        try {
          const getData = await _call(`current-user`, 'GET');
          const data = getData.data.data[0];
          AddUser(data);
          handleGetUserInfo(data.userId);
          handleGetRatings(data.userId);
          handleGetEmails(data.userId);
          setloader(false);
        } catch (err) {
          if (err?.message == "Un-auth") {
            localStorage.removeItem('token');
            localStorage.removeItem('uid');
            localStorage.removeItem('userId');
            localStorage.removeItem('email');
            localStorage.removeItem('state');
            localStorage.removeItem('role');

            props.history.push('/login')
            window.location.reload(true);
          }
          setloader(false);
        }
        setuid(uid)

      } else {
        setloader(false);
      }
    });


    const sessionMaintainerResovler = await sessionMaintainer();
    if (sessionMaintainerResovler == "log-out") {
      await localStorage.removeItem('token');
      await localStorage.removeItem('uid');
      await localStorage.removeItem('userId');
      await localStorage.removeItem('email');
      await localStorage.removeItem('state');
      await localStorage.removeItem('role');
      await localStorage.removeItem('session');

      history.push('/login')
      window.location.reload(true);
    }

  }



  const handleGetEmails = async (userId) => {
    // const getemails = await localStorage.getItem('emails_received');
    try {
      const getemail = await _call('inbox-length/90', 'GET');
      setemails(getemail.data)
    } catch (err) { }
  }


  const handleGetRatings = async (userId) => {
    var ratings_ = 0;
    try {
      const getreviews = await _call(`user/reviews/90`, 'GET');
      const data = getreviews.data.data;
      data.map(item => {
        ratings_ += handleRatings(item.performance);
        return item;
      });
      setratings((ratings_ / data?.length));

    } catch (err) {
    }
  }

  const handleRatings = (item) => {
    return reviewsStandard.find(x => x.name === item)?.value;
  }
  const handleGetUserInfo = async (id) => {
    try {
      const _getUserStatus = await _call(`user-status/90`, 'GET');
      setuserStatus(_getUserStatus.data);
      const getdata = await _call(`getmylistinfo/90`, 'GET');
      setdataset(getdata.data);
    } catch (err) {
    }
  }

  const handleShowRequestBtn = () => {

    if (userstatus == "A" || userstatus?.toLowerCase() == "active" || userstatus?.toLowerCase() == "approved") return false

    if (userstatus == 'Pending' || userstatus == 'P') {
      return ('Your account is not activated yet!. Please wait until it is approved');
    };
    if (userstatus?.toLowerCase() == 'rejected') {
      return ('Your account is rejected. Please contact Admin Support')
    };

    return true;
  }

  const handleRoute = (route) => {
    const { history } = props;
    history.push(route);
  }

  if (loader) return <Spinner />

  return (

    <div id="wrapper" className="bgiparent landing">
      <Header
        history={props.history}
        userInfo={userInfo}
      />
      <div className="clearfix" />
      <div className="row">
        <div className="col-lg-12">
          <div className="">


            <div className="dashboard-container">

              <SellerSidebar />

              <div className="flex flex-1 row p-3 bg-slate-50 border-l" data-simplebar>

                <div className='col-12'>
                  {handleShowRequestBtn() &&
                    <Alert severity="error" className='mb-3'>{handleShowRequestBtn()}</Alert>
                  }
                  <div className='row pt-4'>

                    <div className='col-10'>


                      <h5 className="job-listing-title">Welcome!</h5>
                      <h2 className="job-listing-title bold">{userInfo.first_name} {userInfo.last_name}</h2>
                    </div>
                    <div onClick={() => history.push('/app/seller/inbox')} className='col-2 cursor-pointer' style={{ display: "flex", justifyContent: 'flex-end' }}>
                      {parseInt(emails?.emails) > 0 ? <Blink color='blue' text={<div className='email-icon-container flex flex-col'
                        style={{ background: '#fff' }}
                      >
                        <img src={require('../assets/images_/email.png').default} style={{ width: 20, height: 20 }} />
                        <h4 className='text-sm'>{emails?.emails}</h4>
                      </div>} fontSize='20'>

                      </Blink> :
                        <div className='email-icon-container'
                        >
                          <img src={require('../assets/images_/email.png').default} />
                        </div>
                      }
                    </div>
                  </div>
                  <div className='mt-5' />
                  <div className='row w-100  '>
                    <div className="col-lg-4">
                      <div className="fun-facts-container"
                        onClick={() => handleRoute('/app/seller/balance')}
                      >
                        <div className="fun-fact border" data-fun-fact-color="#b81b7f">
                          <div className="fun-fact-text">
                            <span>Total Amount Earned</span>
                            <h4>${round(dataset?.amountEarned, 2) || 0}</h4>
                          </div>
                          <div className="fun-fact-icon" style={{ backgroundColor: '#b81b7f' }}>
                            <i className="icon-material-outline-business-center" style={{ color: '#fff' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="fun-facts-container"
                        // onClick={() => props.history.push('/app/seller/quotes')}
                        onClick={() => props.history.push({
                          pathname: 'quotes',
                          state: {
                            mode: 4
                          }
                        })}
                      // 4
                      >

                        <div className="fun-fact border" data-fun-fact-color="#efa80f">
                          <div className="fun-fact-text">
                            <span>Cancelled Jobs</span>
                            <h4>{dataset?.cancelled}</h4>
                          </div>
                          <div className="fun-fact-icon" style={{ backgroundColor: '#efa80f' }}>
                            <i className="icon-material-outline-rate-review" style={{ color: '#fff' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="fun-facts-container"
                        onClick={() => props.history.push({
                          pathname: 'mylead',
                          state: {
                            modeexplicit: 4
                          }
                        })}
                      >
                        <div className="fun-fact border" data-fun-fact-color="#36bd78">
                          <div className="fun-fact-text">
                            <span>Total Completed Projects</span>
                            <h4>{dataset?.completed}</h4>
                          </div>
                          <div
                            // onClick={() => props.history.push('/app/seller/quotes')}
                            className="fun-fact-icon" style={{ backgroundColor: '#36bd78' }}>
                            <i className="icon-material-outline-gavel" style={{ color: '#fff' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div
                        onClick={() => props.history.push({
                          pathname: 'mylead',
                          state: {
                            modeexplicit: 2
                          }
                        })}
                        className="fun-facts-container">
                        <div className="fun-fact border" data-fun-fact-color="#b81b7f">
                          <div className="fun-fact-text">
                            <span>Jobs in Process</span>
                            <h4>{dataset?.process}</h4>
                          </div>
                          <div className="fun-fact-icon" style={{ backgroundColor: '#b81b7f' }}>
                            <i className="icon-material-outline-business-center" style={{ color: '#fff' }} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div
                        onClick={() => props.history.push({
                          pathname: 'mylead',
                          state: {
                            modeexplicit: 3
                          }
                        })}
                        className="fun-facts-container">
                        <div className="fun-fact border" data-fun-fact-color="#b81b7f">
                          <div className="fun-fact-text">
                            <span>Jobs in Picked Up</span>
                            <h4>{dataset?.pickedup}</h4>
                          </div>
                          <div className="fun-fact-icon" style={{ backgroundColor: '#b81b7f' }}>
                            <i className="icon-material-outline-business-center" style={{ color: '#fff' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
      <div className='footer'><Footer {...props} /></div>

    </div>
  );
}


function mapDispatchToProps(dispatch) {
  return {
    AddUser: data => {
      // dispatch(REMOVE_USER());
      dispatch(ADD_USER(data));
    },
    RemoveUser: () => {
      dispatch(REMOVE_USER());
    }
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);