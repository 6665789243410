import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import { InputSelector, SimpleInputSelector } from '..';
import { booleanAns, carCondition, carTruckTypes, handlingUnit, motorCycleTypes, otherVehicles } from '../../utils/constant';
import _ from 'lodash'

const ShipFurnitureSection = ({ userBasicInfo, onSubmit, subcatId }) => {
    const [shipmentInfo, setshipmentInfo] = useState({});
    const [submisive, setsubmisive] = useState(false);
    const [furniture, setfurniture] = useState([{}]);
    const [currentState, setcurrentState] = useState(false);
    const [furnitureInfo, setfurnitureInfo] = useState([]);
    var i = 0;

    const handleChange = (key, value) => {
        var shipmentInfoClone = { ...shipmentInfo };
        shipmentInfoClone[key] = value;
        setshipmentInfo(shipmentInfoClone);
    }

    const handleOnChange = (key, value, item) => {
        const data = [...furniture];
        const findex = data.findIndex(x => x === item);
        data[findex][key] = value;
        setfurniture(data);
    }

    const handleAdd = () => {
        const data = [...furniture];
        // data.push({ title: '', quantity: '', length: '', width: '', height: '', weight: '' });
        // setfurniture(data);
        const { title, quantity, length, width, height, weight } = data[data.length - 1];
        if (title && quantity && length && width && height && weight) {
            data.push({ title: '', quantity: '', length: '', width: '', height: '', weight: '' });
            setfurniture(data);
        } else {
            alert("Please complete all the fields to add new.")
        }
    }

    const handleRemove = (item) => {
        const data = [...furniture];
        const findex = data.findIndex(x => x === item);
        data.splice(findex, 1);
        setfurniture(data);
    }

    const handleSubmission = () => {
        if (!shipmentInfo.heading || _.isEmpty(furniture)) {
            setsubmisive(true);
        } else {
            onSubmit({ ...shipmentInfo, furniture_data: JSON.stringify(furniture) })
        }
    }

    return (
        <div className="container" style={{ minHeight: '100vh' }}>
            <div className='row'>
                <div className='col-md-12'>
                    <SimpleInputSelector
                        label="Job Title"
                        type="input"
                        onChange={val => handleChange('heading', val)}
                    />
                    {submisive && !shipmentInfo.heading &&
                        <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Job Title is required</h4>
                    }
                </div>
            </div>

            {furniture.map((item, index) => {
                return (
                    <div>
                        <div className="row mt-4">
                            <div className='col-md-6'>
                                <SimpleInputSelector type="input" label="Item title" onChange={e => handleOnChange('title', e, item)} />
                            </div>
                            <div className='col-md-6'>
                                <SimpleInputSelector type="input" label="Quantity" onChange={e => handleOnChange('quantity', e, item)} mode={'number'} addition="only" />
                            </div>
                            <div className='col-md-3'>
                                <SimpleInputSelector type="input" label="Length (Inches)" onChange={e => handleOnChange('length', e, item)} mode={'number'} />
                            </div>
                            <div className='col-md-3'>
                                <SimpleInputSelector type="input" label="Width (Inches)" onChange={e => handleOnChange('width', e, item)} mode={'number'} />
                            </div>
                            <div className='col-md-3'>
                                <SimpleInputSelector type="input" label="Height (Inches)" onChange={e => handleOnChange('height', e, item)} mode={'number'} />
                            </div>
                            <div className='col-md-3'>
                                <SimpleInputSelector type="input" label="Weight (lbs)" onChange={e => handleOnChange('weight', e, item)} mode={'number'} />
                            </div>

                        </div>

                    </div>
                );
            })}

            <div className='clearfix' />
            <button
                onClick={() => handleAdd()}
                className="button full-width button-sliding-icon ripple-effect margin-top-20 mb-5 send-btn"
                style={{ backgroundColor: '#444', justifyContent: 'center', alignItems: 'center' }}
                type="submit" form="login-form">Add Another
            </button>

            {submisive && _.isEmpty(furniture) &&
                <h4 className='mb-2 mt-2' style={{ color: 'red', fontSize: 14, marginTop: -10 }}>Atleast one item should be added</h4>
            }
            <div>
                <div className='clearfix' />
                <button
                    onClick={() => handleSubmission(furniture)}
                    className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-5 float-right bold"
                    style={{ backgroundColor: '#0e415f', color: '#fff', maxWidth: '220px', fontSize: 20 }}
                    type="submit" form="login-form">Save Information<i className="icon-material-outline-arrow-right-alt" />
                </button>
            </div>
        </div>
    );
}

export default ShipFurnitureSection;