import { Alert, AlertTitle } from "@mui/material";
import React from "react";

const Response = (props) => {
    const { mode = 0, message, title = '' } = props;

    if (mode == 0) {
        return <>
            <Alert severity="error" className="mt-3 mb-3 ml-0">
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </>
    }

    if (mode == 1) {
        return <>
            <Alert severity="success" className="mt-3 mb-3 ml-0">
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </>
    }
    return <div />
}

export default Response;