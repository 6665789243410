import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  minHeight: 150,
  padding: 5,
};

const boxStyle = {
  background: '#f7f7f7',
  width: '100%',
  height: '200px',
  marginTop: 30
}

const AskDecline = ({ ...props }) => {
  const { open, onClose, onAccept } = props;
  const [declineText, setdeclineText] = useState('')
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-responsive-nedd"
    >
      <Box sx={style} className='text-center'>

        <Typography
          style={{ paddingTop: 0, fontSize: 20, fontWeight: 'bold' }}
        >
          Are you declining it
        </Typography>
        <p
          style={{
            lineHeight: '13px',
            fontSize: 14,
            color: '#afafaf',
            marginTop: 5
          }}
        >
          Can you please share your thoughts. Why you are rejecting it
        </p>
        <select
          value={declineText}
          placeholder="Decline Reason"
          onChange={e => setdeclineText(e.target.value)}
        >
          <option value="Price too high" >Price too high</option>
          <option value='Profile is not up to mark' >Profile is not up to mark</option>
          <option value="Not well explained">Not well explained</option>

        </select>
        <div className='row'>
          <button
            onClick={onClose}
            className="button full-width button-sliding-icon ripple-effect p-2 mb-3 mt-5"
            style={{ backgroundColor: '#f7f7f7', color: '#444', maxWidth: '40%', margin: 'auto', border: '1px solid #efefef' }}
            type="type" form="login-form">Cancel</button>
          <button
            onClick={() => onAccept(declineText)}
            className="button full-width button-sliding-icon ripple-effect p-3 mb-3 mt-5"
            style={{ backgroundColor: '#444', color: '#fff', maxWidth: '40%', margin: 'auto' }}
            type="type" form="login-form">Decline</button>
        </div>
      </Box>
    </Modal>
  );
};

export default AskDecline;