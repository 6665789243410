/*global google*/
import React, { Component } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  DirectionsRenderer
} from "react-google-maps";

const RenderMap = withGoogleMap(props => (
  <GoogleMap
    defaultCenter={props.origin}
    defaultZoom={0}
    options={{
      fullscreenControl: false,
      zoomControl: false,
      mapControl: false,
      mapTypeControl: false,
      gestureHandling: "none",
      zoomControl: false,
    }}
  >
    <div style={{ backgroundColor: '#efefef', border: '1px solid #efefef', width: 200, height: 40, position: 'absolute', top: 0, right: 0 }}>
      <h4 style={{ marginTop: 10, textAlign: 'center', fontWeight: 'bold' }}>Distance: {props.distance} miles</h4>
    </div>
    <DirectionsRenderer
      directions={props.directions}
    />
  </GoogleMap>
));


class DirectionMapSection extends Component {
  state = {
    directions: null,
    distance: 0
  };


  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.props.destination === nextProps.destination) {
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  componentDidMount() {
    const directionsService = new google.maps.DirectionsService();

    const origin = this.props.origin;
    const destination = this.props.destination;
    // var obj = {}
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
            distance: result?.routes[0]?.legs[0]?.distance?.text
          });
          // obj = {
          //   mode: 1,
          //   value: result?.routes[0]?.legs[0]?.distance?.text
          // }
          // this.props.handleReturnDistance({
          //   mode: 1,
          //   value: result?.routes[0]?.legs[0]?.distance?.text
          // })
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
    // this.props.handleReturnDistance(obj)
  }

  render() {
    return (
      <div>
        <RenderMap
          directions={this.state.directions}
          origin={this.props.origin}
          distance={this.props?.distance}
          containerElement={<div style={{ height: `400px`, width: "100%" }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}

export default DirectionMapSection;
