import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { ImageUploader, Spinner } from '..';
import _ from 'lodash';
import { Alert, CircularProgress } from '@mui/material';
import { _call } from '../../utils/auth';
import axios from 'axios';
import S3 from "react-aws-s3"
import Response from '../_response';
import { S3BucketInfo } from '../../utils/constant';

const keystate = {
    nicFront: true,
    nicBack: true,
    passport: true,
    bankStatement: true
}




const ProfileManInfo = ({ nicBack,
    nicFront,
    passport, handleSubmit, userType, business_license, userManInfo, userId, bankstatement_ }) => {
    const [userInfo, setuserInfo] = useState({ userManInfo });
    const [alertObj, setalertObj] = useState({ state: false, title: '' });
    const [validatedocs, setvalidatedocs] = useState(keystate);
    const [additionaldocument, setadditionaldocument] = useState([]);
    const [nicF, setnicF] = useState([]);
    const [nicB, setnicB] = useState([]);
    const [passport_, setpassport] = useState([]);
    const [listerrors, setlisterrors] = useState([])
    const [bankstatement, setbankstatement] = useState([]);

    const [dataset, setdataset] = useState([]);
    const [validationstatus, setvalidationstatus] = useState({});
    const [submissive, setsubmissive] = useState(false);
    const [loader, setloader] = useState(false);
    const ReactS3Client = new S3(S3BucketInfo);

    useEffect(() => {
        handleCheckApplicationStatus();
        handleData();
    }, []);


    const handleData = async () => {

        try {

            const _getdocs = await _call('seller-docs/90', 'GET');
            setdataset(_getdocs.data);

        } catch (err) {

        }

    }

    const imageUploader = (file, fileName) => new Promise((resolve, reject) => {
        ReactS3Client
            .uploadFile(file, fileName)
            .then(datas => resolve(datas))
            .catch(err => reject(err))
    })



    const handleCheckApplicationStatus = async () => {
        try {
            const getapplicationstatus = await _call('validate-seller-info', 'POST', {
                validation: 0
            });
            setvalidationstatus(getapplicationstatus.data.result.complete);
        } catch (err) {

        }
    }

    const handleChange = (key, value) => {
        var userInfoClone = {};
        userInfoClone[key] = value;
        setuserInfo({ ...userInfo, ...userInfoClone });
    }

    const handleSave = async () => {
        var data = {};
        var dataset = [];
        setsubmissive(true);

        if (_.isEmpty(nicF) || _.isEmpty(nicB) || _.isEmpty(passport_) || _.isEmpty(bankstatement)) {
            return
        } else {
            setloader(true);
            const formdata = new FormData();
            formdata.append("back", nicB[0]);
            formdata.append("front", nicF[0]);
            formdata.append("bank", bankstatement[0]);
            try {
                const getstripefiles = await _call('seller-files', 'POST', formdata, {
                    'Content-Type': 'multipart/form-data'
                })
                data = {
                    dataset: [
                        { file: nicB[0], fname: getstripefiles.data.NIC_BACK, doc: 'NIC_BACK', filename: `${userId}_${moment().unix()}_${nicB[0].name}` },
                        { file: nicF[0], fname: getstripefiles.data.NIC_FRONT, doc: 'NIC_FRONT', filename: `${userId}_${moment().unix()}_${nicF[0].name}` },
                        { file: bankstatement[0], fname: getstripefiles.data.BANK_STATEMENT, doc: 'BANK_STATEMENT', filename: `${userId}_${moment().unix()}_${bankstatement[0].name}` },
                    ],
                    napi: true,
                }
                setloader(false);
                handleSubmit(data);
                setalertObj({
                    type: 1,
                    message: 'Files are uploaded successfully',
                    title: 'Mandatory Files'
                })
            } catch (err) {
                setloader(false);
                setalertObj({
                    type: 0,
                    message: 'Files are not uploaded',
                    title: 'Some problem occured'
                })
            }
        }

    }

    const handleVerifyViaStripe = async () => {
        const getuid = await localStorage.getItem('uid');
        const gettoken = await localStorage.getItem('token');

        try {

            const _createconnection = await _call('create-account-link', 'POST', {
                id: userId,
                redirection: 'register-seller',
                info: {
                    userId: getuid,
                    token: gettoken,
                    role: 'Seller'
                }
            });
            window.location.replace(_createconnection?.data?.url)

        } catch (err) {
        }
    }


    const handleUploadAdditionalDocument = async () => {
        try {

            if (!_.isEmpty(additionaldocument)) {
                setloader(true)
                var files = [];

                const docs = await Promise.all(additionaldocument.map(async item => {
                    const _upload = await imageUploader(item, item.name);
                    files.push(item.name);
                }));

                await Promise.all([docs])

                const _dataset = {
                    documentName: 'ADDITIONAL_DOCUMENT',
                    fileLink: files.toString(),
                };

                const updateUserInfo = await _call('user/mandatory', 'PUT', { ..._dataset });
                
                setloader(false)
                setalertObj({
                    type: 1,
                    message: 'Files are uploaded successfully',
                    title: 'Additional Files',
                    state: true
                })

            } else {
                // return;
                setloader(false)
            }

        } catch (err) {
            setloader(false)
            setalertObj({
                type: 0,
                message: 'Files are not uploaded',
                title: 'Some problem occured',
                state: true
            })
        }

    }

    // if (loader) return <Spinner />
    // if (loader) return <div className='loaderContainer' style={{ alignItems: 'center', height: '100vh', justifyContent: 'center', display: 'flex' }}><CircularProgress color="inherit" /></div>
    return (
        <div className="pl-4">
            {alertObj.state && <div className='w-100'>
                <Response mode={alertObj.type} title={alertObj.title} message={alertObj.message} />
            </div>}

            <div className='p-4'>
                <h4 className='mt-4 mb-3 ml-3'>Additional Document</h4>

                <ImageUploader
                    sendFiles={e => setadditionaldocument(e)}
                    numberOfFiles={4}
                />
                <button
                    onClick={() => handleUploadAdditionalDocument()}
                    className='bg-yellow-500 float-right w-48 mr-2 rounded h-12 mt-4 text-white text-md'
                    type="button">
                    {loader ? <CircularProgress size={16} /> : "Submit"}
                </button>
            </div>

        </div>
    );
}

export default ProfileManInfo;