import {  ADDUSER, REMOVEUSER } from "./type"

const ADD_USER = (data) => {
    return {
        type: ADDUSER,
        payload: data
    }
}

const REMOVE_USER = () => {
    return {
        type: REMOVEUSER,
        payload: []
    }
}

export {ADD_USER, REMOVE_USER};
