import React, { useEffect, useState } from 'react';

import S3 from "react-aws-s3"
import "react-datepicker/dist/react-datepicker.css";
import { _call } from '../../utils/auth';
import axios from 'axios';
import _ from 'lodash'
import { Alert, CircularProgress } from '@mui/material';
import { crypt, decrypt } from '../../utils/function';
import RModal from '../rmodal';
import ImageUploader from '../imageuploader';
import { S3BucketInfo } from '../../utils/constant';
import moment from 'moment';

const keyId = process.env.REACT_APP_SECRET_KEY;


const ProfileBillingInfo = ({ userBillingInfo, handleSubmit, userId, email, stripething, handleUpdateUserInfo, bankInfo, onVerify }) => {
  const [userInfo, setuserInfo] = useState({ userBillingInfo, routing_number: '', bankAccountNumber: '' });
  const [usertoken, setusertoken] = useState(null);
  const [bankStatement, setbankStatement] = useState([]);
  const [validationstatus, setvalidationstatus] = useState({});
  const [submissive, setsubmissive] = useState(false);
  const [alertObj, setalertObj] = useState({ state: false, title: '' });
  const [bankInformation, setbankInformation] = useState([]);
  const [userBankExistance, setuserBankExistance] = useState(false);
  const [accountStatus, setaccountStatus] = useState(false)
  const [accountData, setaccountData] = useState([]);
  const [loader, setloader] = useState(false);

  const handleChange = (key, value) => {
    var userInfoClone = { ...userInfo };
    userInfoClone[key] = value;
    setuserInfo(userInfoClone);
  }

  useEffect(() => {
    handleData();
    handleGetBankInfo();
    handleCheckApplicationStatus();
  }, []);


  const handleGetBankInfo = async () => {

    try {

      const getUserBankExistance = await _call('get-user-bank-existance', 'GET');
      setuserBankExistance(getUserBankExistance.data.ifExist)

      const getbankInfo = await _call(`b-info/90`, 'GET');
      const data = getbankInfo.data;

      setaccountData(data);
      if (data.info !== '') {
        setbankInformation(JSON.parse(decrypt(keyId, data.info)));
      }

    } catch (err) {
    }

  }

  const handleCheckApplicationStatus = async () => {
    try {
      const getapplicationstatus = await _call('validate-seller-info', 'POST', {
        validation: 2
      });
      setvalidationstatus(getapplicationstatus.data.result.complete);
    } catch (err) {
    }
  }

  const handleData = async () => {
    try {
      const result = await _call('user/verify-bank-account', 'POST', {});
      const data = result.data;
      setusertoken(data.link_token);
    } catch (err) {
    }

  }
  const ReactS3Client = new S3(S3BucketInfo);

  const imageUploader = (file, fileName) => new Promise((resolve, reject) => {
    ReactS3Client
      .uploadFile(file, fileName)
      .then(datas => resolve(datas))
      .catch(err => reject(err))
  })



  const handleUpdateInfo = async () => {
    const token = await localStorage.getItem('token')
    //
    if (userInfo.bankAccountNumber && userInfo.routing_number && userInfo.userBillingInfo && userInfo.bankName) {
      const binfo = {
        rno: crypt(keyId, userInfo.routing_number),
        ano: crypt(keyId, userInfo.bankAccountNumber),
        aho: crypt(keyId, userInfo.accountHolder),
        bankno: crypt(keyId, userInfo.bankName)
      };

      try {
        setloader(true)

        if (!userBankExistance) {
          const formData = new FormData();
          formData.append("bank", bankStatement);
          const unix = moment().unix();
          var bankStatementName = `${unix}_bank_account_statement_${bankStatement.name}`;

          const bankStatementUpload = await imageUploader(bankStatement, bankStatementName);

          const getstripefiles = await _call('seller-account-files', 'PUT', formData, {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token
          });

          const filesInfo = [
            { fname: getstripefiles.data.BANK_STATEMENT, doc: 'BANK_STATEMENT', filename: bankStatementName },
          ];

          const updateRequest = await _call('seller-files', 'PATCH', {
            dataset: filesInfo,
          });
        }

        const ubilling = await _call('seller-billing', 'POST', {
          binfo: crypt(keyId, JSON.stringify(binfo)),
        });

        handleGetBankInfo();

        setalertObj({
          state: true,
          title: 'Congratulation!',
          message: 'Billing saved successfully',
          type: 1,
        })


        setloader(false)
      } catch (err) {
        setloader(false)
        setalertObj({
          state: true,
          title: 'Sorry, some problem occured',
          message: 'Please try again later',
          type: 2,
        })
      }

    } else {
      setsubmissive(true);
    }



  }


  const handleRemoveBilling = async () => {
    try {

      const _deleteInfo = await _call(`delete_info/${userId}`, 'DELETE');
      alert("Deleted Successfully");
      handleGetBankInfo();

    } catch (err) {
    }
  }


  return (
    <div className="container pl-4" style={{ minHeight: '200vh' }}>

      {/* {!_.isEmpty(bankInformation) && */}

      <div className='flex flex-row mt-4 items-center bg-slate-50 px-4 border rounded-lg'>
        <div className='bg-slate-50 rounded-lg flex flex-row items-center justify-center w-[50px] h-[50px] border'>
          <img
            src={require('../../assets/images_/bankInfo.png').default}
            className='w-[20px] h-[20px]'
          />
        </div>
        <div className='flex flex-col mx-3 flex-1 mt-3'>
          <h4 className='bold text-xs xl:text-sm'>Bank Account: {bankInformation?.bank_name}</h4>
          <p className='text-xs xl:text-sm'>Account Number: {bankInformation?.account_holder?.slice(-4)}.</p>
        </div>
        {(!loader && accountData?.link != '') &&
          <a href={accountData?.link} className='px-4 bg-red-50 text-sm border cursor-pointer rounded-lg h-10 text-center pt-2.5 bold' target="__blank">Complete Your Account</a>
        }

        {(!loader && (accountData?.link == '' && accountData?.info)) && <div className='px-4 bg-green-50 text-sm border cursor-pointer rounded-lg h-10 text-center pt-2.5 bold'>
          <h4 className='text-sm'>Successfully Connected</h4>
        </div>}
      </div>
      <a className='text-blue-500 text-right w-full float-right' href='https://stripe.com/legal/ssa' target='__blank'>Stripe Services Agreement — United States</a>

      <div className="mt-4">
        {(accountData?.info == '') ?
          <div className="">
            <h4 className='mt-2 mb-1'>Bank Name</h4>
            <input
              className='mt-1 input-style '
              value={userInfo.bankName}
              onChange={e => handleChange('bankName', e.target.value)}
            />
            {submissive && !userInfo.bankName &&
              <h4 className='mb-2 ' style={{ color: 'red', fontSize: 14 }}>Bank Name is required</h4>
            }
            <h4 className='mt-2 mb-1'>Name on Account</h4>
            <input
              className='mt-1 input-style'
              value={userInfo.accountHolder}
              onChange={e => handleChange('accountHolder', e.target.value)}
            />
            {submissive && !userInfo.accountHolder &&
              <h4 className='mb-2 ' style={{ color: 'red', fontSize: 14 }}>Account Holder Name is required</h4>
            }
            <h4 className='mt-2 mb-1'>Routing Number</h4>
            <input
              className='mt-1 input-style'
              // value={userInfo.routing_number}
              onChange={e => handleChange('routing_number', e.target.value)}
            />
            {submissive && !userInfo.routing_number &&
              <h4 className='mb-2 ' style={{ color: 'red', fontSize: 14 }}>Routing Number is required</h4>
            }
            <h4 className='mt-2 mb-1'>Account Number</h4>
            <input
              className='mt-1 input-style'
              onChange={e => handleChange('bankAccountNumber', e.target.value)}
            />
            {submissive && !userInfo.bankAccountNumber &&
              <h4 className='mb-2 ' style={{ color: 'red', fontSize: 14 }}>Account Number is required</h4>
            }

            {!userBankExistance && <>
              <h4 className='mt-2 mb-1'>Bank Statement</h4>

              <ImageUploader
                sendFiles={e => {
                  setbankStatement(e[0])
                }}
                numberOfFiles={1}
              />
            </>}


            <button
              disabled={loader}
              onClick={() => {
                if (validationstatus.basic) {
                  handleUpdateInfo(userInfo)
                } else {
                  alert('Please complete the profile first');
                }

              }}
              className='bg-yellow-500 float-right w-40 rounded h-12 mt-4 text-white text-md'
              type="button">
              {loader ? <CircularProgress size={16} /> : "Submit"}
            </button>
          </div>
          :
          // <div className="flex flex-row items-center justify-center p-4  rounded-lg w-full">
          //   <a href={accountData?.link} className='px-4 bg-green-50 text-sm border cursor-pointer rounded-lg h-10 text-center pt-2 bold' target="__blank">Update Account</a>
          // </div>
          <></>
        }



      </div>
      <div>

      </div>

      <RModal
        open={alertObj.state}
        title={alertObj.title}
        message={alertObj.message}
        type={alertObj.type}
        onClose={() => {
          setalertObj({ ...alertObj, state: false });
        }}
      />
    </div>
  );
}

export default ProfileBillingInfo;