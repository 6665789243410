import { Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import { InputSelector, SimpleInputSelector } from '..';
import { booleanAns, buildingStories, businessType, carCondition, carTruckTypes, constructionTypeOfGoods, constructionTypeOfService, constructionTypesOfItems, handlingUnit, motorCycleTypes, officeCarType, officeShipHauler, officeShipType, otherVehicles, timeFrameObj } from '../../utils/constant';

const MoveItem = ({ options, selectedOptions, label }) => {
    const [optionsArr, setoptionsArr] = useState(options);
    const [showState, setshowState] = useState(false);

    const handleChange = (item) => {
        var optionsArrClone = [...optionsArr];
        const fIndex = optionsArrClone.findIndex(x => x === item);
        optionsArrClone[fIndex].state = !optionsArrClone[fIndex].state;
        selectedOptions(optionsArrClone.filter(x => x.state).map(item => item.name));
        setoptionsArr(optionsArrClone);
    }

    return (
        <div className='row m-0 mt-4'>
            <div className='col-md-2 bg-slate-100 h-12 pt-3 border'
                onClick={() => setshowState(!showState)}>
                <h4 className="bold">{label}</h4>
            </div>
            <div className="col-md-10" style={{ display: !showState ? 'none' : 'block' }}>
                <div className='row m-0 bg-white p-3 border'>
                    {optionsArr.map((item, index) => {
                        return (
                            <div className="row m-2 items-center justify-center">
                                <div className="form-check bg-slate-50 w-[250px] m-1 pl-[10px] h-12 border row items-center pt-[15px]">
                                    <input
                                        style={{ width: 20, height: 20 }}
                                        type="checkbox" value={item.value}
                                        onChange={e => handleChange(item)}
                                        id="flexCheckDefault" />
                                    <label className='ml-2 -mt-2'>
                                        {item.name}
                                    </label>
                                </div>
                            </div>
                        );
                    })}
                </div>


            </div>
        </div>
    );
}

export default MoveItem;